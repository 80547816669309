import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export const AreaChart = () => {
  const options: Highcharts.Options = {
    chart: {
      // type: 'area',
      // type: 'spline',
      type: 'areaspline',
      backgroundColor: 'transparent',
      height: 350
    },
    title: {
      text: 'Revenue 01 Jan 2023 - 31 Dec 2023',
      align: 'left'
    },
    subtitle: {
      text: 'Rp 430,000,000 <span style= "color: #00B050;">1.3%</span> VS LAST YEAR',
      useHTML: true,
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#333'
      },
      align: 'left'
    },
    xAxis: {
      categories: [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC'
      ],
      tickmarkPlacement: 'on',
      gridLineWidth: 0,
      title: {
        text: undefined
      },
      plotLines: [
        {
          color: '#000000',
          width: 2,
          value: 32000,
          dashStyle: 'Dash',
          zIndex: 10,
          label: {
            text: '1,348 Subs',
            style: {
              color: '#FFFFFF',
              fontWeight: 'bold',
              fotSize: '12px'
            }
          },
          events: {
            click: function () {
              alert('Click On the Line!')
            }
          }
        }
      ]
    },
    yAxis: {
      title: {
        text: 'Revenue'
      },
      min: 0,
      max: 50000,
      tickInterval: 10000,
      gridLineDashStyle: 'Dash'
      // gridLineWidth: 1,
      // gridLineColor: '#eee',
      // labels: {
      //     formatter: onRemoveAnchor(dataa)
      // },
    },
    // tooltip: {
    //     // formatter: function () {
    //     //     return '<b>' + this.x + '</b><br/>' + 'Rp ' + this.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'k';
    //     // },
    //     style: {
    //         fontSize: '12px',
    //         fontWeight: 'normal'
    //     },
    //     backgroundColor: '#fff',
    //     borderColor: '#ccc',
    //     borderWidth: 1
    // },
    legend: {
      enabled: false
    },
    plotOptions: {
      // area: {
      //     marker: {
      //         radius: 2
      //     },
      //     lineWidth: 1,
      //     color: {
      //         linearGradient: {
      //             x1: 0,
      //             y1: 0,
      //             x2: 0,
      //             y2: 1
      //         },
      //         stops: [
      //             [0, '#00AAD2'],
      //             [0.7, '#A3DCFF']
      //         ]
      //     },
      //     states: {
      //         hover: {
      //             lineWidth: 1
      //         }
      //     },
      //     threshold: null
      // },
      // spline: {
      //     marker: {
      //         enabled: false
      //     },
      //     lineWidth: 2,
      //     color: {
      //         linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      //         stops: [
      //             [0, '#00C39A'],
      //             [1, '#E1F9FF']
      //         ]
      //     },
      //     fill: {
      //         linearGradient: { x1: 0, x2: 1, y1: 0, y2: 1 },
      //         stops: [
      //             [0, 'rgba(0, 195, 154, 0,5)'],
      //             [1, 'rgba(225, 249, 225, 0,5)']
      //         ]
      //     }
      // }
      areaspline: {
        marker: {
          // enabled: false
          radius: 2
        },
        lineWidth: 2
        // color: {
        //     linearGradient: {
        //         x1: 0,
        //         x2: 0,
        //         y1: 0,
        //         y2: 1
        //     },
        //     stops: [
        //         [0, '#00AAD2'],
        //         [0.7, '#A3DCFF']
        //     ]
        // },
        // fill: {
        //     linearGradient: { x1: 0, x2: 1, y1: 0, y2: 1 },
        //     stops: [
        //         [0, 'rgba(0, 195, 154, 0,5)'],
        //         [1, 'rgba(225, 249, 225, 0,5)']
        //     ]
        // }
      }
    },
    series: [
      {
        type: 'areaspline',
        name: 'Revenue',
        data: [0, 32000, 18000, 28000, 40000, 33000, 32000, 29000, 25000, 20000, 38000],
        color: '#00AAD2',
        // color: {
        //     linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        //     stops: [
        //         [0, '#fa4fed'],
        //         [1, '#5897ff']
        //     ]
        // },
        fillOpacity: 1,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, '#00AAD2'],
            [0, '#A3DCFF'],
            [1, '#FFFFFF']
          ]
        }
        // arearange: {
        //   enableMouseTracking: false,
        //   states: {
        //     inactive: {
        //       enabled: false
        //     }
        //   },
        //   color: 'red',
        //   fillOpacity: 1 / 3,
        //   lineWidth: 0
        // }
        // marker: {
        //     enabled: true,
        //     symbol: 'circle',
        //     radius: 4,
        //     fillColor: '#00a1d3',
        //     lineWidth: 0,
        //     states: {
        //         hover: {
        //             lineWidth: 1,
        //             lineColor: '#00a1d3'
        //         }
        //     }
        // }
      }
    ]
    // tooltip: {
    //     shared: true,
    //     valueSuffix: 'k'
    // },
    // credits: {
    //     enabled: false
    // }
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}
