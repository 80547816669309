import type { ContainerProps, StackProps } from '@mui/material'

class DialogStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      flex: '1 1',
      padding: 2,
      gap: '1rem',
      flexDirection: 'column'
    }
  }

  static Content: StackProps = {
    sx: {
      display: 'flex',
      flex: '1 1',
      gap: '1rem',
      flexDirection: 'column'
    }
  }

  static ButtonContainer: StackProps = {
    sx: {
      display: 'flex',
      gap: '.5rem',
      justifyContent: 'flex-end',
      flexDirection: 'row'
    }
  }
}

export default DialogStyle
