import CONSTANT from 'modules/constant'
import type { PaginationProps, ResultProps, SubsContentDetailProps, SubsContentListProps } from 'modules/types'
import type { PartialPromoCodeProps, PartialSubsContentCreateProps, PartialSubsContentEditProps, PartialUpdatePromoCodeProps } from 'modules/partial'
import { API, download, headers } from './api'

const PromoCodeAPI = API.injectEndpoints({
    endpoints: (builder) => ({
        getListSubsContent: builder.mutation<PaginationProps<SubsContentListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_SUBS_CONTENT_LIST,
                method: 'POST',
                headers: headers({ useContentType: false }),
                body: body,
                formData: true
            })
        }),
        getDetailSubsContent: builder.query<SubsContentDetailProps, number | string>({
            query: (id) => ({
                url: CONSTANT.URL_SUBS_CONTENT_DETAIL + id,
                method: 'GET',
                headers: headers()
            })
        }),
        createSubsContent: builder.mutation<ResultProps, PartialSubsContentCreateProps>({
            query: (body) => ({
                url: CONSTANT.URL_SUBS_CONTENT_CREATE,
                method: 'POST',
                headers: headers(),
                body: body
            })
        }),
        updateStatusSubsContent: builder.mutation<ResultProps, { Id: number; IsActive: boolean }>({
            query: (body) => ({
                url: CONSTANT.URL_SUBS_CNTENT_UPDATE_STATUS,
                method: 'PUT',
                headers: headers(),
                body: body
            })
        }),
        updateSubsContent: builder.mutation<ResultProps, PartialSubsContentEditProps>({
            query: (body) => ({
              url: CONSTANT.URL_SUBS_CONTENT_EDIT,
              method: 'PUT',
              headers: headers(),
              body: body,
            })
          }),
        downloadSubsContent: builder.mutation<PaginationProps<SubsContentListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_SUBS_CONTENT_DOWNLOAD,
                method: 'POST',
                headers: headers({ useContentType: false }),
                responseHandler: download,
                body: body,
                formData: true
            })
        }),
        deletePromoCode: builder.mutation<ResultProps, number>({
            query: (id) => ({
                url: CONSTANT.URL_PROMO_CODE_DELETE + id,
                method: 'DELETE',
                headers: headers()
            })
        }),
        createPromoCode: builder.mutation<ResultProps, PartialPromoCodeProps>({
            query: (body) => ({
                url: CONSTANT.URL_PROMO_CODE_CREATE,
                method: 'POST',
                headers: headers(),
                body: body
            })
        }),

        updatePromoCode: builder.mutation<ResultProps, { body: PartialUpdatePromoCodeProps, id: number }>({
            query: (param) => ({
                url: CONSTANT.URL_PROMO_CODE_UPDATE + param.id,
                method: 'PUT',
                headers: headers(),
                body: param.body,
            })
        }),
    })
})

export const {
    useGetListSubsContentMutation,
    useGetDetailSubsContentQuery,
    useCreateSubsContentMutation,
    useUpdateStatusSubsContentMutation,
    useUpdateSubsContentMutation,
    useDownloadSubsContentMutation,
    useDeletePromoCodeMutation,
    useCreatePromoCodeMutation,
    useUpdatePromoCodeMutation,
} = PromoCodeAPI
