import CONSTANT from 'modules/constant'
import type {
  BannerDetailProps,
  EventDropdownProps,
  PaginationProps,
  ResultProps,
  SchemeTypeDropdownDataProps,
  ServiceBookingEventBannerProps
} from 'modules/types'
import { API, headers } from './api'


const serviceBookingEventBanner = API.injectEndpoints({
  endpoints: (builder) => ({
    getListBannerEvent: builder.mutation<
      PaginationProps<ServiceBookingEventBannerProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_BANNER_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getEventDropdown: builder.mutation<EventDropdownProps[], void>({
      query: () => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_BANNER_EVENT_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getSchemeTypeDropdown: builder.mutation<SchemeTypeDropdownDataProps[], void>({
      query: () => ({
        url: CONSTANT.URL_SCHEME_TYPE_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    createListBanner: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_BANNER_CREATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getListBannerDetail: builder.query<BannerDetailProps, string | number>({
      query: (id) => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_BANNER_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    deleteBanner: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_BANNER_DELETE + id,
        method: 'DELETE',
        headers: headers(),
      })
    }),
    updateBanner: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_BANNER_UPDATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
  })
})

export const {
  useGetListBannerEventMutation,
  useGetEventDropdownMutation,
  useCreateListBannerMutation,
  useGetListBannerDetailQuery,
  useDeleteBannerMutation,
  useGetSchemeTypeDropdownMutation,
  useUpdateBannerMutation

  
} = serviceBookingEventBanner

