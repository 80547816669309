import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
// import type {PartialPromoCodeBatchCreateProps} from 'modules/partial'
import type {
  ApplicableSubsPlanDropDown,
  CarTypeDataDropDown,
  DetailStateProps,
  ErrorProps,
} from 'modules/types'
import GlobalStyle from 'modules/styles'
import {
  useCreatePromoCodeBatchMutation,
} from 'store/promoCodeBatch'
import { useGetApplicableSubsPlanDropdownMutation, useGetMemberTypeDropdownsssMutation } from 'store/dropdown'
import { useGetDiscountTypeDropdownMutation } from 'store/discountSetting'
import Dialog from 'components/dialog/dialog'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [getDiscountTypeDropdown, DiscountTypeDropdown] = useGetDiscountTypeDropdownMutation()
  const [getApplicableSubsPlanDropdown, applicableSubsPlanDropdown] = useGetApplicableSubsPlanDropdownMutation();
  const [getCarModelDropdown, carModelList] = useGetMemberTypeDropdownsssMutation()
  const [createPromoCodeBatch, create] = useCreatePromoCodeBatchMutation()
  const [applicableSubsPlan, setApplicableSubsPlan] = useState<ApplicableSubsPlanDropDown[]>([])

  const [userType, setUserType] = useState<CarTypeDataDropDown[]>([])
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [discountType, setDiscountType] = useState<number | null>(null)
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const scheme = yup.object<PartialPromoCodeBatchCreateProps>({
    Name: yup.string().required('Promo Name is required'),
    PromoNameEN: yup.string().required('Promo Name EN is required'),
    PromoNameID: yup.string().required('Promo Name ID is required'),
    Prefix: yup.string()
      .required('Prefix is required')
      .matches(/^[A-Z]+$/, 'Prefix must be all uppercase letters'),
    CodeLength: yup.number()
      .required('Code Length is required')
      .positive('Code Length must be a positive number')
      .integer('Code Length must be an integer')
      .max(5, 'Code Length must not exceed 5'),
    Quantity: yup.number().required('Quantity is required').positive().integer(),
    Description: yup.string().required('Description is required'),
    MaxRedemption: yup.number().required('Max Redemption is required').positive().integer(),
    IsPercent: yup.string().required('promo Type is required'),
    // Value: yup.number().required('Value is required').positive().integer(),
    Value: yup.string().required('Value is required'),
    ValidFrom: yup.string().required('Valid From is required'),
    ValidUntil: yup.string().required('Valid Until is required'),
    MinimumPayment: yup.number().required('Minimum Payment is required').positive().integer(),
    // MaximumDeduction: yup.number().required('Maximum Deduction is required').positive().integer(),
    SubscriptionPlan: yup.array().of(yup.object().shape({
      PlanPkId: yup.number().required(),
      SubscriptionPlanName: yup.string().required(),
    })).required('SubscriptionPlan is required'),
    CarModel: yup.array().of(yup.object().shape({
      PkId: yup.number().required(),
      CarName: yup.string().required(),
    })).required('CarModel is required')
  });

  const formik = useFormik<PartialPromoCodeBatchCreateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      Name: '',
      PromoNameEN: '',
      PromoNameID: '',
      Prefix: '',
      CodeLength: null,
      Quantity: null,
      Description: '',
      MaxRedemption: null,
      IsPercent: '',
      Value: 0,
      ValidFrom: '',
      ValidUntil: '',
      MinimumPayment: 0,
      MaximumDeduction: null,
      SubscriptionPlan: [],
      CarModel: []
    },
    onSubmit: (values: PartialPromoCodeBatchCreateProps) => handleSubmit(values)
  })

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidFrom', newDate)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidUntil', newDate)
  }
  const handleChange = (value: ApplicableSubsPlanDropDown[]) => {
    const formattedPlans = value.map(plan => ({
      PlanPkId: plan.id, // Make sure these properties exist in your dropdown data
      SubscriptionPlanName: plan.name
    }));
    formik.setFieldValue('SubscriptionPlan', formattedPlans);
  };

  const handleChangeCarModel = (value: CarTypeDataDropDown[]) => {
    const isian = carModelList.data || []; // All available car models
    // Check if "All" is selected
    if (value.some(item => item.name === "All")) {
      setUserType(isian); // Set userType to all available options
      setIsDisabled(true); // Disable the dropdown
      formik.setFieldValue('IsAllCarModel', true); // Set IsAllCarModel to true
      formik.setFieldValue('CarModel', isian.map(car => ({ PkId: car.id, CarName: car.name }))); // Set all car models
    } else {
      const filteredValue = value.filter(item => item.name !== "All");
      setUserType(filteredValue);
      setIsDisabled(false);
      formik.setFieldValue('CarModel', filteredValue.map(car => ({ PkId: car.id, CarName: car.name }))); // Set selected car models

      const isAllSelected = filteredValue.length === isian.length; // Check if all available models are selected
      formik.setFieldValue('IsAllCarModel', isAllSelected);
    }
  };

  const checkMinutesValidity = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target instanceof HTMLInputElement) {
      const value = Number(event.target.value);
      if (value > 100) {
        event.target.value = '100';
      }
      if (value < 0) {
        event.target.value = '0';
      }
    }
  }

  // const formatCurrencyss = (value: string) => {
  //   if (!value) return '';
  //   return Number(value).toLocaleString('en-US', {
  //     style: 'decimal',
  //     // minimumFractionDigits: 0,
  //     // maximumFractionDigits: 0,
  //   });
  // };

  // const formatCurrency = (value: number) =>
  //   value ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : '-';

  const formatCurrency = (value: string | number) => {
    if (typeof value === 'string') {
      const numberValue = parseFloat(value.replace(/,/g, '')); // Remove commas and convert to number
      return isNaN(numberValue) ? 0 : numberValue.toLocaleString('en-US');
    }
    return value ? value.toLocaleString('en-US') : 0;
  };
  // const handlePromoAmountChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   const value = e.target.value.replace(/,/g, ''); // Remove commas for parsing
  //   formik.setFieldValue('Value', value); // Set the raw value in formik
  // };

  const handleSubmit = (e: PartialPromoCodeBatchCreateProps) => {

    const formatPayload = {
      Name: e.Name,
      PromoNameEN: e.PromoNameEN,
      PromoNameID: e.PromoNameID,
      Prefix: e.Prefix,
      CodeLength: e.CodeLength,
      Quantity: e.Quantity,
      Description: e.Description,
      MaxRedemption: e.MaxRedemption,
      IsPercent: e.IsPercent,
      Value: Number(e.Value),
      ValidFrom: dayjs(e.ValidFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      ValidUntil: dayjs(e.ValidUntil).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      MinimumPayment: Number(e.MinimumPayment),
      MaximumDeduction: e.MaximumDeduction,
      IsAllCarModel: e.IsAllCarModel,
      SubscriptionPlan: e.SubscriptionPlan,
      CarModel: e.CarModel
    };
    createPromoCodeBatch(formatPayload).then()
  }

  const disabled = !formik.isValid ||
    (formik.values.SubscriptionPlan.length === 0) ||
    (formik.values.CarModel.length === 0);
  // console.log('disabled', disabled);
  console.log('formikError', formik.errors)
  // console.log('formikTouched', formik.touched)


  return (
    <>
      <Dialog
        isDisabled={disabled}
        title='Create Promo Code Batch'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        maxWidth='md'

      >
        <FormikProvider value={formik}>
          <Container {...DialogStyle.Container}>
            <TextField
              {...DialogStyle.textField}
              id='Name'
              variant='outlined'
              label='Promo Name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.Name}
              error={formik.touched && formik.touched.Name && Boolean(formik.errors.Name)}
              helperText={
                formik.touched && formik.touched.Name && formik.errors && formik.errors.Name
              }
              fullWidth
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                <TextField
                  {...DialogStyle.textField}
                  id='PromoNameEN'
                  variant='outlined'
                  label='Promo Name EN-US'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.PromoNameEN}
                  error={formik.touched && formik.touched.PromoNameEN && Boolean(formik.errors.PromoNameEN)}
                  helperText={
                    formik.touched && formik.touched.PromoNameEN && formik.errors && formik.errors.PromoNameEN
                  }
                  fullWidth
                />
                <TextField
                  id='Prefix'
                  variant='outlined'
                  label='Prefix'
                  onChange={formik.handleChange}
                  {...DialogStyle.textField}
                  onBlur={formik.handleBlur}
                  value={formik.values.Prefix}
                  error={formik.touched && formik.touched.Prefix && Boolean(formik.errors.Prefix)}
                  helperText={
                    formik.touched && formik.touched.Prefix && formik.errors && formik.errors.Prefix
                  }
                  fullWidth
                />
                <TextField
                  id='Quantity'
                  variant='outlined'
                  label='Quantity'
                  type='number'
                  onChange={formik.handleChange}
                  {...DialogStyle.textField}
                  onBlur={formik.handleBlur}
                  value={formik.values.Quantity}
                  error={formik.touched && formik.touched.Quantity && Boolean(formik.errors.Quantity)}
                  helperText={
                    formik.touched && formik.touched.Quantity && formik.errors && formik.errors.Quantity
                  }
                  fullWidth
                />
              </Box>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                <TextField
                  {...DialogStyle.textField}
                  id='PromoNameID'
                  variant='outlined'
                  label='Promo Name ID'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.PromoNameID}
                  error={formik.touched && formik.touched.PromoNameID && Boolean(formik.errors.PromoNameID)}
                  helperText={
                    formik.touched && formik.touched.PromoNameID && formik.errors && formik.errors.PromoNameID
                  }
                  fullWidth
                />
                <TextField
                  id='CodeLength'
                  variant='outlined'
                  label='Suffix Length'
                  type='number'
                  onChange={formik.handleChange}
                  {...DialogStyle.textField}
                  onBlur={formik.handleBlur}
                  value={formik.values.CodeLength}
                  error={formik.touched && formik.touched.CodeLength && Boolean(formik.errors.CodeLength)}
                  helperText={
                    formik.touched && formik.touched.CodeLength && formik.errors && formik.errors.CodeLength
                  }
                  fullWidth
                />
                <TextField
                  id='MaxRedemption'
                  variant='outlined'
                  label='Max. Redemption per Promo Code'
                  type='number'
                  onChange={formik.handleChange}
                  {...DialogStyle.textField}
                  onBlur={formik.handleBlur}
                  value={formik.values.MaxRedemption}
                  error={formik.touched && formik.touched.MaxRedemption && Boolean(formik.errors.MaxRedemption)}
                  helperText={
                    formik.touched && formik.touched.MaxRedemption && formik.errors && formik.errors.MaxRedemption
                  }
                  fullWidth
                />
              </Box>
            </Box>
            <TextField
              {...DialogStyle.textField}
              id='Description'
              variant='outlined'
              label='Description'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.Description}
              error={formik.touched && formik.touched.Description && Boolean(formik.errors.Description)}
              helperText={
                formik.touched && formik.touched.Description && formik.errors && formik.errors.Description
              }
              multiline
              rows={2}
              fullWidth
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                <Autocomplete
                  options={DiscountTypeDropdown.data || []}
                  getOptionLabel={(list) => list.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.id == value.id : false
                  }
                  onOpen={() => getDiscountTypeDropdown()}
                  onChange={(_, id) => { formik.setFieldValue('IsPercent', id && id.name), setDiscountType(id && id?.id) }}
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='IsPercent'
                      label='Promo Type'
                      error={
                        formik.touched && formik.touched.IsPercent && Boolean(formik.errors.IsPercent)
                      }
                      helperText={
                        formik.touched &&
                        formik.touched.IsPercent &&
                        formik.errors &&
                        formik.errors.IsPercent
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {DiscountTypeDropdown.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                {discountType === 1 && (
                  <>
                    <TextField
                      id='MaximumDeduction'
                      variant='outlined'
                      label='Maximum Deduction'
                      onChange={formik.handleChange}
                      {...DialogStyle.textField}
                      type='number'
                      onBlur={formik.handleBlur}
                      value={formik.values.MaximumDeduction}
                      error={formik.touched && formik.touched.MaximumDeduction && Boolean(formik.errors.MaximumDeduction)}
                      helperText={
                        formik.touched && formik.touched.MaximumDeduction && formik.errors && formik.errors.MaximumDeduction
                      }
                      fullWidth
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={startDate}
                        onChange={handleChangeStartDate}
                        label='Valid From'
                        format='DD MMM YYYY'
                      />
                    </LocalizationProvider>
                    <Autocomplete
                      fullWidth
                      sx={{ marginBottom: '10px' }}
                      multiple
                      id='fixed-tags-demo'
                      value={applicableSubsPlan}
                      onOpen={() => getApplicableSubsPlanDropdown()}
                      isOptionEqualToValue={(option, value) =>
                        option && value ? option.name === value.name : false
                      }
                      onChange={(event, newValue) => {
                        setApplicableSubsPlan(newValue)
                        handleChange(newValue);
                      }}
                      options={(applicableSubsPlanDropdown && applicableSubsPlanDropdown.data) || []}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip label={option.name} {...getTagProps({ index })} key={index} />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} label='Applicable Subscription Plan' />}
                    />
                  </>
                )}
                {discountType === 2 && (
                  <>
                    {/* <TextField
                      id='MaximumDeduction'
                      variant='outlined'
                      label='Maximum Deduction'
                      onChange={formik.handleChange}
                      {...DialogStyle.textField}
                      onBlur={formik.handleBlur}
                      type='number'
                      disabled={true}
                      sx={{ backgroundColor: '#E1DFDF' }}
                      value={formik.values.MaximumDeduction}
                      error={formik.touched && formik.touched.MaximumDeduction && Boolean(formik.errors.MaximumDeduction)}
                      helperText={
                        formik.touched && formik.touched.MaximumDeduction && formik.errors && formik.errors.MaximumDeduction
                      }
                      fullWidth
                    /> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={startDate}
                        onChange={handleChangeStartDate}
                        label='Valid From'
                        format='DD MMM YYYY'
                      />
                    </LocalizationProvider>
                    <Autocomplete
                      fullWidth
                      sx={{ marginBottom: '10px' }}
                      multiple
                      id='fixed-tags-demo'
                      value={applicableSubsPlan}
                      onOpen={() => getApplicableSubsPlanDropdown()}
                      isOptionEqualToValue={(option, value) =>
                        option && value ? option.name === value.name : false
                      }
                      onChange={(event, newValue) => {
                        setApplicableSubsPlan(newValue)
                        handleChange(newValue);
                      }}
                      options={(applicableSubsPlanDropdown && applicableSubsPlanDropdown.data) || []}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip label={option.name} {...getTagProps({ index })} key={index} />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} label='Applicable Subscription Plan' />}
                    />
                  </>
                )}
              </Box>

              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                {discountType === 1 && (
                  <>
                    <FormControl fullWidth>
                      <InputLabel htmlFor='electricityFee'>Promo Amount</InputLabel>
                      <OutlinedInput
                        id='Value'
                        label='Promo Amount'
                        type='number'
                        value={formik.values.Value && formik.values.Value.toString()}
                        onChange={(e) => {
                          checkMinutesValidity(e);
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched && formik.touched.Value && Boolean(formik.errors.Value)}
                        inputProps={{
                          autoComplete: 'off',
                          min: 0,
                          max: 100
                        }}
                        onKeyDown={(event) => {
                          if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault();
                        }}
                        endAdornment={<InputAdornment position='end'>%</InputAdornment>
                        }
                      />
                    </FormControl>
                    <TextField
                      id='MinimumPayment'
                      variant='outlined'
                      label='Minimum Payment'
                      {...DialogStyle.textField}
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const rawValue = e.target.value.replace(/,/g, ''); // Remove commas for parsing
                        formik.setFieldValue('MinimumPayment', rawValue);
                        console.log('rawValue', rawValue);

                      }}
                      onBlur={formik.handleBlur}
                      type='text'
                      // value={formik.values.MinimumPayment}
                      value={formatCurrency(formik.values.MinimumPayment.toString())}
                      error={formik.touched && formik.touched.MinimumPayment && Boolean(formik.errors.MinimumPayment)}
                      helperText={
                        formik.touched && formik.touched.MinimumPayment && formik.errors && formik.errors.MinimumPayment
                      }
                      fullWidth
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={endDate}
                        minDate={dayjs(startDate)}
                        onChange={handleChangeEndDate}
                        label='Valid Until'
                        format='DD MMM YYYY'
                      />
                    </LocalizationProvider>
                    <Autocomplete
                      fullWidth
                      sx={{ marginBottom: '10px' }}
                      multiple
                      id='fixed-tags-demo'
                      value={userType}
                      onOpen={() => getCarModelDropdown()}
                      isOptionEqualToValue={(option, value) =>
                        option && value ? option.id === value.id : false // Compare by id instead of name
                      }
                      onChange={(event, newValue) => {
                        setUserType(newValue);
                        handleChangeCarModel(newValue);
                      }}
                      options={[
                        { id: 14, name: "All" }, // New option
                        ...(carModelList && carModelList.data || []),
                      ]}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.name} {...getTagProps({ index })}
                            key={index}
                            disabled={isDisabled}
                          />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} label='Car Model' />}
                    />
                  </>
                )}
                {discountType === 2 && (
                  <>
                    <FormControl fullWidth>
                      <InputLabel htmlFor='electricityFee'>Promo Amount</InputLabel>
                      <OutlinedInput
                        id='Value'
                        label='Promo Amount'
                        type='text'
                        // value={formik.values.Value && formik.values.Value.toString()}
                        value={formatCurrency(formik.values.Value)}
                        // value={formatCurrency(formik.values.Value !== null && formik.values.Value !== 0 ? formik.values.Value.toString() : '')}
                        // onChange={formik.handleChange}
                        // onChange={(e) => { formik.handleChange, handlePromoAmountChange(e) }}
                        onChange={(e) => {
                          const rawValue = e.target.value.replace(/,/g, ''); // Remove commas for parsing
                          formik.setFieldValue('Value', rawValue); // Set the raw value in formik
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched && formik.touched.Value && Boolean(formik.errors.Value)}
                        inputProps={{ autoComplete: 'off' }}
                        onKeyDown={(event) => {
                          if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                        }}
                        startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
                      />
                    </FormControl>
                    <TextField
                      id='MinimumPayment'
                      variant='outlined'
                      label='Minimum Payment'
                      {...DialogStyle.textField}
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        const rawValue = e.target.value.replace(/,/g, ''); // Remove commas for parsing
                        formik.setFieldValue('MinimumPayment', rawValue);
                        console.log('rawValue', rawValue);

                      }}
                      onBlur={formik.handleBlur}
                      type='text'
                      value={formatCurrency(formik.values.MinimumPayment.toString())}
                      // value={formik.values.MinimumPayment}
                      error={formik.touched && formik.touched.MinimumPayment && Boolean(formik.errors.MinimumPayment)}
                      helperText={
                        formik.touched && formik.touched.MinimumPayment && formik.errors && formik.errors.MinimumPayment
                      }
                      fullWidth
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={endDate}
                        minDate={dayjs(startDate)}
                        onChange={handleChangeEndDate}
                        label='Valid Until'
                        format='DD MMM YYYY'
                      />
                    </LocalizationProvider>
                    <Autocomplete
                      fullWidth
                      sx={{ marginBottom: '10px' }}
                      multiple
                      id='fixed-tags-demo'
                      value={userType}
                      onOpen={() => getCarModelDropdown()}
                      isOptionEqualToValue={(option, value) =>
                        option && value ? option.id === value.id : false // Compare by id instead of name
                      }
                      onChange={(event, newValue) => {
                        setUserType(newValue);
                        handleChangeCarModel(newValue);
                      }}
                      options={[
                        { id: 14, name: "All" }, // New option
                        ...(carModelList && carModelList.data || []), // Existing options
                      ]}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.name} {...getTagProps({ index })}
                            key={index}
                            disabled={isDisabled}
                          />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} label='Car Model' />}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Container>
        </FormikProvider>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export type PartialPromoCodeBatchCreateProps = {
  Name: string
  PromoNameEN: string
  PromoNameID: string
  Prefix: string
  CodeLength: number | null
  Quantity: number | null
  Description: string
  MaxRedemption: number | null
  IsPercent: string
  Value: number
  ValidFrom: string
  ValidUntil: string
  MinimumPayment: number
  MaximumDeduction?: number | null
  IsAllCarModel?: boolean
  SubscriptionPlan: SubscriptionApplicablePlanProps[]
  CarModel: CarModelCreateProps[]
}

export type SubscriptionApplicablePlanProps = {
  PlanPkId: number,
  SubscriptionPlanName: string
}
export type CarModelCreateProps = {
  PkId: number,
  CarName: string
}
export default Create
