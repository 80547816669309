import { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment'
import Filter from 'components/filter/filter'
import Content from 'components/content/content'
import type { ErrorProps } from 'modules/types'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import type { PartialNotificationListProps } from 'modules/partial'
import CONSTANT from 'modules/constant'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import {
  useGetNotificationCategoryListQuery,
  useLazyGetNotificationListQuery
} from 'store/notification'
// import Create from './create/create'
// import Edit from './edit/edit'
import Detail from './detail/detail'

const NotificationPage = () => {
  const [getNotificationList, notificationListResult] = useLazyGetNotificationListQuery()

  const notificationCategoryListResult = useGetNotificationCategoryListQuery()

  const [filter, setFilter] = useState<
    Omit<PartialNotificationListProps, 'Start' | 'Length' | 'Search'>
  >({
    Category: '',
    FullName: '',
    Vin: '',
    MinNotifDate: '',
    MaxNotifDate: ''
  })
  const [payload, setPayload] = useState<PartialNotificationListProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    Category: '',
    FullName: '',
    Vin: '',
    MinNotifDate: '',
    MaxNotifDate: ''
  })

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)
  const [randomNumber, setRandomNumber] = useState<number>(0)

  const onAction = (_: React.MouseEvent<HTMLElement>, index: null | number) => {
    setMenu(index)
  }

  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })

  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onResync = () => getNotificationList(payload)

  const onFilter = (state: boolean) => setDialogFilter(state)

  useEffect(() => {
    onResync()
  }, [payload])

  const tableHeadTitles = [
    'Category',
    'Title',
    'Body',
    'Full Name',
    'VIN',
    'Email',
    'Notification Date'
  ]
  const numbers = GLOBAL.tableNumber(payload.Start)

  const totalPage =
    notificationListResult.data && GLOBAL.tableTotalPage(notificationListResult.data.recordsTotal)

  return (
    <>
      <Content
        title='Notification'
        tableHeadTitles={tableHeadTitles}
        onFilter={() => onFilter(true)}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={notificationListResult.data && notificationListResult.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={notificationListResult.isLoading}
      >
        {notificationListResult.isSuccess &&
          notificationListResult.data &&
          notificationListResult.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  onClick={(event) => {
                    onAction(event, index)
                    setDrawerDetail(true)
                  }}
                >
                  <Icon icon='VisibilityOutlined' />
                </IconButton>
                <Detail
                  id={table.pkId}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
              </TableCell>
              <TableCell>{table.category || '-'}</TableCell>
              <TableCell>{table.title || '-'}</TableCell>
              <TableCell>{table.body || '-'}</TableCell>
              <TableCell>{table.fullName || '-'}</TableCell>
              <TableCell>{table.vin || '-'}</TableCell>
              <TableCell>{table.email || '-'}</TableCell>
              <TableCell>{moment(table.notificationDate).format('DD MMM YYYY HH:mm:ss')}</TableCell>
            </TableRow>
          ))}
      </Content>

      {notificationListResult.isLoading && <Loading />}

      <Notification
        open={
          !notificationListResult.isLoading &&
          !notificationListResult.isUninitialized &&
          !notificationListResult.isSuccess
        }
        onClose={() => !notificationListResult.isError && location.reload()}
        isError={Boolean(notificationListResult.error) && notificationListResult.isError}
        message={GLOBAL.returnExceptionMessage(
          notificationListResult.isError,
          notificationListResult.error as ErrorProps
        )}
      />

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomNumber(randomNumber + 1)
          setFilter({
            Category: '',
            FullName: '',
            Vin: '',
            MinNotifDate: '',
            MaxNotifDate: ''
          })
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            Category: '',
            FullName: '',
            Vin: '',
            MinNotifDate: '',
            MaxNotifDate: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(notificationCategoryListResult && notificationCategoryListResult.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onChange={(_, category) =>
            setFilter({
              ...filter,
              Category: (category && String(category.name)) || ''
            })
          }
          value={
            (notificationCategoryListResult &&
              notificationCategoryListResult.data &&
              notificationCategoryListResult.data.find((e) => String(e.name) == filter.Category)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='category'
              label='Category'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {notificationCategoryListResult.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />

        <TextField
          id='fullName'
          variant='outlined'
          label='Full Name'
          value={filter.FullName}
          onChange={(event) => setFilter({ ...filter, FullName: event.target.value })}
          fullWidth
        />

        <TextField
          id='vin'
          variant='outlined'
          label='VIN'
          value={filter.Vin}
          onChange={(event) => setFilter({ ...filter, Vin: event.target.value })}
          fullWidth
        />

        <Box>
          <Typography mb={1}>Notification Date</Typography>
          <Grid container spacing={1.75}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={filter.MinNotifDate ? dayjs(filter.MinNotifDate) : null}
                  // minDate={dayjs(filter.MinNotifDate)}
                  onChange={(event: Dayjs | null) =>
                    setFilter({
                      ...filter,
                      MinNotifDate: (event && event.format('YYYY-MM-DD 00:00')) || ''
                    })
                  }
                  label='Min Date'
                  format='DD-MM-YYYY'
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={filter.MaxNotifDate ? dayjs(filter.MaxNotifDate) : null}
                  minDate={dayjs(filter.MinNotifDate)}
                  onChange={(event: Dayjs | null) =>
                    setFilter({
                      ...filter,
                      MaxNotifDate: (event && event.format('YYYY-MM-DD 23:59')) || ''
                    })
                  }
                  label='Max Date'
                  format='DD-MM-YYYY'
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
      </Filter>
    </>
  )
}

export default NotificationPage
