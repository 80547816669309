import { Box, Container, Typography } from '@mui/material'
import moment from 'moment'
import { useGetNotificationDetailQuery } from 'store/notification'
import type { DetailStateProps } from 'modules/types'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetNotificationDetailQuery(id)

  const loading = !data && (isLoading || isFetching)

  const category = data && data.category
  const title = data && data.title
  const body = data && data.body
  const fullName = data && data.fullName
  const vin = data && data.vin
  const email = data && data.email
  const notificationDate = data && data.notificationDate

  const gridDataLabel = [
    'Category',
    'Title',
    'Body',
    'Full Name',
    'VIN',
    'Email',
    'Notification Date'
  ]

  const gridDataValue = [category, title, body, fullName, vin, email, notificationDate]

  return (
    <>
      <Drawer open={open} title='Detail Notification' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              {gridDataLabel.map((label, id) => (
                <Box key={id + 1}>
                  <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>{label}</Typography>
                  <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                    {label.toLowerCase().includes('date')
                      ? gridDataValue[id]
                        ? moment(gridDataValue[id]).format('DD MMM YYYY HH:mm:ss')
                        : '-'
                      : gridDataValue[id] || '-'}
                  </Typography>
                </Box>
              ))}
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
