import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import type { GridColDef, GridRowId, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import * as yup from 'yup'
import { DataGrid } from '@mui/x-data-grid'
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Icon from 'components/icon'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import {
  useGetAccountCheckUserListsssMutation,
  useGetMainMenuListMutation,
  useGetSubMenuListMutation,
  useUpdateAccountPermissionMutation
} from 'store/assignment'
import { useGetRolesListDropdownsssMutation } from 'store/roles'
import type { PartialMenuPermissionProps } from 'modules/partial'
import type {
  DetailStateProps,
  ErrorProps,
  RolesAssignmentFunctionProps,
  RolesAssignmentMenuProps
} from 'modules/types'
import GlobalStyle from 'modules/styles'
import useDebounce from 'modules/useDebounce'
import DialogStyle from './style'

type SubMenuProps = {
  menu?: string
  name: string
  id: number
  functions: RolesAssignmentFunctionProps[]
  convertedFunctions: string
}

const Create = ({ open, onClose }: DetailStateProps) => {
  const [getRolesListDropdown, rolesList] = useGetRolesListDropdownsssMutation()
  const [getAccountList, accountList] = useGetAccountCheckUserListsssMutation()
  const [getMainMenuList, mainMenuList] = useGetMainMenuListMutation()
  const [getSubMenuList, subMenuList] = useGetSubMenuListMutation()
  const [updateAccountPermission, updateAccount] = useUpdateAccountPermissionMutation()

  const [nameCheck, setNameCheck] = useState<string>('')
  const [focus, setFocus] = useState<boolean>(false)

  const isNotEmpty = subMenuList && subMenuList.data && subMenuList.data.length > 0

  const subMenuColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96 },
    { field: 'name', headerName: 'Sub Menu', width: 240 }
  ]

  const functionNameColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96 },
    { field: 'name', headerName: 'Function Name', width: 240 }
  ]

  const selectedColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96 },
    { field: 'menu', headerName: 'Main Menu', width: 160 },
    { field: 'name', headerName: 'Sub Menu', width: 160 },
    { field: 'convertedFunctions', headerName: 'Function Name', width: 256 },
    {
      field: 'Action',
      headerName: 'Action',
      width: 96,
      sortable: false,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <IconButton onClick={() => onDeleteSelectionRow(row)}>
          <Icon icon='DeleteForever' />
        </IconButton>
      )
    }
  ]

  const [functionNameRows] = useState<{ id: string; name: string }[]>([
    { id: 'CREATE', name: 'Create' },
    { id: 'READ', name: 'View' },
    { id: 'UPDATE', name: 'Edit / Update' },
    { id: 'DELETE', name: 'Delete' }
  ])

  const [selectedSubMenu, setSelectedSubMenu] = useState<(RolesAssignmentMenuProps | undefined)[]>(
    []
  )

  const [selectedFunction, setSelectedFunction] = useState<
    ({ id: string; name: string } | undefined)[]
  >([])

  const [selectedRow, setSelectedRow] = useState<(RolesAssignmentMenuProps | undefined)[]>([])

  const onSubMenuSelectionRow = (ids: GridRowSelectionModel) => {
    const subMenu = subMenuList && subMenuList.data
    const list = isEmpty(selectedRow) ? subMenu : selectedRow.concat(subMenu)

    const SelectionRow = ids.map((id) => list && list.find((row) => row && row.id == id))

    setSelectedSubMenu(SelectionRow)
  }

  const onFunctionSelectionRow = (ids: GridRowSelectionModel) => {
    const SelectionRow = ids.map((id) => functionNameRows.find((row) => row.id == id))

    setSelectedFunction(SelectionRow)
  }

  const onAddSelectionRow = () => {
    const menu =
      mainMenuList &&
      mainMenuList.data &&
      mainMenuList.data.find((menu) => formik.values.menuId == menu.id)

    const functionId = selectedFunction.map((func) => func && func.id)
    const currentListedId =
      subMenuList && subMenuList.data && subMenuList.data.map((el) => el && el.id)

    const selected = selectedSubMenu.map((sub) => {
      if (sub) {
        const result: SubMenuProps = {
          menu: sub.menu,
          name: sub.name,
          id: sub.id,
          functions: sub.functions.filter((func) => functionId.includes(func.type)),
          convertedFunctions: sub.functions
            .filter((func) => functionId.includes(func.type))
            .map(({ name }) => name)
            .join(', ')
        }

        if (currentListedId && currentListedId.includes(sub.id)) {
          result.menu = menu && menu.name
        }

        return result
      }

      return undefined
    })

    setSelectedRow(selected)
  }

  const onDeleteSelectionRow = (row: RolesAssignmentMenuProps) => {
    const SelectionRow = selectedRow.filter((selected) => selected && selected.id !== row.id)
    setSelectedRow(SelectionRow)
  }

  const onSubmit = (values: PartialMenuPermissionProps) => {
    const data = { ...values }
    delete data.menuId

    const list = selectedRow.map(
      (selected) => selected && selected.functions.map((func) => func.id)
    )

    const flattened = list.flat(1)
    const menus = flattened.map((id) => ({ FunctionId: id, Action: 'INSERT' }))

    updateAccountPermission({ ...data, Menus: menus })
  }

  const scheme = yup.object<PartialMenuPermissionProps>({
    userId: yup.string().required('Assign is required'),
    roleId: yup.number().positive('RoleId is required').required('RoleId is required'),
    menuId: yup.number().positive('menuId is required').required('menuId is required'),
    Menus: yup.array().of(
      yup.object({
        FunctionId: yup
          .number()
          .positive('FunctionId is required')
          .required('FunctionId is required'),
        Action: yup.string().required('Action is required')
      })
    )
  })

  const formik = useFormik<PartialMenuPermissionProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      userId: '',
      roleId: 0,
      menuId: 0,
      Menus: []
    },
    onSubmit: onSubmit
  })

  const debounce = useDebounce(nameCheck)

  useEffect(() => {
    if (focus) getAccountList({ nameCheck })
  }, [debounce])

  return (
    <>
      <Dialog
        title='Create New Role Assignment'
        open={open}
        maxWidth='md'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={rolesList.isLoading || accountList.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <Autocomplete
            options={rolesList.data || []}
            getOptionLabel={(list) => list.name}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.pkId == value.pkId : false
            }
            onOpen={() => getRolesListDropdown()}
            onChange={(_, id) => formik.setFieldValue('roleId', id && id.pkId)}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.pkId}>
                {item.code}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='roleId'
                label='Select A Role'
                error={formik.touched && formik.touched.roleId && Boolean(formik.errors.roleId)}
                helperText={
                  formik.touched && formik.touched.roleId && formik.errors && formik.errors.roleId
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {rolesList.isLoading && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <Autocomplete
            options={accountList.data || []}
            getOptionLabel={(list) => list.name}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.userId == value.userId : false
            }
            onChange={(_, userId) => formik.setFieldValue('userId', userId && userId.userId)}
            onInputChange={(_, value) => setNameCheck(value)}
            onFocus={() => setFocus(true)}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.userId}>
                {item.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='userId'
                label='Assign Access To'
                error={formik.touched && formik.touched.userId && Boolean(formik.errors.userId)}
                helperText={
                  formik.touched && formik.touched.userId && formik.errors && formik.errors.userId
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {false && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <Typography {...DialogStyle.Permission}>Permissions</Typography>
          <Autocomplete
            options={mainMenuList.data || []}
            getOptionLabel={(list) => list.name}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.id == value.id : false
            }
            onOpen={() => getMainMenuList()}
            onChange={(_, id) => {
              formik.setFieldValue('menuId', id && id.id)
              getSubMenuList((id && id.id) || 0)
            }}
            onBlur={formik.handleBlur}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.id}>
                {item.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='menuId'
                label='Select Main Menu'
                error={formik.touched && formik.touched.menuId && Boolean(formik.errors.menuId)}
                helperText={
                  formik.touched && formik.touched.menuId && formik.errors && formik.errors.menuId
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {mainMenuList.isLoading && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          {formik.touched && formik.touched.menuId && !formik.errors.menuId && (
            <>
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <DataGrid
                    rows={(subMenuList && subMenuList.data) || []}
                    columns={subMenuColumns}
                    rowSelectionModel={selectedSubMenu.map((el) => el && el.id) as GridRowId[]}
                    onRowSelectionModelChange={onSubMenuSelectionRow}
                    keepNonExistentRowsSelected
                    checkboxSelection
                    hideFooter
                    autoHeight
                  />
                </Grid>
                <Grid item xs={6}>
                  <DataGrid
                    rows={functionNameRows}
                    columns={functionNameColumns}
                    rowSelectionModel={selectedFunction.map((el) => el && el.id) as GridRowId[]}
                    onRowSelectionModelChange={onFunctionSelectionRow}
                    checkboxSelection
                    hideFooter
                    autoHeight
                  />
                </Grid>
              </Grid>
              <Button variant='contained' disabled={!isNotEmpty} onClick={onAddSelectionRow}>
                Add
              </Button>
              {selectedRow && !isEmpty(selectedRow) && (
                <DataGrid rows={selectedRow} columns={selectedColumns} hideFooter autoHeight />
              )}
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!updateAccount.isLoading && !updateAccount.isUninitialized}
        onClose={() => (updateAccount.isError ? updateAccount.reset() : location.reload())}
        isError={Boolean(updateAccount.error) && updateAccount.isError}
        message={GLOBAL.returnExceptionMessage(
          updateAccount.isError,
          updateAccount.error as ErrorProps
        )}
      />
    </>
  )
}

export default Create
