import CONSTANT from 'modules/constant'
import type { PartialMenuPermissionProps, PartialRolesAssignmentCheckProps } from 'modules/partial'
import type {
  PaginationProps,
  ReturnProps,
  RolesAssignmentAccountDatasssProps,
  RolesAssignmentAccountProps,
  RolesAssignmentMenuProps,
  RolesAssignmentProps,
  RolesAssignmentsProps
} from 'modules/types'
import { API, download, headers } from './api'

const assignmentAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getAccountPermissionList: builder.mutation<PaginationProps<RolesAssignmentProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_ROLES_ASSIGNMENT,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getAccountPermissionListsss: builder.mutation<PaginationProps<RolesAssignmentProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_ROLES_ASSIGNMENTsss,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getAccountPermissionDetail: builder.query<RolesAssignmentProps, string>({
      query: (userId) => ({
        url: CONSTANT.URL_ROLES_ASSIGNMENT_DETAIL + userId,
        method: 'GET',
        headers: headers()
      })
    }),
    getAccountPermissionDetailsss: builder.query<RolesAssignmentsProps, string>({
      query: (userId) => ({
        url: CONSTANT.URL_ROLES_ASSIGNMENT_DETAIL + userId,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadAccountPermissionList: builder.mutation<
      PaginationProps<RolesAssignmentProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_ROLES_ASSIGNMENT_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    getAccountList: builder.mutation<
      RolesAssignmentAccountProps[],
      PartialRolesAssignmentCheckProps
    >({
      query: (body) => ({
        url: CONSTANT.URL_ROLES_ASSIGNMENT_ACCOUNT_LIST + `?nameCheck=${body.nameCheck}`,
        method: 'GET',
        headers: headers()
      })
    }),
    getAccountCheckUserList: builder.mutation<
      RolesAssignmentAccountProps[],
      PartialRolesAssignmentCheckProps
    >({
      query: (body) => ({
        url: CONSTANT.URL_ACCESS_CONTROL_USERS_ACCOUNT_DROPDOWN + `?nameCheck=${body.nameCheck}`,
        method: 'GET',
        headers: headers()
      })
    }),
    getAccountCheckUserListsss: builder.mutation<
    RolesAssignmentAccountDatasssProps[],
      PartialRolesAssignmentCheckProps
    >({
      query: (body) => ({
        url: CONSTANT.URL_ACCESS_CONTROL_USERS_ACCOUNT_DROPDOWN + `?nameCheck=${body.nameCheck}`,
        method: 'GET',
        headers: headers()
      })
    }),
    getMainMenuList: builder.mutation<RolesAssignmentMenuProps[], void>({
      query: () => ({
        url: CONSTANT.URL_ROLES_ASSIGNMENT_MAIN_MENU_LIST,
        method: 'GET',
        headers: headers()
      })
    }),
    getSubMenuList: builder.mutation<RolesAssignmentMenuProps[], number>({
      query: (id) => ({
        url: CONSTANT.URL_ROLES_ASSIGNMENT_SUB_MENU_LIST + id,
        method: 'GET',
        headers: headers()
      })
    }),
    updateAccountPermission: builder.mutation<ReturnProps, PartialMenuPermissionProps>({
      query: (body) => ({
        url: CONSTANT.URL_ROLES_ASSIGNMENT_UPDATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    })
  })
})

export const {
  useGetAccountPermissionListMutation,
  useGetAccountPermissionListsssMutation,
  useGetAccountPermissionDetailQuery,
  useGetAccountPermissionDetailsssQuery,
  useDownloadAccountPermissionListMutation,
  useGetAccountListMutation,
  useGetAccountCheckUserListMutation,
  useGetAccountCheckUserListsssMutation,
  useGetMainMenuListMutation,
  useGetSubMenuListMutation,
  useUpdateAccountPermissionMutation
} = assignmentAPI
