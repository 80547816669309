import { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
    Autocomplete,
    Button,
    CircularProgress,
    Container,
    Grid,
    TextField,
    Typography,
} from '@mui/material'
import ReactQuill from 'react-quill'
import { decode, encode } from 'html-entities'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import type { PartialPreviewCopywritingProps } from 'modules/partial'
import { useGetCategoryDropdownMutation, useGetDetailCopywritingQuery, useUpdateCopywritingMutation } from 'store/copyright';
import type { DetailStateProps, ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import Preview from './preview/page'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
    if (!open) return <></>

    const { data, isFetching, isLoading } = useGetDetailCopywritingQuery(id)
    const [getCategoryDropdown, categoryDropdown] = useGetCategoryDropdownMutation();
    const [updateCopywriting, update] = useUpdateCopywritingMutation()

    const [isiPayload, setIsiPayload] = useState<PartialPreviewCopywritingProps>()
    const [dialogCreate, setDialogCreate] = useState<boolean>(false)



    const scheme = yup.object<PartialEditCopywritingProps>({
        CategoryPkId: yup.number().required('Category PkId is required'),
        Code: yup.string().required('Copywriting Code is required'),
        Name: yup.string().required('Copywriting Name is required'),
        ContentID: yup.string().required('ContentID is required'),
        ContentEN: yup.string().required('ContentEN is required')
    });

    const formik = useFormik<PartialEditCopywritingProps>({
        validationSchema: scheme,
        enableReinitialize: true,
        validateOnMount: true,
        initialValues: {
            PkId: (data && data.pkId) || 0,
            CategoryPkId: (data && data.categoryPkId) || 0,
            Code: (data && data.code) || '',
            Name: (data && data.name) || '',
            ContentID: (data && decode(data.contentID)) || '',
            ContentEN: (data && decode(data.contentEN)) || ''
        },
        onSubmit: (values: PartialEditCopywritingProps) => handleSubmit(values)
    })

    const handleSubmit = useCallback((values: PartialEditCopywritingProps) => {
        const formattedPayload = {
            PkId: values.PkId,
            CategoryPkId: values.CategoryPkId,
            Code: values.Code,
            Name: values.Name,
            ContentID: encode(values.ContentID),
            ContentEN: encode(values.ContentEN),
        };
        updateCopywriting({ body: formattedPayload });
        console.log('Formatted Payload:', formattedPayload);
    }, [updateCopywriting]);

    const onPress = () => {
        setIsiPayload(formik.values);
        console.log('formik.values', formik.values);
        setDialogCreate(true);
    };

    const errorData = formik.errors

    const categoryDropdownValue = (!categoryDropdown.isLoading &&
        categoryDropdown?.data?.find((brand) => brand.name.toLowerCase() === data?.category.toLowerCase())) || null;

    useEffect(() => {
        if (data) {
            getCategoryDropdown()
        }
    }, [data])

    const disableButton = !formik.isValid
    // console.log('disableButton', disableButton);

    return (
        <>
            <Dialog
                title='Edit Copywriting'
                open={open}
                onCancel={onClose}
                onSubmit={() => formik.handleSubmit()}
                loading={update.isLoading}
                isDisabled={disableButton}
                submitText={'Submit'}
            >
                <Container {...DialogStyle.Container}>
                    {(isLoading || isFetching) && !data && <Loading />}
                    {data && (
                        <>
                            <Autocomplete
                                options={(categoryDropdown && categoryDropdown.data) || []}
                                getOptionLabel={(list) => list.name}
                                isOptionEqualToValue={(option, value) =>
                                    option && value ? option.code == value.code : false
                                }
                                disabled={true}
                                onOpen={() => getCategoryDropdown()}
                                value={categoryDropdownValue}
                                onChange={(_, id) => {
                                    formik.setFieldValue('CategoryPkId', id?.pkId);
                                    const newNameCategory = id?.name || null;
                                    formik.setFieldValue('nameCategory', newNameCategory);
                                }}
                                ListboxProps={GlobalStyle.ListBox}
                                renderOption={(props, item) => (
                                    <li {...props} key={item.code}>
                                        {item.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name='CategoryPkId'
                                        label='Category'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {categoryDropdown.isLoading && (
                                                        <CircularProgress color='inherit' size={20} />
                                                    )}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            )
                                        }}
                                    />
                                )}
                            />
                            <TextField
                                id='Code'
                                disabled={true}
                                variant='outlined'
                                label='Copywriting Code'
                                value={formik.values.Code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.Code && Boolean(errorData.Code)}
                                helperText={formik.touched.Code && errorData.Code}
                                fullWidth
                            />
                            <TextField
                                id='Name'
                                variant='outlined'
                                label='Copywriting Name'
                                value={formik.values.Name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.Name && Boolean(errorData.Name)}
                                helperText={formik.touched.Name && errorData.Name}
                                fullWidth
                            />
                            <Grid item xs={12}>
                                <Typography {...DialogStyle.Title}>Bahasa ID</Typography>
                                <ReactQuill
                                    theme='snow'
                                    placeholder='Description'
                                    value={formik.values.ContentID}
                                    onChange={(e) => formik.setFieldValue('ContentID', GLOBAL.emptyQuill(e))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography {...DialogStyle.Title}>Language EN-US</Typography>
                                <ReactQuill
                                    theme='snow'
                                    placeholder='Description'
                                    value={formik.values.ContentEN}
                                    onChange={(e) => formik.setFieldValue('ContentEN', GLOBAL.emptyQuill(e))}
                                />
                            </Grid>
                        </>
                    )}
                </Container>
                <Button variant='contained' onClick={onPress}>
                    Preview
                </Button>
            </Dialog>
            {dialogCreate &&
                <Preview
                    open={dialogCreate}
                    onClose={() => setDialogCreate(false)}
                    data={isiPayload}
                />}
            <Notification
                open={!update.isLoading && !update.isUninitialized}
                onClose={() => (update.isError ? update.reset() : location.reload())}
                isError={Boolean(update.error) && update.isError}
                message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
            />
        </>
    )
}

export type PartialEditCopywritingProps = {
    PkId?: number
    CategoryPkId: number
    Code: string
    Name: string,
    ContentID: string,
    ContentEN: string
}

export default Edit