import CONSTANT from 'modules/constant'
import type { DiscountSettingDetailProps, DiscountTypeDropdownProps, PaginationProps, ResultProps, SubsDiscountSettingListProps } from 'modules/types'
import type { PartialCreateDiscountSettingProps, PartialDIsableEnableProps, PartialEditDiscountSettingProps } from 'modules/partial'
import { API, download, headers } from './api'

const PromoCodeAPI = API.injectEndpoints({
    endpoints: (builder) => ({
        getListDiscountSetting: builder.mutation<PaginationProps<SubsDiscountSettingListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_DISCOUNT_SETTING_LIST,
                method: 'POST',
                headers: headers({ useContentType: false }),
                body: body,
                formData: true
            })
        }),
        getDetailDiscountSetting: builder.query<DiscountSettingDetailProps, number | string>({
            query: (id) => ({
                url: CONSTANT.URL_DISCOUNT_SETTING_DETAIL + id,
                method: 'GET',
                headers: headers()
            })
        }),
        createDiscountSetting: builder.mutation<ResultProps, PartialCreateDiscountSettingProps>({
            query: (body) => ({
                url: CONSTANT.URL_DISCOUNT_SETTING_CREATE,
                method: 'POST',
                headers: headers(),
                body: body
            })
        }),
        getDiscountTypeDropdown: builder.mutation<DiscountTypeDropdownProps[], void>({
            query: () => ({
                url: CONSTANT.URL_DISCOUNT_TYPE_DROPDOWN,
                method: 'GET',
                headers: headers()
            })
        }),
        updateDiscountSetting: builder.mutation<ResultProps, PartialEditDiscountSettingProps>({
            query: (param) => ({
                url: CONSTANT.URL_DISCOUNT_SETTING_EDIT,
                method: 'PUT',
                headers: headers(),
                body: param,
            })
        }),
        updateStatusDiscountSetting: builder.mutation<ResultProps, PartialDIsableEnableProps>({
            query: (body) => ({
              url: CONSTANT.URL_DISCOUNT_SETTING_STATUS,
              method: 'PUT',
              headers: headers(),
              body: body
            })
          }),
          downloadDiscountSetting: builder.mutation<PaginationProps<SubsDiscountSettingListProps>, FormData>({
            query: (body) => ({
              url: CONSTANT.URL_DISCOUNT_SETTING_DOWNLOAD,
              method: 'POST',
              headers: headers({ useContentType: false }),
              responseHandler: download,
              body: body,
              formData: true
            })
          })
    })
})

export const {
    useGetListDiscountSettingMutation,
    useGetDetailDiscountSettingQuery,
    useCreateDiscountSettingMutation,
    useGetDiscountTypeDropdownMutation,
    useUpdateDiscountSettingMutation,
    useUpdateStatusDiscountSettingMutation,
    useDownloadDiscountSettingMutation
} = PromoCodeAPI
