import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Chip,
  Autocomplete,
  TextField,
  CircularProgress,
  FormHelperText,
} from '@mui/material'
import moment from 'moment'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import Notification from 'components/notification'
import type { ErrorProps } from 'modules/types'
import { useDownloadSubscriptionBluelinkMutation, useGeSubscriptionBluelinkListMutation } from 'store/subscriptionBluelink'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import Detail from './detail/detail'

const FreeSubscriptionBluelinkSubs = () => {
  const [getSubscriptionBluelink, listSubscriptionBluelink] = useGeSubscriptionBluelinkListMutation()
  const [downloadSubscriptionBluelink, download] = useDownloadSubscriptionBluelinkMutation()

  const [messageUntil, setMessageUntil] = useState<string>('')
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [randomKey, setRandomKey] = useState<number>(0)

  const [filter, setFilter] = useState({
    MinExpDate: '',
    MaxExpDate: '',
    Status: ''
  })

  const [payload, setPayload] = useState<PartialSubsBluelinkProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    MinExpDate: '',
    MaxExpDate: '',
    Status: ''
  })
  const formatDateWithTime = (value: string) =>
    value ? moment(value).format('MMM DD, YYYY HH:mm:ss') : '-';
  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search))
      formData.append('Search', payload.Search)

    if (!isEmpty(payload.MinExpDate)) formData.append('MinExpDate', payload.MinExpDate)
    if (!isEmpty(payload.MaxExpDate)) formData.append('MaxExpDate', payload.MaxExpDate)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)

    getSubscriptionBluelink(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search))
      formData.append('Search', payload.Search)

    if (!isEmpty(payload.MinExpDate)) formData.append('MinExpDate', payload.MinExpDate)
    if (!isEmpty(payload.MaxExpDate)) formData.append('MaxExpDate', payload.MaxExpDate)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

      downloadSubscriptionBluelink(formData)
  }

  const onFilter = (state: boolean) => {setDialogFilter(state), setMessageUntil('')}

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback: CallableFunction) => {
    setAnchor(null)
    callback(callback)
  }

  const tableHeadTitles = [
    'User ID',
    'VIN',
    'Exp Date',
    'Subscription Status'
  ]

  const openAnchor = Boolean(anchor)
  const totalPage =
    listSubscriptionBluelink.data && GLOBAL.tableTotalPage(listSubscriptionBluelink.data.recordsFiltered)
  const numbers = GLOBAL.tableNumber(payload.Start)

  const isEndDateGreaterThanStartDate = () => {
    if (
      messageUntil && dayjs(filter.MinExpDate).isAfter(dayjs(filter.MaxExpDate))
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    onResync()
    setMessageUntil('')
  }, [payload])

  return (
    <>
      <Content
        title='Subscription Bluelink List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={listSubscriptionBluelink.data && listSubscriptionBluelink.data.recordsFiltered}
        page={payload.Start + 1}
        isLoading={listSubscriptionBluelink.isLoading}
        useDownloadDropdown
      >
        {listSubscriptionBluelink.isSuccess &&
          listSubscriptionBluelink.data &&
          listSubscriptionBluelink.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.pkId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='Visibility' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.pkId}`}
                  aria-labelledby={`button-${index}-${table.pkId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                </Menu>
                <Detail
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                  id={table.pkId}
                />
              </TableCell>
              <TableCell>{table.userId || '-'}</TableCell>
              <TableCell>{table.vin || '-'}</TableCell>
              <TableCell>{formatDateWithTime(table.expDate) ||  '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.status === 'Active' ? 'Active' : 'InActive'}
                  color={table.status == 'Active' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {listSubscriptionBluelink.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter) || isEndDateGreaterThanStartDate()}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomKey(randomKey + 1)
          setFilter({
            MinExpDate: '',
            MaxExpDate: '',
            Status: ''
          })
          setPayload({
            ...payload,
            Start: 0,
            MinExpDate: '',
            MaxExpDate: '',
            Status: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, Start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={activeAndInActiveValues}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onChange={(_, renewalStatus) =>
            setFilter({
              ...filter,
              Status: (renewalStatus && String(renewalStatus.id)) || ''
            })
          }
          value={activeAndInActiveValues.find((e) => String(e.id) == filter.Status) || undefined}
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='Status'
              label='Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {listSubscriptionBluelink.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Typography><strong>Expired Date</strong></Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex', marginTop: -15}}>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MinExpDate ? dayjs(filter.MinExpDate) : null}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MinExpDate: (event && event.format('YYYY-MM-DD')) || '' })
              }
              label='Min Date'
             format='MMM, DD YYYY'
              sx={{ width: '190px' }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MaxExpDate ? dayjs(filter.MaxExpDate) : null}
              minDate={dayjs(filter.MinExpDate)}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MaxExpDate: (event && event.format('YYYY-MM-DD')) || '' })
              }
              onError={(error) => {
                if (error) {
                  setMessageUntil(filter.MaxExpDate)
                }
              }}
              label='Max Date'
                format='MMM, DD YYYY'
              sx={{ width: '190px' }}
            />
          </LocalizationProvider>
        </div>
        {messageUntil && dayjs(filter.MinExpDate).isAfter(dayjs(filter.MaxExpDate)) && (
          <FormHelperText sx={{ alignSelf: 'center', marginTop: -2 }} error id='MaxExpDate'>Max Date must be greater than Min Date</FormHelperText>
        )}
      </Filter>

      <Notification
        open={
          !listSubscriptionBluelink.isLoading &&
          !listSubscriptionBluelink.isUninitialized &&
          !listSubscriptionBluelink.isSuccess
        }
        onClose={() => (listSubscriptionBluelink.isError ? listSubscriptionBluelink.reset() : location.reload())}
        isError={Boolean(listSubscriptionBluelink.error) && listSubscriptionBluelink.isError}
        message={GLOBAL.returnExceptionMessage(
          listSubscriptionBluelink.isError,
          listSubscriptionBluelink.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}
export type PartialSubsBluelinkProps = {
  Start: number
  Length: number
  Search: string
  MinExpDate: string
  MaxExpDate: string
  Status: string
}

const activeAndInActiveValues = [
  { id: 'Active', name: 'Active' },
  { id: 'InActive', name: 'InActive' }
]

export default FreeSubscriptionBluelinkSubs
