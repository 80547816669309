/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export const DonutChart = ({ title, data }: DonutChartProps) => {
  const fontSizeRef = useRef<string | null>(null)

  const options: Highcharts.Options = {
    chart: {
      type: 'pie',
      events: {
        render: function (this) {
          const series = this.series[0]

          const x = series.center[0] + this.plotLeft
          const y = series.center[1] + this.plotTop

          fontSizeRef.current = `${series.center[2] / 12}px`

          this.subtitle.css({
            fontFamily: 'Hyundai Sans Text Office',
            fontSize: `${series.center[2] / 12}px`
          })
          this.subtitle.attr({ x, y })
        }
      }
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    colors: ['#2196F3', '#1976D2', '#0D47A1'],
    title: {
      text: title,
      align: 'left'
    },
    subtitle: {
      floating: true,
      verticalAlign: 'middle',
      useHTML: true,
      text: `
        <div style="position: absolute; transform: translateX(-50%); font-size: ${fontSizeRef.current}">
          Center
        </div>
      `
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 80,
      // borderWidth: 1,
      backgroundColor: '#FFFFFF',
      shadow: false,
      useHTML: true,
      labelFormatter: function (this: any) {
        return `
          <div>
            <div>${this.name}</div>
            <div style="display: flex; flexDirection: row">
              <div>${this.y}</div>
              <div>Cost of ${this.y}</div>
            <div>
          </div>
        `
      }
    },
    plotOptions: {
      pie: {
        innerSize: '75%'
      },
      series: {
        allowPointSelect: true,
        cursor: 'pointer',
        // dataLabels: [{
        //     enabled: true,
        //     distance: 20,
        //     format: '{point.name}'
        // }, {
        //     enabled: true,
        //     distance: -15,
        //     format: '{point.percentage:.0f}%',
        //     style: {
        //         fontSize: '0.9em'
        //     }
        // }],
        dataLabels: {
          enabled: false,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        },
        showInLegend: true
      }
    },
    series: [
      {
        type: 'pie',
        name: 'Registrations',
        data: data.map((d) => ({ ...d, y: d.value }))
      }
    ]
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

interface DonutChartProps {
  title: string
  data: { name: string; value: number }[]
}
