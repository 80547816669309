import CONSTANT from 'modules/constant'
import type {
  DropdownListProps,
  PaginationProps,
  PromoCodeBatcSubListProps,
  PromoCodeBatchDetaiProps,
  PromoCodeBatchDetailsProps,
  PromoCodeBatchListProps,
  PromoCodeBatchSubsDetailProps,
  PromoCodeQrCode,
  ResultProps
} from 'modules/types'
import type {
  PartialDIsableEnableProps,
  PartialPromoCodeBatchCreateProps,
  // PartialPromoCodeBatchEditTempProps,
  PartialPromoCodeSubProps,
  PromoCodeQrCodePartial
} from 'modules/partial'
import { API, download, headers } from './api'

const PromoCodeBatchAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getPromoCodeBatchList: builder.mutation<PaginationProps<PromoCodeBatchListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadPromoCodeBatch: builder.mutation<PaginationProps<PromoCodeBatchListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    deletePromoCodeBatch: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_DELETE + id,
        method: 'DELETE',
        headers: headers()
      })
    }),
    getDetailPromoCodeBatch: builder.query<PromoCodeBatchDetaiProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    getDetailPromoCodeBatch111: builder.query<PromoCodeBatchDetailsProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_DETAIL111 + id,
        method: 'GET',
        headers: headers()
      })
    }),
    dropDownPromoCodeBatchStatus: builder.mutation<DropdownListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_DROPDOWN_STATUS,
        method: 'GET',
        headers: headers()
      })
    }),
    dropDownPromoCodeBatchReward: builder.mutation<DropdownListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_DROPDOWN_REWARD,
        method: 'GET',
        headers: headers()
      })
    }),
    createPromoCodeBatch: builder.mutation<ResultProps, PartialPromoCodeBatchCreateProps>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updatePromoCodeBatch: builder.mutation<ResultProps, PartialPromoCodeBatchEditTempProps>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_UPDATE,
        method: 'PUT',
        headers: headers(),
        body: body
      })
    }),
    updateStatusPromoCodeBatch: builder.mutation<ResultProps, PartialDIsableEnableProps>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_UPDATE_STATUS,
        method: 'PUT',
        headers: headers(),
        body: body
      })
    }),
    getPromoCodeBatchSubList: builder.mutation<PaginationProps<PromoCodeBatcSubListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_SUB_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadPromoCodeBatchSub: builder.mutation<PaginationProps<PromoCodeBatchListProps>, FormData>(
      {
        query: (body) => ({
          url: CONSTANT.URL_PROMO_CODE_BATCH_SUB_DOWNLOAD,
          method: 'POST',
          headers: headers({ useContentType: false }),
          responseHandler: download,
          body: body,
          formData: true
        })
      }
    ),
    updateStatusPromoCodeBatchSub: builder.mutation<ResultProps, PartialDIsableEnableProps>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_SUB_UPDATE_STATUS,
        method: 'PUT',
        headers: headers(),
        body: body
      })
    }),
    getDetailPromoCodeBatchSubsDetail: builder.query<PromoCodeBatchSubsDetailProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_SUBS_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),

    


    deleteMultiplesPromoCodeSub: builder.mutation<ResultProps, PartialPromoCodeSubProps>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_SUB_DELETE,
        method: 'DELETE',
        headers: headers(),
        body: body
      })
    }),
    getDetailPromoCodeBatchSub: builder.query<PromoCodeBatchDetaiProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_SUB_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    updatePromoCodeBatchSubValidity: builder.mutation<ResultProps, PartialPromoCodeSubProps>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_SUB_VALIDITY,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getPromoCodeQrCodeBy: builder.query<PromoCodeQrCode, PromoCodeQrCodePartial>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_QR_CODE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getPromoCodeQrCode: builder.mutation<PromoCodeQrCode, PromoCodeQrCodePartial>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_QR_CODE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    })
  })
})

export const {
  useGetPromoCodeBatchListMutation,
  useDownloadPromoCodeBatchMutation,
  useDeletePromoCodeBatchMutation,
  useDropDownPromoCodeBatchRewardMutation,
  useDropDownPromoCodeBatchStatusMutation,
  useCreatePromoCodeBatchMutation,
  useUpdatePromoCodeBatchMutation,
  useUpdateStatusPromoCodeBatchMutation,
  useGetPromoCodeBatchSubListMutation,
  useDownloadPromoCodeBatchSubMutation,
  useUpdateStatusPromoCodeBatchSubMutation,
  useGetDetailPromoCodeBatchSubsDetailQuery,

  useDeleteMultiplesPromoCodeSubMutation,
  useGetDetailPromoCodeBatchSubQuery,
  useUpdatePromoCodeBatchSubValidityMutation,
  useGetPromoCodeQrCodeMutation,

  useGetDetailPromoCodeBatchQuery,
  useGetDetailPromoCodeBatch111Query,
  useGetPromoCodeQrCodeByQuery
} = PromoCodeBatchAPI


export type PartialPromoCodeBatchEditTempProps = {
  PkId: number | null
  Name: string
  PromoNameEN: string
  PromoNameID: string
  Description: string
  MaxRedemption: number | null
  IsPercent: string
  Value: number | null
  MinimumPayment: number | null
  MaximumDeduction: number | null
  ValidFrom: string
  ValidUntil: string
  IsAllCarModel?: boolean
  SubscriptionPlan: SubscriptionApplicablePlanProps[]
  CarModel: CarModelCreateProps[]
}
export type SubscriptionApplicablePlanProps = {
  PlanPkId: number,
  SubscriptionPlanName: string
}

export type CarModelCreateProps = {
  PkId: number,
  CarName: string
}