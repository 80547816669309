import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  FormHelperText,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
// import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import moment from 'moment'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Filter from 'components/filter/filter'
import Content from 'components/content/content'
import type { ErrorProps } from 'modules/types'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import CONSTANT from 'modules/constant'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useDownloadAuditTraiMutation, useDropDownAuditTrailActvityMutation, useDropDownAuditTrailModuleMutation, useDropDownAuditTrailRoleMutation, useGetAuditTrailListMutation } from 'store/audittrail'
import Detail from './detail/detail'

const AuditTrailList = () => {
  const [getListAuditTrail, audittrail] = useGetAuditTrailListMutation()
  const [getActivityDropdownList, activityDropdown] = useDropDownAuditTrailActvityMutation()
  const [getModuleDropdownList, moduleDropdown] = useDropDownAuditTrailModuleMutation()
  const [getRoleDropdownList, roleDropdown] = useDropDownAuditTrailRoleMutation()
  const [downloadAudittrail, download] = useDownloadAuditTraiMutation()

  const [messageUntil, setMessageUntil] = useState<string>('')

  const [filter, setFilter] = useState({
    Activity: '',
    Module: '',
    Role: '',
    StartTimestamp: '',
    EndTimestamp: ''
  })
  const [payload, setPayload] = useState<PartialAuditTrailProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    Activity: '',
    Module: '',
    Role: '',
    StartTimestamp: '',
    EndTimestamp: ''
  })
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  //   const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  // const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [randomNumber, setRandomNumber] = useState<number>(0)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })
  const onResync = () => {
    const formData = new FormData()
    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Activity)) formData.append('Activity', payload.Activity)
    if (!isEmpty(payload.Module)) formData.append('Module', payload.Module)
    if (!isEmpty(payload.Role)) formData.append('Role', payload.Role)
    if (!isEmpty(payload.StartTimestamp)) formData.append('StartTimestamp', payload.StartTimestamp)
    if (!isEmpty(payload.EndTimestamp)) formData.append('EndTimestamp', payload.EndTimestamp)

    getListAuditTrail(formData)
  }
  const onFilter = (state: boolean) => { setDialogFilter(state), setMessageUntil('') }
  const onDownload = (type?: string) => {
    const formData = new FormData()
    formData.append('Start', String(0))
    formData.append('Length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Activity)) formData.append('Activity', payload.Activity)
    if (!isEmpty(payload.Module)) formData.append('Module', payload.Module)
    if (!isEmpty(payload.Role)) formData.append('Role', payload.Role)
    if (!isEmpty(payload.StartTimestamp)) formData.append('StartTimestamp', payload.StartTimestamp)
    if (!isEmpty(payload.EndTimestamp)) formData.append('EndTimestamp', payload.EndTimestamp)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadAudittrail(formData)
  }

  // const [date, setDate] = useState<{
  //   StartTimestamp: Dayjs | null
  //   EndTimestamp: Dayjs | null
  // }>({
  //   StartTimestamp: null,
  //   EndTimestamp: null
  // })

  useEffect(() => {
    onResync()
    setMessageUntil('')
  }, [payload])

  const tableHeadTitles = [
    'Activity',
    'Module',
    'Record ID',
    'Column name',
    'Role',
    'Created By',
    'Time Stamp'
  ]
  const formatDateWithTime = (value: string) =>
    value ? moment(value).format('MMM DD, YYYY HH:mm:ss') : '-';
  const numbers = GLOBAL.tableNumber(payload.Start)
  const openAnchor = Boolean(anchor)
  const totalPage = audittrail.data && GLOBAL.tableTotalPage(audittrail.data.recordsFiltered)

  const isEndDateGreaterThanStartDate = () => {
    if (
      messageUntil && dayjs(filter.StartTimestamp).isAfter(dayjs(filter.EndTimestamp))
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Content
        title='Audit Trail List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        useDownloadDropdown
        // onAdditional={onCreate}
        additionalTitle='Create New Plan'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={audittrail.data && audittrail.data.recordsFiltered}
        page={payload.Start + 1}
        isLoading={audittrail.isLoading}
      >
        {audittrail.isSuccess &&
          audittrail.data &&
          audittrail.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.pkId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='VisibilityOutlined' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.pkId}`}
                  aria-labelledby={`button-${index}-${table.pkId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View detail
                  </MenuItem>
                </Menu>
                <Detail
                  id={table.pkId}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
              </TableCell>
              <TableCell>{table.activity || '-'}</TableCell>
              <TableCell>{table.module || '-'}</TableCell>
              <TableCell>{table.recordId || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.columnName || '-'}</TableCell>
              <TableCell>{table.role || '-'}</TableCell>
              <TableCell>{table.createdName || '-'}</TableCell>
              <TableCell>{formatDateWithTime(table.timestamp) || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>
      {audittrail.isLoading && <Loading />}
      <Notification
        open={!audittrail.isLoading && !audittrail.isUninitialized && !audittrail.isSuccess}
        onClose={() => (audittrail.isError ? audittrail.reset() : location.reload())}
        isError={Boolean(audittrail.error) && audittrail.isError}
        message={GLOBAL.returnExceptionMessage(audittrail.isError, audittrail.error as ErrorProps)}
      />
      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter) || isEndDateGreaterThanStartDate()}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomNumber(randomNumber + 1)
          setFilter({
            Activity: '',
            Module: '',
            Role: '',
            StartTimestamp: '',
            EndTimestamp: ''
          })
          // setDate({
          //   StartTimestamp: null,
          //   EndTimestamp: null
          // })
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            Activity: '',
            Module: '',
            Role: '',
            StartTimestamp: '',
            EndTimestamp: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(activityDropdown && activityDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getActivityDropdownList()}
          onChange={(_, id) =>
            setFilter({
              ...filter,
              Activity: (id && String(id.name)) || ''
            })
          }
          value={
            (activityDropdown &&
              activityDropdown.data &&
              activityDropdown.data.find((e) => String(e.name) == filter.Activity)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.name}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='Activity'
              label='Activity'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {activityDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(moduleDropdown && moduleDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getModuleDropdownList()}
          onChange={(_, partner) =>
            setFilter({
              ...filter,
              Module: (partner && String(partner.name)) || ''
            })
          }
          value={
            (moduleDropdown &&
              moduleDropdown.data &&
              moduleDropdown.data.find((e) => String(e.name) == filter.Module)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.name}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='Module'
              label='Module'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {moduleDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(roleDropdown && roleDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.pkId == value.pkId : false
          }
          onOpen={() => getRoleDropdownList()}
          onChange={(_, partner) =>
            setFilter({
              ...filter,
              Role: (partner && String(partner.code)) || ''
            })
          }
          value={
            (roleDropdown &&
              roleDropdown.data &&
              roleDropdown.data.find((e) => String(e.code) == filter.Role)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.code}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='Role'
              label='Role'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {roleDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Typography><strong>Time Stamp</strong></Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex', marginTop: '-10px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              // value={date.StartTimestamp}
              value={filter.StartTimestamp ? dayjs(filter.StartTimestamp) : null}
              onChange={(e) => {
                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                setFilter({ ...filter, StartTimestamp: newDate })
                // setDate((prevState) => ({ ...prevState, minDateSignUp: e }))
              }}
              label='Min Date'
              format='MMM, DD YYYY'
              sx={{ width: "190px" }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              // value={date.EndTimestamp}
              value={filter.EndTimestamp ? dayjs(filter.EndTimestamp) : null}
              minDate={dayjs(filter.StartTimestamp)}
              onChange={(e) => {
                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                setFilter({ ...filter, EndTimestamp: newDate })
                // setDate((prevState) => ({ ...prevState, maxDateSignUp: e }))
              }}
              onError={(error) => {
                if (error) {
                  setMessageUntil(filter.EndTimestamp)
                }
              }}
              label='Max Date'
              format='MMM, DD YYYY'
              sx={{ width: "190px" }}
            />
          </LocalizationProvider>
        </div>
        {messageUntil && dayjs(filter.StartTimestamp).isAfter(dayjs(filter.EndTimestamp)) && (
          <FormHelperText sx={{alignSelf: 'center', marginTop: -2}} error id='EndTimestamp'>Max Date must be greater than Min Date</FormHelperText>
        )}
      </Filter>
    </>
  )
}

export type PartialAuditTrailProps = {
  Start: number
  Length: number
  Search: string
  Activity: string
  Module: string
  Role: string
  StartTimestamp: string
  EndTimestamp: string
}

export default AuditTrailList
