import { useEffect } from 'react'
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material'
import { EventAvailable } from '@mui/icons-material'
import moment from 'moment'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useLazyGetSasTokenQuery } from 'store/blob'
import { useGetSubsTransactionDetailQuery } from 'store/subscriptionTransaction'
import GLOBAL from 'modules/global'
import { StatusChip } from 'pages/subscription/transaction/statusChip'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetSubsTransactionDetailQuery(id)

  const [getSasToken, sasTokenResult] = useLazyGetSasTokenQuery()

  useEffect(() => {
    if (!sasTokenResult.isSuccess || !sasTokenResult.data) return

    const queryParam = sasTokenResult.data.sasToken

    if (!queryParam) return

    const url = `${data?.paymentInvoiceUrl}?${queryParam}`
    window.open(url)
  }, [sasTokenResult.data])

  const download = (fileUrl: string) => {
    getSasToken(fileUrl)
  }

  return (
    <>
      <Drawer open={open} title='Subscription Detail' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {isLoading || isFetching ? (
            <Loading />
          ) : data ? (
            <>
              {data && (
                <>
                  <Box sx={{ mb: 4 }}>
                    <Box
                      sx={{
                        border: '1px solid #E4DCD3',
                        backgroundColor: '#F6F3F2',
                        p: 1.5,
                        mb: 2.5
                      }}
                    >
                      <Grid
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 12
                        }}
                      >
                        <img
                          src={data.iconUrl || 'https://dummyimage.com/42'}
                          style={{ width: 42, height: 42, borderRadius: 100 }}
                        />
                        <Grid
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: 12
                          }}
                        >
                          <Typography sx={{ fontSize: 16 }}>Plan</Typography>
                          <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                            {data.subscriptionPlanData}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373' }}>
                          Subscription Period
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                          {moment(data.subscriptionStartDate).format('DD MMM YYYY')} -{' '}
                          {moment(data.subscriptionEndDate).format('DD MMM YYYY')}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          border: '1px solid #E4DCD3',
                          backgroundColor: '#FFFFFF',
                          px: '12px',
                          py: '8px'
                        }}
                      >
                        <EventAvailable style={{ height: 24, width: 24 }} />
                        <Grid
                          style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            marginLeft: 12
                          }}
                        >
                          <Typography sx={{ fontSize: 14, color: 'black' }}>
                            Auto Renewal
                          </Typography>
                          {/* <Typography sx={{fontSize: 12, color: '#737373'}}>Next Payment Jan 01, 2026</Typography> */}
                          <Typography sx={{ fontSize: 12, color: 'red' }}>
                            Next Payment {moment(data.nextPaymentDate).format('DD MMM YYYY')}
                          </Typography>
                        </Grid>
                      </Box>
                    </Box>

                    <Box>
                      <Typography variant='h5' sx={{ fontSize: 16, fontWeight: 600, mb: 1.5 }}>
                        Transaction Summary
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Transaction Status
                        </Typography>
                        <StatusChip status={data.status} />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Transaction ID
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                          {data.transactionId}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Transaction Date
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                          {moment(data.transactionDate).format('DD MMM YYYY HH:mm:ss')}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Invoice ID
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                          {data.invoiceId}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Registered Owner
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                          {data.registeredOwner}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Email
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{data.email}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Auto Renewal
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                          {data.autoRenewalStatus}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>VIN</Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{data.vin}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Car Model
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                          {data.carModelName}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Payment Method
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                          {data.paymentMethod}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Date of Birth
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                          {moment(data.dob).format('DD MMMM YYYY')}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Transaction Region
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                          {data.transactionRegional}
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Typography variant='h5' sx={{ fontSize: 16, fontWeight: 600, mb: 1.5 }}>
                        Payment Information
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Price
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                          Rp {GLOBAL.numberFormat(data.price)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Discount
                        </Typography>
                        <Grid
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end'
                          }}
                        >
                          <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                            -Rp {GLOBAL.numberFormat(data.discount)}
                          </Typography>
                          <Typography sx={{ color: '#737373', fontSize: 14 }}>
                            Discount End Year
                          </Typography>
                        </Grid>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>
                          Promo Code
                        </Typography>
                        <Grid
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end'
                          }}
                        >
                          <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                            -Rp {GLOBAL.numberFormat(data.promoCode)}
                          </Typography>
                          <Typography sx={{ color: '#737373', fontSize: 14 }}>
                            {data.promoName}
                          </Typography>
                        </Grid>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          mb: 1.5
                        }}
                      >
                        <Typography sx={{ fontSize: 16, color: '#737373', mr: 2 }}>VAT</Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                          Rp {GLOBAL.numberFormat(data.vat)}
                        </Typography>
                      </Box>
                    </Box>

                    <Divider />

                    <Grid
                      style={{
                        marginTop: 16,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                        Total (Including Tax)
                      </Typography>
                      <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                        Rp {GLOBAL.numberFormat(data.totalPrice)}
                      </Typography>
                    </Grid>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}
                  >
                    {data.status.toLowerCase() === 'success' && (
                      <Button
                        variant='contained'
                        size='large'
                        sx={{
                          fontSize: 18,
                          color: 'white',
                          backgroundColor: '#002C5F'
                        }}
                        onClick={() => download(data.paymentInvoiceUrl ?? '')}
                      >
                        Download Invoice
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </>
          ) : (
            <div>No data.</div>
          )}
        </Container>

        <Notification
          open={
            !sasTokenResult.isLoading &&
            !sasTokenResult.isUninitialized &&
            !sasTokenResult.isSuccess
          }
          // onClose={() => (sasTokenResult.isError ? sasTokenResult.reset() : location.reload())}
          isError={Boolean(sasTokenResult.error) && sasTokenResult.isError}
          message={GLOBAL.returnExceptionMessage(
            sasTokenResult.isError,
            sasTokenResult.error as ErrorProps
          )}
        />
      </Drawer>
    </>
  )
}

export default Detail
