import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Filter from 'components/filter/filter'
import Content from 'components/content/content'
import type { ErrorProps } from 'modules/types'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import { useGetSchemeTypeDropdownMutation } from 'store/serviceBookingEventBanner'
import { useGetMemberTypeDropdownsssMutation } from 'store/dropdown'
import { useDownloadSubsPlanMutation, useGetListSubsPlanMutation, useUpdateStatusSubsPlanMutation } from 'store/subsplan'
// import type { PartialSubsPlan } from 'modules/partial'
import CONSTANT from 'modules/constant'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import Create from './create/create'
import Edit from './edit/page'
import Detail from './detail/detail'

const SubscriptionPlanList = () => {
  const [getListPromoCode, promoCode] = useGetListSubsPlanMutation()
  const [updateStatusPlan, update] = useUpdateStatusSubsPlanMutation()

  const [getSchemetype, schemeTypeDropdown] = useGetSchemeTypeDropdownMutation()
  const [getCarModelDropdown, carModelList] = useGetMemberTypeDropdownsssMutation()
  const [downloadPlan, download] = useDownloadSubsPlanMutation()

  const [messageUntil, setMessageUntil] = useState<string>('')
  const [messagePriceUntil, setMessagePriceUntil] = useState<string>('')

  const [filter, setFilter] = useState({
    duration: '',
    CarModel: '',
    PriceFrom: '',
    priceUntil: '',
    ValidFrom: '',
    ValidUntil: '',
    Status: ''
  })
  const [payload, setPayload] = useState<PartialSubsPlan>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    duration: '',
    CarModel: '',
    PriceFrom: '',
    priceUntil: '',
    ValidFrom: '',
    ValidUntil: '',
    Status: ''
  })

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [randomNumber, setRandomNumber] = useState<number>(0)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })
  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.duration)) formData.append('duration', payload.duration)
    if (!isEmpty(payload.CarModel)) formData.append('CarModel', payload.CarModel)
    if (!isEmpty(payload.PriceFrom)) formData.append('PriceFrom', payload.PriceFrom)
    if (!isEmpty(payload.priceUntil)) formData.append('priceUntil', payload.priceUntil)
    if (!isEmpty(payload.ValidFrom)) formData.append('ValidFrom', payload.ValidFrom)
    if (!isEmpty(payload.ValidUntil)) formData.append('ValidUntil', payload.ValidUntil)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)

    getListPromoCode(formData)
  }
  const onFilter = (state: boolean) => {setDialogFilter(state), setMessageUntil('')}
  const onDownload = (type?: string) => {
    const formData = new FormData()
    formData.append('Start', String(0))
    formData.append('Length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.duration)) formData.append('duration', payload.duration)
    if (!isEmpty(payload.CarModel)) formData.append('CarModel', payload.CarModel)
    if (!isEmpty(payload.PriceFrom)) formData.append('PriceFrom', payload.PriceFrom)
    if (!isEmpty(payload.priceUntil)) formData.append('priceUntil', payload.priceUntil)
    if (!isEmpty(payload.ValidFrom)) formData.append('ValidFrom', payload.ValidFrom)
    if (!isEmpty(payload.ValidUntil)) formData.append('ValidUntil', payload.ValidUntil)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadPlan(formData)
  }
  const onCreate = () => setDialogCreate(true)

  useEffect(() => {
    onResync()
  }, [payload])

  const tableHeadTitles = [
    'Subscription Plan Name',
    'Scheme Type',
    'Price',
    'Sequence No',
    'Start Date',
    'End Date',
    'Created Date',
    'Car Model',
    'Status'
  ]
  const numbers = GLOBAL.tableNumber(payload.start)
  const openAnchor = Boolean(anchor)
  const totalPage = promoCode.data && GLOBAL.tableTotalPage(promoCode.data.recordsFiltered)

  // const carNames = (carModels: CarModelProps[]) => {
  //   if (!Array.isArray(carModels) || carModels.length === 0) {
  //     return '-';
  //   }
  //   return carModels.map(car => car.carName).join(', ') || '-';
  // };

  const isEndDateGreaterThanStartDate = () => {
    if (
      messageUntil && dayjs(filter.ValidFrom).isAfter(dayjs(filter.ValidUntil))
    ) {
      return true
    } else {
      return false
    }
  }


  return (
    <>
      <Content
        title='Plan List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        useDownloadDropdown
        onAdditional={onCreate}
        additionalTitle='Create New Plan'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={promoCode.data && promoCode.data.recordsFiltered}
        page={payload.start + 1}
        isLoading={promoCode.isLoading}
      >
        {promoCode.isSuccess &&
          promoCode.data &&
          promoCode.data?.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.pkId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.pkId}`}
                  aria-labelledby={`button-${index}-${table.pkId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuModalItem
                    title={table.status === 'DISABLED' ? 'Enable' : 'Disable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        updateStatusPlan({
                          Id: table.pkId,
                          IsActive: table.status == 'DISABLED'
                        })
                      )
                    }
                  >
                    {table.status == 'DISABLED' ? 'Enabled' : 'Disabled'}
                  </MenuModalItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                </Menu>
                <Detail
                  id={table.pkId}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                {dialogUpdate && menu == index && (
                  <Edit
                    id={table.pkId}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>
                {table.iconUrl ? (
                  <img
                    src={table.iconUrl}
                    alt={table.subscriptionPlanName}
                    style={{
                      height: 24,
                      width: 24,
                      borderRadius: 100,
                      objectFit: 'contain',
                      marginBottom: '-8px',
                      marginRight: '8px',
                    }}
                  />
                ) : (
                  <img
                    src={'https://dummyimage.com/50'}
                    alt={table.subscriptionPlanName}
                    style={{
                      height: 24,
                      width: 24,
                      borderRadius: 100,
                      objectFit: 'contain',
                      marginBottom: '-8px',
                      marginRight: '8px',
                    }}
                  />
                )}
                {table.subscriptionPlanName || '-'}</TableCell>
              <TableCell>{table.duration || '-'}</TableCell>
              <TableCell>{`Rp ${GLOBAL.formatCurrency(table.price)} ` || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.seqNo || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.validFrom) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.validUntil) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDateWithTime(table.createdAt) || '-'}</TableCell>
              {/* <TableCell>{carNames(table.carModel) || '-'}</TableCell> */}
              <TableCell>{table.carModel || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.status === 'ENABLED' ? 'Enabled' : 'Disabled'}
                  color={table.status == 'ENABLED' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}
      {promoCode.isLoading && <Loading />}
      <Notification
        open={!promoCode.isLoading && !promoCode.isUninitialized && !promoCode.isSuccess}
        onClose={() => (promoCode.isError ? promoCode.reset() : location.reload())}
        isError={Boolean(promoCode.error) && promoCode.isError}
        message={GLOBAL.returnExceptionMessage(promoCode.isError, promoCode.error as ErrorProps)}
      />
      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter) || isEndDateGreaterThanStartDate()}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomNumber(randomNumber + 1)
          setFilter({
            duration: '',
            CarModel: '',
            PriceFrom: '',
            priceUntil: '',
            ValidFrom: '',
            ValidUntil: '',
            Status: '',
          })
          setPayload({
            start: 0,
            length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            duration: '',
            CarModel: '',
            PriceFrom: '',
            priceUntil: '',
            ValidFrom: '',
            ValidUntil: '',
            Status: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(schemeTypeDropdown && schemeTypeDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getSchemetype()}
          onChange={(_, id) => {
            if (!isEmpty(id)) setFilter({ ...filter, duration: id?.name })
          }}
          value={
            (schemeTypeDropdown &&
              schemeTypeDropdown.data &&
              schemeTypeDropdown.data.find((e) => e.name == filter.duration)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.name}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='duration'
              label='Scheme Type'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {schemeTypeDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(carModelList && carModelList.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getCarModelDropdown()}
          onChange={(_, id) => {
            if (!isEmpty(id)) setFilter({ ...filter, CarModel: id?.name })
          }}
          value={
            (carModelList &&
              carModelList.data &&
              carModelList.data.find((e) => e.name == filter.CarModel)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.name}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='CarModel'
              label='Car Model'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {carModelList.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Typography>Price Range</Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
          <TextField
            id='PriceFrom'
            variant='outlined'
            label='Price From'
            type='number'
            // InputProps={{
            //   inputProps: {
            //     min: filter.MaxValidTo
            //   }
            // }}
            sx={{ width: "190px" }}
            value={filter.PriceFrom}
            onChange={(event) => setFilter({ ...filter, PriceFrom: event.target.value })}
            fullWidth
          />
          <TextField
            id='priceUntil'
            variant='outlined'
            label='Price To'
            type='number'
            value={filter.priceUntil}
            InputProps={{
              inputProps: {
                max: 100, min: 10
              }
            }}
            onError={(error) => {
              if (error) {
                setMessagePriceUntil(filter.priceUntil)
              }
            }}
            sx={{ width: "190px" }}
            onChange={(event) => setFilter({ ...filter, priceUntil: event.target.value })}
            fullWidth
          />
        </div>
        {messagePriceUntil && dayjs(filter.PriceFrom).isAfter(dayjs(filter.priceUntil)) && (
          <FormHelperText sx={{alignSelf: 'center', marginTop: -2}} error id='priceUntil'>Start Date must be greater than End Date</FormHelperText>
        )}
        <Typography>Period</Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.ValidFrom ? dayjs(filter.ValidFrom) : null}
              // minDate={dayjs(filter.ValidFrom)}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, ValidFrom: (event && event.format('YYYY-MM-DD')) || '' })
              }
              label='Start Date'
              format='MMM DD, YYYY'
              sx={{ width: "190px" }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.ValidUntil ? dayjs(filter.ValidUntil) : null}
              minDate={dayjs(filter.ValidFrom)}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, ValidUntil: (event && event.format('YYYY-MM-DD')) || '' })
              }
              onError={(error) => {
                if (error) {
                  setMessageUntil(filter.ValidUntil)
                }
              }}
              label='End Date'
              format='MMM DD, YYYY'
              sx={{ width: "190px" }}
            />
          </LocalizationProvider>
        </div>
        {messageUntil && dayjs(filter.ValidFrom).isAfter(dayjs(filter.ValidUntil)) && (
          <FormHelperText sx={{alignSelf: 'center', marginTop: -2}} error id='ValidUntil'>Start Date must be greater than End Date</FormHelperText>
        )}

        
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.Status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, Status: event.target.value })}
          >
            <MenuItem value='ENABLED'>Enabled</MenuItem>
            <MenuItem value='DISABLED'>Disabled</MenuItem>
          </Select>
        </FormControl>
      </Filter>
      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}
export type PartialSubsPlan = PartialFormDataProps & {
  duration: string
  CarModel: string
  PriceFrom: string
  priceUntil: string
  ValidFrom: string
  ValidUntil: string
  Status: string
}

export type PartialFormDataProps = {
  start: number
  length: number
  field?: string
  Search: string
}

export type CarModelProps = {
  carModelId: number
  pkId: number
  carName: string
  isActive: string
}

export default SubscriptionPlanList
