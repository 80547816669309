import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  TextField,
  Autocomplete,
  Chip,
  FormControl,
  InputLabel,
  Select
} from '@mui/material'
import { useDownloadSubsContentMutation, useGetListSubsContentMutation, useUpdateStatusSubsContentMutation } from 'store/subsContent'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
// import type { PartialFormDataCapsProps, PartialSubsContentProps } from 'modules/partial'
import Notification from 'components/notification'
import type { ErrorProps } from 'modules/types'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import MenuModalItem from 'components/menuItem/menuItem'
import { useGetApplicableSubsPlanDropdownMutation } from 'store/dropdown'
import Create from './create/create'
import Edit from './edit/page'
import Detail from './detail/detail'


const SubscriptionContentList = () => {
  const [getSubsContentList, listSubsContent] = useGetListSubsContentMutation()
  const [updateStatusContent, update] = useUpdateStatusSubsContentMutation()
  const [downloadSubsContent, download] = useDownloadSubsContentMutation()

  const [getApplicableSubsPlanDropdown, applicableSubsPlanDropdown] = useGetApplicableSubsPlanDropdownMutation();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [randomKey, setRandomKey] = useState<number>(0)

  const [filter, setFilter] = useState<PartialSubsContentProps>({
    SubscriptionPlan: '',
    Status: ''
  })

  const [payload, setPayload] = useState<PartialSubsContentProps & PartialFormDataCapsProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    SubscriptionPlan: '',
    Status: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search);
    if (!isEmpty(payload.SubscriptionPlan)) formData.append('SubscriptionPlan', payload.SubscriptionPlan)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    getSubsContentList(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.SubscriptionPlan)) formData.append('SubscriptionPlan', payload.SubscriptionPlan)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadSubsContent(formData)
  }

  const onCreate = () => setDialogCreate(true)

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback: CallableFunction) => {
    setAnchor(null)
    callback(callback)
  }

  const tableHeadTitles = [
    'Subscription Content Name',
    'Applicable Subscription Plan',
    'Subtitle',
    'Status'
  ]

  const openAnchor = Boolean(anchor)
  const totalPage =
    listSubsContent.data && GLOBAL.tableTotalPage(listSubsContent.data.recordsFiltered)
  const numbers = GLOBAL.tableNumber(payload.Start)

  // const applicableSubsPlanList = (subsPlan: subscriptionPlanList[]) => {
  //   if (!Array.isArray(subsPlan) || subsPlan.length === 0) {
  //     return '-';
  //   }
  //   return subsPlan.map(plan => plan.subscriptionPlanName).join(', ') || '-';
  // };

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Subscription Content List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={listSubsContent.data && listSubsContent.data.recordsFiltered}
        page={payload.Start + 1}
        isLoading={listSubsContent.isLoading}
        useDownloadDropdown
        onAdditional={onCreate}
        additionalTitle='New Subscription Content'
        additionalPrefixIcon='Add'
      >
        {listSubsContent.isSuccess &&
          listSubsContent.data &&
          listSubsContent.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.pkId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.pkId}`}
                  aria-labelledby={`button-${index}-${table.pkId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuModalItem
                    title={table.status === 'DISABLED' ? 'Enable' : 'Disable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        updateStatusContent({
                          Id: table.pkId,
                          IsActive: table.status == 'DISABLED'
                        })
                      )
                    }
                  >
                    {table.status == 'DISABLED' ? 'Enabled' : 'Disabled'}
                  </MenuModalItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                </Menu>
                <Detail
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                  id={table.pkId}
                />
                {dialogUpdate && menu == index && (
                  <Edit
                    id={table.pkId}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{table.contentName || '-'}</TableCell>
              {/* <TableCell>{applicableSubsPlanList(table.subscriptionPlan)}</TableCell> */}
              <TableCell>{table.subscriptionPlan}</TableCell>
              <TableCell>{table.subtitle}</TableCell>
              <TableCell>
                <Chip
                  label={table.status === 'ENABLED' ? 'Enabled' : 'Disabled'}
                  color={table.status == 'ENABLED' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}
      {listSubsContent.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomKey(randomKey + 1)
          setFilter({
            SubscriptionPlan: '',
            Status: ''
          })
          setPayload({
            ...payload,
            Start: 0,
            SubscriptionPlan: '',
            Status: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, Start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(applicableSubsPlanDropdown && applicableSubsPlanDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getApplicableSubsPlanDropdown()}
          onChange={(_, reward) => {
            if (!isEmpty(reward)) setFilter({ ...filter, SubscriptionPlan: reward?.name })
          }}
          value={
            (applicableSubsPlanDropdown &&
              applicableSubsPlanDropdown.data &&
              applicableSubsPlanDropdown.data.find((e) => e.name == filter.SubscriptionPlan)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.name}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='SubscriptionPlan'
              label='Subscription Plan'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {applicableSubsPlanDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.Status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, Status: event.target.value })}
          >
            <MenuItem value='ENABLED'>Enabled</MenuItem>
            <MenuItem value='DISABLED'>Disabled</MenuItem>
          </Select>
        </FormControl>
      </Filter>

      <Notification
        open={
          !listSubsContent.isLoading &&
          !listSubsContent.isUninitialized &&
          !listSubsContent.isSuccess
        }
        onClose={() => (listSubsContent.isError ? listSubsContent.reset() : location.reload())}
        isError={Boolean(listSubsContent.error) && listSubsContent.isError}
        message={GLOBAL.returnExceptionMessage(
          listSubsContent.isError,
          listSubsContent.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}
export type PartialSubsContentProps = {
  SubscriptionPlan: string
  Status: string
}
export type PartialFormDataCapsProps = {
  Start: number
  Length: number
  Search: string
}

export type subscriptionPlanList = {
  planPkId: number
  subscriptionPlanName: string
}
export default SubscriptionContentList
