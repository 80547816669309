import { useEffect } from 'react'
import {
    Container,
    Dialog,
    Grid,
    Typography
} from '@mui/material'
import { useGetCategoryDropdownMutation } from 'store/copyright'
import DialogStyle from './style'


export type CopywritingPayload = {
    nameCategory?: string
    CategoryPkId?: number
    Code: string;
    Name: string;
    ContentID: string;
    ContentEN: string;
}
const Preview = ({ open, onClose, data }: DetailStateProps & { data: CopywritingPayload | undefined }) => {
    const [getCategoryDropdown, categoryDropdown] = useGetCategoryDropdownMutation();

    const categoryDropdownValue =
        !categoryDropdown.isLoading &&
        categoryDropdown?.data?.find(
            (brand) => brand.pkId === data?.CategoryPkId
        ) || null;

    const categoryName = categoryDropdownValue?.name || '-';
    console.log('categoryName', categoryName);


    const cleanStrIndo = data?.ContentID.replace(/<[^>]*>/g, '');
    const cleanStrENG = data?.ContentEN.replace(/<[^>]*>/g, '');

    useEffect(() => {
        if (data) {
            getCategoryDropdown()
            console.log('data', data);
        }
    }, [data])

    return (
        <>
            <Dialog
                title='New Copywriting'
                open={open}
                onClose={onClose}
            >
                <Container {...DialogStyle.Container}>
                    <Grid style={{ width: '100%', height: '835px', display: 'flex', flexDirection: 'column', paddingLeft: 15, paddingTop: 15 }}>
                        {/* <Grid style={{ alignSelf: 'center' }}>
                            <Typography variant="h6"><strong>Preview</strong></Typography>
                        </Grid> */}

                        <Grid style={{  borderBottomStyle:'solid', borderWidth: 1, backgroundColor: '#ecefff', marginTop: 10, width: '560px', display: 'flex', flexDirection: 'row' }}>
                            <Grid style={{ width: '280px' }}>
                                <Typography style={{ color: '#737373' }}>Category</Typography>
                                <Typography variant="body1"><strong>{categoryName}</strong> </Typography>
                            </Grid>
                            <Grid style={{ width: '280px', marginLeft: 2 }}>
                                <Typography style={{ color: '#737373' }}>Copywriting Code</Typography>
                                <Typography variant="body1"><strong>{data?.Code ? data?.Code : '-'}</strong> </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'row', marginTop: 10, padding: 12 }} container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="body1"><strong>{categoryName}</strong></Typography>
                                <Typography variant="body1">{cleanStrIndo ? cleanStrIndo : '-'}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1"><strong>{categoryName}</strong></Typography>
                                <Typography variant="body1">{cleanStrENG ? cleanStrENG : '-'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>


                </Container>
            </Dialog>
        </>
    )
}

export type Children = {
    children?: React.ReactNode
}

export type DetailStateProps = Children & {
    title?: string
    open: boolean
    data: CopywritingPayload | undefined;
    maxWidth?: 'sm' | 'md'
    onClose: () => void
}


export default Preview