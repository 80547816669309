import { Chip } from '@mui/material'

export const StatusChip = ({ status }: StatusChipProps) => (
  <Chip label={status} color={getStatusColor(status)} size='small' style={{ borderRadius: 6 }} />
)

const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'paid':
      return 'success'
    case 'waiting for payment':
      return 'warning'
    case 'expired':
    case 'cancel':
      return 'error'
    default:
      return 'default'
  }
}

interface StatusChipProps {
  status: string
}
