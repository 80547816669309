import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import type { ErrorProps } from 'modules/types'
import { useDownloadPromoCodetransactionMutation, useGetListPromoCodeTransactionMutation, useGetPromoCodeTransactionDropdownQuery } from 'store/promoTransaction'
import GlobalStyle from 'modules/styles'
import Detail from './detail/detail'

const PromoRedemption = () => {
  const [getListPromoCodTransaction, promoCodeTransaction] = useGetListPromoCodeTransactionMutation()
  const [downloadPromoCodeTransaction, download] = useDownloadPromoCodetransactionMutation()

  const promoCodeTransactionDropdownResult = useGetPromoCodeTransactionDropdownQuery()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [messageUntil, setMessageUntil] = useState<string>('')
  const [randomKey, setRandomKey] = useState<number>(0)

  const [payload, setPayload] = useState<PartialPromoCodeTransactionProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    PromoName: '',
    StatusRedeemed: '',
    RedeemedAtFrom: '',
    RedeemedAtUntil: ''
  })

  const [filter, setFilter] = useState({
    PromoName: '',
    StatusRedeemed: '',
    RedeemedAtFrom: '',
    RedeemedAtUntil: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })
  const onResync = () => {
    const formData = new FormData()
    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.PromoName)) formData.append('PromoName', payload.PromoName)
    if (!isEmpty(payload.StatusRedeemed)) formData.append('StatusRedeemed', payload.StatusRedeemed)
    if (!isEmpty(payload.RedeemedAtFrom)) formData.append('RedeemedAtFrom', payload.RedeemedAtFrom)
    if (!isEmpty(payload.RedeemedAtUntil)) formData.append('RedeemedAtUntil', payload.RedeemedAtUntil)
    getListPromoCodTransaction(formData)
  }
  const onFilter = (state: boolean) => {setDialogFilter(state), setMessageUntil('')}
  const onDownload = (type?: string) => {
    const formData = new FormData()
    formData.append('Start', String(0))
    formData.append('Length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.PromoName)) formData.append('PromoName', payload.PromoName)
    if (!isEmpty(payload.StatusRedeemed)) formData.append('StatusRedeemed', payload.StatusRedeemed)
    if (!isEmpty(payload.RedeemedAtFrom)) formData.append('RedeemedAtFrom', payload.RedeemedAtFrom)
    if (!isEmpty(payload.RedeemedAtUntil)) formData.append('RedeemedAtUntil', payload.RedeemedAtUntil)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadPromoCodeTransaction(formData)
  }

  // const [date, setDate] = useState<{
  //   RedeemedAtFrom: Dayjs | null
  //   RedeemedAtUntil: Dayjs | null
  // }>({
  //   RedeemedAtFrom: null,
  //   RedeemedAtUntil: null
  // })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }
  useEffect(() => {
    onResync()
    setMessageUntil('')
  }, [payload])

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = [
    'User ID',
    'Redemption Status',
    'Promo Name',
    'Promo Code',
    'Redeemed At',
    'Created At'
  ]
  const numbers = GLOBAL.tableNumber(payload.Start)
  const openAnchor = Boolean(anchor)
  const totalPage =
    promoCodeTransaction.data && GLOBAL.tableTotalPage(promoCodeTransaction.data.recordsFiltered)

  const isEndDateGreaterThanStartDate = () => {
    if (
      messageUntil && dayjs(filter.RedeemedAtFrom).isAfter(dayjs(filter.RedeemedAtUntil))
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Content
        title='Promo Code Transaction List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={promoCodeTransaction.data && promoCodeTransaction.data.recordsFiltered}
        page={payload.Start + 1}
        isLoading={promoCodeTransaction.isLoading}
        useDownloadDropdown
      >
        {promoCodeTransaction.isSuccess &&
          promoCodeTransaction.data &&
          promoCodeTransaction.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='VisibilityOutlined' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table}`}
                  aria-labelledby={`button-${index}-${table}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detall
                  </MenuItem>
                </Menu>
                <Detail
                  id={table.pkId}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
              </TableCell>
              <TableCell>{table.user || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.redeemedStatus === 'Success' ? 'Success' : 'Failed'}
                  color={table.redeemedStatus == 'Success' ? 'success' : 'error'}
                />
              </TableCell>
              <TableCell>{table.promoCodeName || '-'}</TableCell>
              <TableCell>{table.promoCode || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDateWithTime(table.redeemedAt)}</TableCell>
              <TableCell>{GLOBAL.formatDateWithTime(table.createdAt)}</TableCell>
            </TableRow>
          ))}
      </Content>
      {promoCodeTransaction.isLoading && <Loading />}
      <Notification
        open={!promoCodeTransaction.isLoading && !promoCodeTransaction.isUninitialized && !promoCodeTransaction.isSuccess}
        onClose={() =>
          promoCodeTransaction.isError ? promoCodeTransaction.reset() : location.reload()
        }
        isError={Boolean(promoCodeTransaction.error) && promoCodeTransaction.isError}
        message={GLOBAL.returnExceptionMessage(
          promoCodeTransaction.isError,
          promoCodeTransaction.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter) || isEndDateGreaterThanStartDate()}
        onReset={() => {
          setRandomKey(randomKey + 1)
          setFilter({
            PromoName: '',
            StatusRedeemed: '',
            RedeemedAtFrom: '',
            RedeemedAtUntil: ''
          })
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            PromoName: '',
            StatusRedeemed: '',
            RedeemedAtFrom: '',
            RedeemedAtUntil: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          key={'promoName-' + randomKey}
          options={(promoCodeTransactionDropdownResult && promoCodeTransactionDropdownResult.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onChange={(_, promoName) => {
            setFilter({
              ...filter,
              PromoName: String((promoName && promoName.name) || '')
            })
          }}
          value={
            (promoCodeTransactionDropdownResult &&
              promoCodeTransactionDropdownResult.data &&
              promoCodeTransactionDropdownResult.data.find((e) => String(e.name) == filter.PromoName)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='promoName'
              label='Promo Name'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {promoCodeTransactionDropdownResult.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <FormControl fullWidth>
          <InputLabel id='select-label'>Redemption Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.StatusRedeemed}
            label='Redemption Status'
            onChange={(event) => setFilter({ ...filter, StatusRedeemed: event.target.value })}
          >
            <MenuItem value='Success'>Success</MenuItem>
            <MenuItem value='Failed'>Failed</MenuItem>
          </Select>
        </FormControl>
        <Typography><strong>Redeemed At</strong></Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex', marginTop: -15}}>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              // value={date.RedeemedAtFrom}
              value={filter.RedeemedAtFrom ? dayjs(filter.RedeemedAtFrom) : null}
              sx={{ width: "190px" }}
              onChange={(e) => {
                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                setFilter({ ...filter, RedeemedAtFrom: newDate })
                // setDate((prevState) => ({ ...prevState, minDateSignUp: e }))
              }}
              label='Min Date'
              format='MMM, DD YYYY'
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              // value={date.RedeemedAtUntil}
              value={filter.RedeemedAtUntil ? dayjs(filter.RedeemedAtUntil) : null}
              minDate={dayjs(filter.RedeemedAtFrom)}
              sx={{ width: "190px" }}
              onChange={(e) => {
                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                setFilter({ ...filter, RedeemedAtUntil: newDate })
                // setDate((prevState) => ({ ...prevState, maxDateSignUp: e }))
              }}
              onError={(error) => {
                if (error) {
                  setMessageUntil(filter.RedeemedAtUntil)
                }
              }}
              label='Max Date'
           format='MMM, DD YYYY'
            />
          </LocalizationProvider>
        </div>
        {messageUntil && dayjs(filter.RedeemedAtFrom).isAfter(dayjs(filter.RedeemedAtUntil)) && (
          <FormHelperText sx={{alignSelf: 'center', marginTop: -2}} error id='RedeemedAtUntil'>Max Date must be greater than Min Date</FormHelperText>
        )}
      </Filter>
    </>
  )
}

export type PartialPromoCodeTransactionProps = {
  Start: number
  Length: number
  Search: string
  PromoName: string
  StatusRedeemed: string
  RedeemedAtFrom: string
  RedeemedAtUntil: string
}

export default PromoRedemption
