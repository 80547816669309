import { isEmpty } from 'lodash'
import {
  mobileVendor,
  mobileModel,
  osName,
  osVersion,
  browserName,
  fullBrowserVersion
} from 'react-device-detect'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import moment from 'moment-timezone'
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from '@reduxjs/toolkit/query/react'
import type { BaseResponseProps, RefreshProps, UserProps } from 'modules/types'
import type { PartialRefreshProps } from 'modules/partial'
import CONSTANT from 'modules/constant'
import { getAppCheckToken } from 'modules/firebase'

const baseQuery = fetchBaseQuery({
  baseUrl: CONSTANT.URL_BASE,
  prepareHeaders: async (headers, { getState }) => {
    const user = getState() as { user: UserProps }

    if (user && user.user && user.user.token) {
      headers.set('Authorization', `Bearer ${user.user.token}`)
    }

    headers.set('X-Firebase-AppCheck', await getAppCheckToken())
  },
  validateStatus: (response, result: BaseResponseProps<unknown> | undefined) => {
    if (response.ok) {
      if (response.headers.get('Content-Type')?.includes('application/json')) {
        return !result?.error && Boolean(result?.data)
      }

      return true
    }

    return false
  }
})

const fetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (result.data) return { ...result, data: (result.data as BaseResponseProps<any>).data }

  if (result.error) {
    console.log('error: fetch', result.error)

    switch (result.error.status) {
      case 'FETCH_ERROR': {
        const message = 'Oops.. Network request failed'
        const error = { ...result, error: { ...result.error, message } }

        return error
      }
      case 401: {
        const storage = localStorage.getItem('persist:root')
        const parseStorage = storage && JSON.parse(storage)
        const userStorage = parseStorage as { user: string }
        const parseUser = JSON.parse(userStorage.user) as UserProps

        const body: PartialRefreshProps = {
          TokenKey: parseUser.token,
          SecurityStamp: parseUser.refreshToken
        }

        const refresh: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta> =
          await baseQuery(
            {
              url: CONSTANT.URL_REFRESH,
              method: 'POST',
              headers: headers(),
              body: body
            },
            api,
            extraOptions
          )

        if (refresh.error) {
          localStorage.clear()
          location.reload()
        }

        const data = refresh.data as RefreshProps

        const updateStorage = {
          ...parseStorage,
          user: {
            ...parseUser,
            token: data && data.token,
            validUntil: data && data.validUntil
          }
        }

        localStorage.setItem('persist:root', JSON.stringify(updateStorage))

        return await baseQuery(args, api, extraOptions)
      }
      default: {
        const message = 'Oops.. Something went wrong'
        const error = { ...result, error: { ...result.error, message } }

        return error
      }
    }
  }

  return result
}

const dispositionStringify = (content: string) => {
  if (isEmpty(content)) return ''
  if (!content.includes('filename')) return content
  const key = 'filename'
  const disposition = content
  const split = disposition && disposition.split(';')
  const filename = split && split.length > 0 && split[1]
  const name = filename && filename.slice(key.length + 2, filename.length)

  return name
}

export const download = async (response: Response) => {
  if (response.status !== 200) return response.json()

  response.blob().then((blob) => {
    const name = dispositionStringify(response.headers.get('Content-Disposition') || '')
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    link.href = url
    link.download = name || ''
    link.click()
  })
}

export const headers = (
  {
    useContentType = true,
    contentType = 'application/json'
  }: {
    useContentType?: boolean
    contentType?: string
  } = { useContentType: true, contentType: 'application/json' }
) => {
  const header = new Headers()

  header.append('Accept-Language', CONSTANT.DEFAULT_LANGUAGE)
  header.append('Access-Control-Allow-Origin', '*')
  header.append('X-AppVersion', 'WebApp')
  header.append('X-DeviceId', 'WebApp')
  header.append('X-DeviceManufacturer', mobileVendor)
  header.append('X-DeviceModel', mobileModel)
  header.append('X-OSName', osName)
  header.append('X-OSVersion', osVersion)
  header.append('X-TimeZone', moment.tz.guess())
  header.append('X-Notes', `Browser: ${browserName} v${fullBrowserVersion}`)

  if (useContentType) {
    header.append('Content-Type', contentType)
  }

  return header
}

export const headersOtp = (
  {
    useContentType = true,
    contentType = 'application/json',
    token
  }: {
    useContentType?: boolean
    contentType?: string
    token?: string
  } = { useContentType: true, contentType: 'application/json', token: '' }
) => {
  const header = new Headers()

  header.append('Accept-Language', CONSTANT.DEFAULT_LANGUAGE)
  header.append('X-AppVersion', 'WebApp')
  header.append('X-DeviceId', 'WebApp')
  header.append('X-DeviceManufacturer', mobileVendor)
  header.append('X-DeviceModel', mobileModel)
  header.append('X-OSName', osName)
  header.append('X-OSVersion', osVersion)
  header.append('X-TimeZone', moment.tz.guess())

  if (token) header.append('Authorization', `Bearer ${token}`)
  if (useContentType) header.append('Content-Type', contentType)

  return header
}

export const API = createApi({
  reducerPath: 'API',
  baseQuery: fetchBase,
  endpoints: (builder) => ({
    checkToken: builder.query<string, void>({
      query: () => ({
        url: CONSTANT.URL_VALID,
        method: 'POST',
        headers: headers()
      })
    })
  })
})

export const { useCheckTokenQuery } = API
