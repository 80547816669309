import CONSTANT from 'modules/constant'
import type {
    PaginationProps,
    SubscriptionExtensionLogDetailProps,
    SubscriptionExtensionLogListProps,
} from 'modules/types'
import { API, download, headers } from './api'

const SubscriptionExtensionLogAPI = API.injectEndpoints({
    endpoints: (builder) => ({
        geExtensionLogList: builder.mutation<PaginationProps<SubscriptionExtensionLogListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_SUBSCRIPTION_EXTENSION_LOG_LIST,
                method: 'POST',
                headers: headers({ useContentType: false }),
                body: body,
                formData: true
            })
        }),
        downloadSubscriptionExtensionLog: builder.mutation<PaginationProps<SubscriptionExtensionLogListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_SUBSCRIPTION_EXTENSION_LOG_DOWNLOAD,
                method: 'POST',
                headers: headers({ useContentType: false }),
                responseHandler: download,
                body: body,
                formData: true
            })
        }),
        getSubscriptionExtensionLogDetail: builder.query<SubscriptionExtensionLogDetailProps, number>({
            query: (id) => ({
                url: CONSTANT.URL_SUBSCRIPTION_EXTENSION_LOG_DETAIL + id,
                method: 'GET',
                headers: headers()
            })
        })
    })
})

export const {
    useGeExtensionLogListMutation,
    useDownloadSubscriptionExtensionLogMutation,
    useGetSubscriptionExtensionLogDetailQuery
} = SubscriptionExtensionLogAPI