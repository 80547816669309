import type { ButtonProps, ContainerProps, IconButtonProps } from '@mui/material'
import theme from 'theme'

class FilterStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2.5)
    }
  }

  static CloseButton: IconButtonProps = {
    sx: {
      position: 'absolute',
      top: theme.spacing(1.75),
      right: theme.spacing(1.5),
      color: 'black'
    }
  }

  static Button: ButtonProps = {
    sx: {
      fontSize: 16,
    }
  }
}

export default FilterStyle
