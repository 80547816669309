import CONSTANT from 'modules/constant'
import type {
  DropdownListProps,
  PaginationProps,
  PromoCodeTransactionDetailProps,
  PromoCodeTransactionDropdownProps,
  PromoTransactionListProps
} from 'modules/types'
import { API, download, headers } from './api'

const PromoCodeTransaction = API.injectEndpoints({
  endpoints: (builder) => ({
    getListPromoCodeTransaction: builder.mutation<
      PaginationProps<PromoTransactionListProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_TRANSACTION_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getDetailPromoCodeTransaction: builder.query<PromoCodeTransactionDetailProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_PROMO_CODE_TRANSACTION_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    getPromoCodeTransactionDropdown: builder.query<PromoCodeTransactionDropdownProps[], void>({
      query: () => ({
        url: CONSTANT.URL_PROMO_CODE_TRANSACTION_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadPromoCodetransaction: builder.mutation<
      PaginationProps<PromoTransactionListProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_TRANSACTION_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    getRedemptionStatus: builder.mutation<DropdownListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_PROMO_CODE_REDEMPTION_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useGetListPromoCodeTransactionMutation,
  useGetDetailPromoCodeTransactionQuery,
  useGetPromoCodeTransactionDropdownQuery,
  useDownloadPromoCodetransactionMutation,
  useGetRedemptionStatusMutation
} = PromoCodeTransaction
