import { isEmpty } from 'lodash'
import CONSTANT from 'modules/constant'
import type { NotificationCategoryProps, NotificationProps, PaginationProps } from 'modules/types'
import type { PartialNotificationListProps } from 'modules/partial'
import { API, headers } from './api'

const NotificationAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationList: builder.query<
      PaginationProps<NotificationProps>,
      PartialNotificationListProps
    >({
      query: (payload) => {
        const body = new FormData()

        body.append('Start', payload.Start.toString())
        body.append('Length', payload.Length.toString())

        if (!isEmpty(payload.Search)) body.append('Search', payload.Search)
        if (!isEmpty(payload.Category)) body.append('Category', payload.Category)
        if (!isEmpty(payload.FullName)) body.append('FullName', payload.FullName)
        if (!isEmpty(payload.Vin)) body.append('Vin', payload.Vin)
        if (!isEmpty(payload.MinNotifDate)) body.append('MinNotifDate', payload.MinNotifDate)
        if (!isEmpty(payload.MaxNotifDate)) body.append('MaxNotifDate', payload.MaxNotifDate)

        return {
          url: CONSTANT.URL_NOTIFICATION_LIST,
          method: 'POST',
          headers: headers({ useContentType: false }),
          body
        }
      }
    }),
    getNotificationDetail: builder.query<NotificationProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_NOTIFICATION_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    getNotificationCategoryList: builder.query<NotificationCategoryProps[], void>({
      query: () => ({
        url: CONSTANT.URL_NOTIFICATION_CATEGORY_LIST,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useLazyGetNotificationListQuery,
  useGetNotificationDetailQuery,
  useGetNotificationCategoryListQuery
} = NotificationAPI
