import { useFormik } from 'formik'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useCreateRolesListMutation, useGetRolesListDropdownsssMutation } from 'store/roles'
import type { PartialRolesCreateProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [getRolesListTypesDropdown, rolesTypeDropdown] = useGetRolesListDropdownsssMutation()
  const [createRolesList, create] = useCreateRolesListMutation()

  const onSubmit = (values: PartialRolesCreateProps) => createRolesList(values)

  const scheme = yup.object<PartialRolesCreateProps>({
    Code: yup.string().required('Code is required'),
    Name: yup.string().required('Name is required'),
    Description: yup.string().required('Description is required'),
  })

  const formik = useFormik<PartialRolesCreateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      Code: '',
      Name: '',
      Description: ''
    },
    onSubmit: onSubmit
  })

  const disabled = !formik.isValid

  console.log('create.error', create.error);
  

  return (
    <>
      <Dialog
        title='Create New Role'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={disabled}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            id='Name'
            variant='outlined'
            label='Name'
            value={formik.values.Name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.Name && Boolean(formik.errors.Name)}
            helperText={
              formik.touched && formik.touched.Name && formik.errors && formik.errors.Name
            }
            fullWidth
          />
          <TextField
            id='Description'
            variant='outlined'
            label='Description'
            value={formik.values.Description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched && formik.touched.Description && Boolean(formik.errors.Description)
            }
            helperText={
              formik.touched &&
              formik.touched.Description &&
              formik.errors &&
              formik.errors.Description
            }
            fullWidth
          />
          <Autocomplete
            options={rolesTypeDropdown.data || []}
            getOptionLabel={(list) => list.code}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.pkId == value.pkId : false
            }
            onOpen={() => getRolesListTypesDropdown()}
            onChange={(_, code) => formik.setFieldValue('Code', code && code.code)}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.pkId}>
                {item.code}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='roleId'
                label='Select A Role'
                error={
                  formik.touched && formik.touched.Code && Boolean(formik.errors.Code)
                }
                helperText={
                  formik.touched &&
                  formik.touched.Code &&
                  formik.errors &&
                  formik.errors.Code
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {rolesTypeDropdown.isLoading && (
                        <CircularProgress color='inherit' size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
