import { createTheme } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

const theme = createTheme({
  palette: {
    background: { default: '#f4f8fe' },
    primary: { main: '#002c5f' },
    success: { main: '#28a138' },
    warning: { main: '#f49928' },
    error: { main: '#e63312' }
  },
  typography: {
    fontFamily: [
      'Hyundai Sans Text Office',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    h1: { fontFamily: 'Hyundai Sans Head Office' },
    h2: { fontFamily: 'Hyundai Sans Head Office' },
    h3: { fontFamily: 'Hyundai Sans Head Office' },
    h4: { fontFamily: 'Hyundai Sans Head Office' },
    h5: { fontFamily: 'Hyundai Sans Head Office' },
    h6: { fontFamily: 'Hyundai Sans Head Office' },
    button: {
      fontFamily: 'Hyundai Sans Head Office',
      textTransform: 'none'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ExpandMore />
      }
    }
  }
})

export default theme
