import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material'
import Filter from 'components/filter/filter'
import Content from 'components/content/content'
import type { ErrorProps } from 'modules/types'
import Icon from 'components/icon'
// import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
// import {
//   useDeletePromoCodeMutation
// } from 'store/promoCode'
import { useDownloadCopyWritingMutation, useGetCategoryDropdownMutation, useGetListCopywritingMutation } from 'store/copyright'
// import type { PartialSubsPlan } from 'modules/partial'
import CONSTANT from 'modules/constant'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
// import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'

const CopywritingList = () => {
  const [getListCopywriting, copywriting] = useGetListCopywritingMutation()
  const [getCategoryDropdown, categoryDropdown] = useGetCategoryDropdownMutation();
  const [downloadPromoCode, download] = useDownloadCopyWritingMutation()
  // const [deletePromoCode, deletePromo] = useDeletePromoCodeMutation()

  const [filter, setFilter] = useState({
    Category: ''
  })
  const [payload, setPayload] = useState<PartialSubsPlan>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    Category: '',
    RewardType: '',
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidTo: '',
    MaxValidTo: '',
    MinCreatedAt: '',
    MaxCreatedAt: '',
    Status: ''
  })
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  // const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [randomNumber, setRandomNumber] = useState<number>(0)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })
  const onResync = () => {
    const formData = new FormData()
    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Category)) formData.append('Category', payload.Category)
    if (!isEmpty(payload.RewardType)) formData.append('RewardType', payload.RewardType)
    if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
    if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
    if (!isEmpty(payload.MinValidTo)) formData.append('MinValidTo', payload.MinValidTo)
    if (!isEmpty(payload.MaxValidTo)) formData.append('MaxValidTo', payload.MaxValidTo)
    if (!isEmpty(payload.MinCreatedAt)) formData.append('MinCreatedAt', payload.MinCreatedAt)
    if (!isEmpty(payload.MaxCreatedAt)) formData.append('MaxCreatedAt', payload.MaxCreatedAt)

    getListCopywriting(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()
    formData.append('Start', String(0))
    formData.append('Length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Category)) formData.append('Category', payload.Category)
    if (!isEmpty(payload.RewardType)) formData.append('RewardType', payload.RewardType)
    if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
    if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
    if (!isEmpty(payload.MinValidTo)) formData.append('MinValidTo', payload.MinValidTo)
    if (!isEmpty(payload.MaxValidTo)) formData.append('MaxValidTo', payload.MaxValidTo)
    if (!isEmpty(payload.MinCreatedAt)) formData.append('MinCreatedAt', payload.MinCreatedAt)
    if (!isEmpty(payload.MaxCreatedAt)) formData.append('MaxCreatedAt', payload.MaxCreatedAt)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadPromoCode(formData)
  }
  // const onCreate = () => setDialogCreate(true)

  useEffect(() => {
    onResync()
  }, [payload])

  const tableHeadTitles = [
    'Copywriting Code',
    'Copywriting Name',
    'Category'
  ]
  const numbers = GLOBAL.tableNumber(payload.Start)
  const openAnchor = Boolean(anchor)
  const totalPage = copywriting.data && GLOBAL.tableTotalPage(copywriting.data.recordsFiltered)

  return (
    <>
      <Content
        title='Copywiriting List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        useDownloadDropdown
        // onAdditional={onCreate}
        // additionalTitle='New Copywriting'
        // additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={copywriting.data && copywriting.data.recordsFiltered}
        page={payload.Start + 1}
        isLoading={copywriting.isLoading}
      >
        {copywriting.isSuccess &&
          copywriting.data &&
          copywriting.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.pkId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.pkId}`}
                  aria-labelledby={`button-${index}-${table.pkId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>

                  {/* <MenuModalItem
                    title={table.code}
                    onClick={() => onRemoveAnchor(() => deletePromoCode(table.pkId))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem> */}
                </Menu>
                <Detail
                  id={table.pkId}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                {dialogUpdate && menu == index && (
                  <Edit
                    id={table.pkId}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{table.code || '-'}</TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>{table.category || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>
      {/* {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />} */}
      {copywriting.isLoading && <Loading />}
      <Notification
        open={!copywriting.isLoading && !copywriting.isUninitialized && !copywriting.isSuccess}
        onClose={() => (copywriting.isError ? copywriting.reset() : location.reload())}
        isError={Boolean(copywriting.error) && copywriting.isError}
        message={GLOBAL.returnExceptionMessage(copywriting.isError, copywriting.error as ErrorProps)}
      />
      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomNumber(randomNumber + 1)
          setFilter({
            Category: ''
          })
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            Category: '',
            RewardType: '',
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidTo: '',
            MaxValidTo: '',
            MinCreatedAt: '',
            MaxCreatedAt: '',
            Status: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(categoryDropdown && categoryDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getCategoryDropdown()}
          onChange={(_, partner) =>
            setFilter({
              ...filter,
              Category: (partner && String(partner.name)) || ''
            })
          }
          value={
            (categoryDropdown &&
              categoryDropdown.data &&
              categoryDropdown.data.find((e) => String(e.name) == filter.Category)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.name}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='Category'
              label='Category'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {categoryDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>
    </>
  )
}

export type PartialSubsPlan = {
  Start: number
  Length: number
  Search: string
  Category: string
  RewardType: string
  MinValidFrom: string
  MaxValidFrom: string
  MinValidTo: string
  MaxValidTo: string
  MinCreatedAt: string
  MaxCreatedAt: string
  Status: string
}

export default CopywritingList
