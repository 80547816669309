import { useState } from 'react'
import { useSelector } from 'react-redux'
import { KeyboardArrowDown, NotificationsNone, Settings } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import GLOBAL from 'modules/global'
import { useLogoutUserMutation } from 'store/user'
import type { UserProps } from 'modules/types'
import Dialog from 'components/dialog/dialog'
import HeaderStyle from './style'
import ChangePassword from './change_password/change_password'

const Header = () => {
  const navigate = useNavigate()
  const user = useSelector((state: { user: UserProps }) => state.user.user)

  const [logoutUser, logout] = useLogoutUserMutation()
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogLogout, setDialogLogout] = useState<boolean>(false)
  const [dialogSetting, setDialogSetting] = useState<boolean>(false)

  const openAnchor = Boolean(anchor)
  const role = user && (user.roleName || user.email)

  const onAction = (event: React.MouseEvent<HTMLElement>) => setAnchor(event.currentTarget)
  const onLogout = () => {
    setAnchor(null)
    setDialogLogout(true)
  }

  const handleLogout = () =>
    logoutUser({}).then(() => GLOBAL.logout(() => (location.href = '/login')))

  return (
    <>
      <Container {...HeaderStyle.Container}>
        <Link to='/' style={{ height: '100%' }}>
          <img src='/logo-bluelink.png' alt='Logo' {...HeaderStyle.Logo} />
        </Link>
        {/* <FilledInput
          id='search-global'
          size='small'
          placeholder='Search'
          startAdornment={
            <InputAdornment position='start'>
              <SearchRounded />
            </InputAdornment>
          }
          {...HeaderStyle.Search}
        /> */}
        <Box {...HeaderStyle.User}>
          <IconButton onClick={() => navigate('/notification/notification-list')}>
            <NotificationsNone />
          </IconButton>
          <IconButton onClick={() => setDialogSetting(true)}>
            <Settings />
          </IconButton>
          <Box {...HeaderStyle.Avatar}>
            <Avatar alt='Bluelink User' sx={{ backgroundColor: 'primary.main' }} />
            <Box {...HeaderStyle.profile}>
              <Typography {...HeaderStyle.Name}>{user.fullname}</Typography>
              <Typography {...HeaderStyle.Role}>{role}</Typography>
            </Box>
          </Box>
          <Box>
            <IconButton
              id='button-trigger'
              aria-haspopup='true'
              aria-controls={openAnchor ? 'button-account' : undefined}
              aria-expanded={openAnchor ? 'true' : undefined}
              onClick={onAction}
            >
              <KeyboardArrowDown />
            </IconButton>
            <Menu
              id='button-account'
              aria-labelledby='button-trigger'
              anchorEl={anchor}
              open={openAnchor}
              onClose={() => setAnchor(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Container>

      <Dialog
        title='Logout'
        open={dialogLogout}
        onCancel={() => setDialogLogout(false)}
        onSubmit={handleLogout}
        loading={logout.isLoading}
        submitText='Yes'
      >
        <Typography>Are you sure want to Logout?</Typography>
      </Dialog>

      {dialogSetting && (
        <ChangePassword open={dialogSetting} onClose={() => setDialogSetting(false)} />
      )}
    </>
  )
}

export default Header
