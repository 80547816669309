import type { LinkProps } from 'react-router-dom'
import type { GridProps, TypographyProps } from '@mui/material'

class BreadcrumbStyle {
  static Container: GridProps = {
    bgcolor: 'white',
    alignItems: 'center',
    sx: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'divider'
    }
  }

  static Title: TypographyProps = {
    variant: 'h4',
    fontWeight: 500
  }

  static Link: LinkProps = {
    to: '',
    style: {
      color: 'inherit',
      textDecoration: 'none'
    }
  }
}

export default BreadcrumbStyle
