import { useEffect, useState } from 'react'
import type { ChangeEvent } from 'react';
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
    Autocomplete,
    Chip,
    Container,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material'
import { AddOutlined, DeleteOutlineOutlined } from '@mui/icons-material'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import { useGetDetailSubsContentQuery, useUpdateSubsContentMutation } from 'store/subsContent'
import type { PartialSubsContentEditProps } from 'modules/partial'
import { useGetApplicableSubsPlanDropdownMutation } from 'store/dropdown'
import type { ApplicableSubsPlanDropDown, DetailStateProps, ErrorProps } from 'modules/types'
import DialogStyle from './style'


const Edit = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
    if (!open) return <></>

    const { data, isFetching, isLoading } = useGetDetailSubsContentQuery(id)
    const [getApplicableSubsPlanDropdown, applicableSubsPlanDropdown] = useGetApplicableSubsPlanDropdownMutation();
    const [applicableSubsPlan, setApplicableSubsPlan] = useState<ApplicableSubsPlanDropDown[]>([])
    const [inputFieldsContent, setInputFieldsContent] = useState([{ ContentEN: '', ContentID: '' }]);
    const [updateSubsContent, update] = useUpdateSubsContentMutation()

    const scheme = yup.object<PartialSubsContentEditProps>({
        ContentName: yup.string().required('Content Name is required'),
        Subtitle: yup.string().required('Subtitle is required'),
        SubscriptionPlan: yup.array().of(yup.object().shape({
            PlanPkId: yup.number().required('PlanPkId is required'),
            SubscriptionPlanName: yup.string().required('Subscription Plan Name is required'),
        })).required('SubscriptionPlan is required'),
        // ContentEN: yup.array().of(yup.string().required('Content English is required')).required(),
        // ContentID: yup.array().of(yup.string().required('Content Bahasa is required')).required()
        Content: yup.array().of(
            yup.object().shape({
                ContentEN: yup.string().required('Content English is required'),
                ContentID: yup.string().required('Content Bahasa is required'),
            })
        ).required('Content is required')
    });

    const formik = useFormik<PartialSubsContentEditProps>({
        validationSchema: scheme,
        enableReinitialize: true,
        validateOnMount: true,
        initialValues: {
            PkId: (data && data.pkId) || 0,
            ContentName: (data && data.contentName) || '',
            Subtitle: (data && data.subtitle) || '',
            SubscriptionPlan: (data && data.subscriptionPlan) ? data.subscriptionPlan.map(item => ({
                PlanPkId: item.planPkId || 0,
                SubscriptionPlanName: item.subscriptionPlanName || ''
            })) : [{ PlanPkId: 0, SubscriptionPlanName: '' }],
            // Content: [{
            //     ContentEN: (data && data.content && data.content[0] && data.content[0].contentEN) || '',
            //     ContentID: (data && data.content && data.content[1] && data.content[0].contentID) || ''
            // }]
            Content: (data && data.content) ? data.content.map(item => ({
                ContentEN: item.contentEN || '',
                ContentID: item.contentID || ''
            })) : [{ ContentEN: '', ContentID: '' }]
        },
        onSubmit: (values: PartialSubsContentEditProps) => handleSubmit(values)
    })

    console.log(formik);

    const handleSubmit = (e: PartialSubsContentEditProps) => {
        console.log('Formik Values Before Submit:', formik.values);
        const Content = inputFieldsContent.map(field => ({
            ContentEN: field.ContentEN,
            ContentID: field.ContentID,
        }));

        const formatPayload = {
            PkId: e.PkId,
            ContentName: e.ContentName,
            Subtitle: e.Subtitle,
            SubscriptionPlan: e.SubscriptionPlan,
            Content: Content,
        };

        updateSubsContent(formatPayload);
        console.log('payloaddd', formatPayload);
    };

    const errorData = formik.errors

    const handleChangeInputEnglish = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        // const { value } = e.target;
        // const list = [...inputFieldsContent];
        // list[index].ContentEN = value;
        // formik.setFieldValue('ContentEN', list.map(field => field.ContentEN));
        // setInputFieldsContent(list);
        const { value } = e.target;
        const list = [...inputFieldsContent];
        list[index].ContentEN = value;
        setInputFieldsContent(list);

        // Update Formik's Content array
        formik.setFieldValue('Content', list);
    };
    const handleChangeInputBahasa = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        // const { value } = e.target;
        // const list = [...inputFieldsContent];
        // list[index].ContentID = value;
        // formik.setFieldValue('ContentID', list.map(field => field.ContentID));
        // setInputFieldsContent(list);
        const { value } = e.target;
        const list = [...inputFieldsContent];
        list[index].ContentID = value;
        setInputFieldsContent(list);

        // Update Formik's Content array
        formik.setFieldValue('Content', list);
    };
    const handleAddFieldsEnglish = () => {
        if (inputFieldsContent.length < 10) { // Batasi penambahan field hingga 10
            setInputFieldsContent([...inputFieldsContent, { ContentEN: '', ContentID: '' }]); // Add a new input field
        }
    };
    const handleRemoveFieldsEnglish = (index: number) => {
        const values = [...inputFieldsContent];
        values.splice(index, 1); // Remove the specific input field
        setInputFieldsContent(values);
    };
    const handleChange = (value: ApplicableSubsPlanDropDown[]) => {
        const formattedPlans = value.map(plan => ({
            PlanPkId: plan.id,
            SubscriptionPlanName: plan.name
        }));
        formik.setFieldValue('SubscriptionPlan', formattedPlans);
    };

    useEffect(() => {
        if (data) {
            getApplicableSubsPlanDropdown()
            const defaultCarModel =
                (data &&
                    data.subscriptionPlan &&
                    data.subscriptionPlan.map((item) => ({
                        id: item.planPkId,
                        name: item.subscriptionPlanName
                    }))) ||
                []
            setApplicableSubsPlan(defaultCarModel)

            const defaultContent =
                (data &&
                    data.content &&
                    data.content.map((item) => ({
                        ContentEN: item.contentEN,
                        ContentID: item.contentID
                    }))) ||
                []
            setInputFieldsContent(defaultContent)
        }
    }, [data])

    // const disableButton = !formik.isValid
    const disableButton = !formik.isValid ||
        !formik.values.ContentName ||
        !formik.values.Subtitle ||
        !formik.values.SubscriptionPlan.length ||
        inputFieldsContent.some(field => !field.ContentEN || !field.ContentID);
    console.log('disableButton', disableButton);
    console.log('formikError', formik.errors)
    console.log('formikTouched', formik.touched)

    return (
        <>
            <Dialog
                title='Edit Subscription Content'
                open={open}
                onCancel={onClose}
                onSubmit={() => formik.handleSubmit()}
                loading={update.isLoading}
                isDisabled={disableButton}
                submitText={'Submit'}
            >
                <Container {...DialogStyle.Container}>
                    {(isLoading || isFetching) && !data && <Loading />}
                    {data && (
                        <>
                            <TextField
                                id='ContentName'
                                variant='outlined'
                                label='Content Name'
                                value={formik.values.ContentName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.ContentName && Boolean(errorData.ContentName)}
                                helperText={formik.touched.ContentName && errorData.ContentName}
                                fullWidth
                            />
                            <TextField
                                id='Subtitle'
                                variant='outlined'
                                label='Subtitle'
                                value={formik.values.Subtitle}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.Subtitle && Boolean(errorData.Subtitle)}
                                helperText={formik.touched.Subtitle && errorData.Subtitle}
                                fullWidth
                            />
                            <Autocomplete
                                fullWidth
                                sx={{ marginBottom: '10px' }}
                                multiple
                                id='fixed-tags-demo'
                                value={applicableSubsPlan}
                                onOpen={() => getApplicableSubsPlanDropdown()}
                                isOptionEqualToValue={(option, value) =>
                                    option && value ? option.id === value.id : false // Compare by id instead of name
                                }
                                onChange={(event, newValue) => {
                                    setApplicableSubsPlan(newValue);
                                    handleChange(newValue);
                                }}
                                options={(applicableSubsPlanDropdown && applicableSubsPlanDropdown.data) || []}
                                getOptionLabel={(option) => option.name}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.name} {...getTagProps({ index })}
                                            key={index}
                                        />
                                    ))
                                }
                                renderInput={(params) => <TextField {...params} label='Applicable Subscription Plan' />}
                            />
                            <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: -10 }}>
                                <Typography {...DialogStyle.Title} style={{ width: '90%' }}>Content</Typography>
                                <IconButton onClick={handleAddFieldsEnglish} style={{ width: '10%' }} disabled={inputFieldsContent.length >= 10}>
                                    <AddOutlined style={{ width: 24, height: 24, color: inputFieldsContent.length >= 10 ? 'gray' : '#002C5F', marginRight: 2 }} />
                                    <Typography style={{ color: inputFieldsContent.length >= 10 ? 'gray' : '#002C5F', marginTop: 6 }}>Add</Typography>
                                </IconButton>
                            </Grid>
                            {inputFieldsContent.slice(0, 10).map((input, index) => (
                                <Grid key={index} item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography  {...DialogStyle.Title} style={{ marginRight: 6 }}>{index + 1}</Typography>
                                    <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <TextField
                                            variant='outlined'
                                            label={`Content English #${index + 1}`}
                                            name={`ContentEnglish-${index}`}
                                            value={input.ContentEN}
                                            onChange={(e) => {
                                                handleChangeInputEnglish(e, index),
                                                    console.log('input', input);
                                            }}
                                        />
                                        <TextField
                                            variant='outlined'
                                            label={`Content Bahasa #${index + 1}`}
                                            name={`ContentBahasa-${index}`}
                                            value={input.ContentID}
                                            onChange={(e) => {
                                                handleChangeInputBahasa(e, index),
                                                    console.log('input', input);
                                            }}
                                            style={{ marginTop: 10 }}
                                        />
                                    </Grid>
                                    <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                        {inputFieldsContent.length > 1 && ( // Kondisi untuk menampilkan RemoveOutlined
                                            <IconButton onClick={() => handleRemoveFieldsEnglish(index)}>
                                                <DeleteOutlineOutlined style={{ width: 24, height: 24, color: '#002C5F' }} />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                        </>
                    )}
                </Container>
            </Dialog>
            <Notification
                open={!update.isLoading && !update.isUninitialized}
                onClose={() => (update.isError ? update.reset() : location.reload())}
                isError={Boolean(update.error) && update.isError}
                message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
            />
        </>
    )
}

export default Edit