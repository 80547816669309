import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled
} from '@mui/material'
import type { DialogProps } from '@mui/material'
import { Close } from '@mui/icons-material'
import type { Children } from 'modules/types'
import ModalStyle from './style'

type FilterProps = DialogProps &
  Children & {
    title?: string
    isSubmitDisabled?: boolean
    onCancel?: () => void
    onReset?: () => void
    onSubmit?: () => void
  }

const FilterDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    padding: [theme.spacing(2), theme.spacing(2.5)].join(' ')
  },
  '& .MuiDialogContent-root': {
    padding: [theme.spacing(1), theme.spacing(2.5)].join(' ')
  },
  '& .MuiDialogActions-root': {
    padding: [theme.spacing(1.5), theme.spacing(2.5), theme.spacing(2.5)].join(' '),
    gap: theme.spacing(1)
  }
}))

const Filter = ({ isSubmitDisabled = false, ...props }: FilterProps) => (
  <FilterDialog {...props} fullWidth maxWidth='xs'>
    <DialogTitle>{props.title}</DialogTitle>
    <IconButton {...ModalStyle.CloseButton} aria-label='close' onClick={props.onCancel}>
      <Close fontSize='small' />
    </IconButton>
    <DialogContent>
      <Container {...ModalStyle.Container}>{props.children}</Container>
    </DialogContent>
    <DialogActions>
      <Button {...ModalStyle.Button} variant='outlined' onClick={props.onReset}>
        Reset
      </Button>
      <Button
        {...ModalStyle.Button}
        variant='contained'
        disabled={isSubmitDisabled}
        onClick={props.onSubmit}
      >
        Apply
      </Button>
    </DialogActions>
  </FilterDialog>
)

export default Filter
