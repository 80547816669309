import CONSTANT from 'modules/constant'
import type { PaginationProps, PromoCodeDetailProps, PromoCodeGeneralDetailProps, PromoCodeGeneralListProps, PromoCodeListProps, ResultProps } from 'modules/types'
import type { PartialDIsableEnableProps, PartialPromoCodeGeneralCreateProps, PartialPromoCodeGeneralEditTempProps, PartialPromoCodeProps, PartialUpdatePromoCodeProps } from 'modules/partial'
import { API, download, headers } from './api'

const PromoCodeAPI = API.injectEndpoints({
    endpoints: (builder) => ({
        getListPromoCodeGeneral: builder.mutation<PaginationProps<PromoCodeGeneralListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_PROMO_CODE_GENERAL_LIST,
                method: 'POST',
                headers: headers({ useContentType: false }),
                body: body,
                formData: true
            })
        }),
        downloadPromoCodeGeneral: builder.mutation<PaginationProps<PromoCodeGeneralListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_PROMO_CODE_GENERAL_DOWNLOAD,
                method: 'POST',
                headers: headers({ useContentType: false }),
                responseHandler: download,
                body: body,
                formData: true
            })
        }),
        getDetailPromoCodeGeneral: builder.query<PromoCodeGeneralDetailProps, number>({
            query: (id) => ({
              url: CONSTANT.URL_PROMO_CODE_GENERAL_DETAIL + id,
              method: 'GET',
              headers: headers()
            })
          }),
          createPromoCodeGeneral: builder.mutation<ResultProps, PartialPromoCodeGeneralCreateProps>({
            query: (body) => ({
              url: CONSTANT.URL_PROMO_CODE_GENERAL_CREATE,
              method: 'POST',
              headers: headers(),
              body: body
            })
          }),
          updateStatusPromoCodeGeneral: builder.mutation<ResultProps, PartialDIsableEnableProps>({
            query: (body) => ({
              url: CONSTANT.URL_PROMO_CODE_GENERAL_UPDATE_STATUS,
              method: 'PUT',
              headers: headers(),
              body: body
            })
          }),
          updatePromoCodeGeneral: builder.mutation<ResultProps, PartialPromoCodeGeneralEditTempProps>({
            query: (body) => ({
              url: CONSTANT.URL_PROMO_CODE_GENERAL_EDIT,
              method: 'PUT',
              headers: headers(),
              body: body
            })
          }),



        downloadPromoCode: builder.mutation<PaginationProps<PromoCodeListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_PROMO_CODE_DOWNLOAD,
                method: 'POST',
                headers: headers({ useContentType: false }),
                responseHandler: download,
                body: body,
                formData: true
            })
        }),
        deletePromoCode: builder.mutation<ResultProps, number>({
            query: (id) => ({
                url: CONSTANT.URL_PROMO_CODE_DELETE + id,
                method: 'DELETE',
                headers: headers()
            })
        }),
        createPromoCode: builder.mutation<ResultProps, PartialPromoCodeProps>({
            query: (body) => ({
                url: CONSTANT.URL_PROMO_CODE_CREATE,
                method: 'POST',
                headers: headers(),
                body: body
            })
        }),
        getDetailPromoCode: builder.query<PromoCodeDetailProps, number>({
            query: (id) => ({
                url: CONSTANT.URL_PROMO_CODE_DETAIL + id,
                method: 'GET',
                headers: headers()
            })
        }),
        updatePromoCode: builder.mutation<ResultProps, { body: PartialUpdatePromoCodeProps, id: number }>({
            query: (param) => ({
                url: CONSTANT.URL_PROMO_CODE_UPDATE + param.id,
                method: 'PUT',
                headers: headers(),
                body: param.body,
            })
        }),
    })
})

export const {
    useGetListPromoCodeGeneralMutation,
    useDownloadPromoCodeGeneralMutation,
    useGetDetailPromoCodeGeneralQuery,
    useCreatePromoCodeGeneralMutation,
    useUpdateStatusPromoCodeGeneralMutation,
    useUpdatePromoCodeGeneralMutation,


    useDownloadPromoCodeMutation,
    useDeletePromoCodeMutation,
    useCreatePromoCodeMutation,
    useUpdatePromoCodeMutation,

    useGetDetailPromoCodeQuery
} = PromoCodeAPI
