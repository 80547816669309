import {
  Container,
  Grid,
  Typography
} from '@mui/material'
import moment from 'moment'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetSubscriptionExtensionLogDetailQuery } from 'store/subscriptionExtensionLog'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetSubscriptionExtensionLogDetailQuery(id)

  const loading = !data && (isLoading || isFetching)

  return (
    <>
      <Drawer open={open} title='Subscription Extension Logs Detail' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <Grid container spacing={2} style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 15
              }}>
                <Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Registered Owner</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>{data?.registeredOwner ? data?.registeredOwner : ''}</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>VIN</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>{data?.vin ? data?.vin : '-'}</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Previous Exp Date</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>{moment(data?.proviousExpDate ? data?.proviousExpDate : '-').format('MMM DD, YYYY HH:mm:ss')}</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>User ID</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>{data?.userId ? data?.userId : '-'}</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Adjusted Exp Date</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>{moment(data?.adjustedExpDate ? data?.adjustedExpDate : '-').format('MMM DD, YYYY HH:mm:ss')}</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Created Date</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>{moment(data?.createdDate ? data?.createdDate : '-').format('MMM DD, YYYY HH:mm:ss')}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
