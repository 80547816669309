import { useState } from 'react';
import type { ChangeEvent } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Autocomplete, Chip, Container, Grid, IconButton, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AddOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import Dialog from 'components/dialog/dialog';
import Notification from 'components/notification';
import GLOBAL from 'modules/global';
// import type { PartialSubsContentCreateProps } from 'modules/partial';
import { useCreateSubsContentMutation } from 'store/subsContent'
import { useGetApplicableSubsPlanDropdownMutation } from 'store/dropdown';
import type { ApplicableSubsPlanDropDown, DetailStateProps, ErrorProps } from 'modules/types';
import DialogStyle from './style';
;

const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>;

  const [createSubsContent, create] = useCreateSubsContentMutation();
  const [getApplicableSubsPlanDropdown, applicableSubsPlanDropdown] = useGetApplicableSubsPlanDropdownMutation();
  const [applicableSubsPlan, setApplicableSubsPlan] = useState<ApplicableSubsPlanDropDown[]>([])
  const [inputFieldsContent, setInputFieldsContent] = useState([{ ContentEnglish: '', ContentBahasa: '' }]);

  const scheme = yup.object<PartialSubsContentCreateProps>({
    ContentName: yup.string().required('Content Name is required'),
    Subtitle: yup.string().required('Subtitle is required'),
    SubscriptionPlan: yup.array().of(yup.object().shape({
      PlanPkId: yup.number().required(),
      SubscriptionPlanName: yup.string().required(),
    })).required('SubscriptionPlan is required'),
    // ContentEnglish: yup.string().required('Content English is required'),
    // ContentBahasa: yup.string().required('Content Bahasa is required')
    ContentEnglish: yup.array().of(yup.string().required('Content English is required')).required(),
    ContentBahasa: yup.array().of(yup.string().required('Content Bahasa is required')).required()
  });

  const formik = useFormik<PartialSubsContentCreateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      ContentName: '',
      Subtitle: '',
      SubscriptionPlan: [],
      Content: []
    },
    onSubmit: (values: PartialSubsContentCreateProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialSubsContentCreateProps) => {
    // Create the Content array by mapping over inputFieldsContent
    const Content = inputFieldsContent.map(field => ({
      ContentEN: field.ContentEnglish,
      ContentID: field.ContentBahasa,
    }));

    // Construct the payload in the desired format
    const formatPayload = {
      ContentName: e.ContentName,
      Subtitle: e.Subtitle,
      SubscriptionPlan: e.SubscriptionPlan, // Use SubscriptionPlan directly from formik values
      Content: Content, // Use the new Content array
    };

    // Call the mutation with the formatted payload
    createSubsContent(formatPayload);
    console.log('payloaddd', formatPayload);
  };
  const errorData = formik.errors

  const handleChangeInputEnglish = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { value } = e.target;
    const list = [...inputFieldsContent];
    list[index].ContentEnglish = value;
    formik.setFieldValue('ContentEnglish', list.map(field => field.ContentEnglish));
    setInputFieldsContent(list);
  };
  const handleChangeInputBahasa = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { value } = e.target;
    const list = [...inputFieldsContent];
    list[index].ContentBahasa = value;
    formik.setFieldValue('ContentBahasa', list.map(field => field.ContentBahasa));
    setInputFieldsContent(list);
  };

  const handleAddFieldsEnglish = () => {
    if (inputFieldsContent.length < 10) { // Batasi penambahan field hingga 10
      setInputFieldsContent([...inputFieldsContent, { ContentEnglish: '', ContentBahasa: '' }]); // Add a new input field
    }
  };
  const handleRemoveFieldsEnglish = (index: number) => {
    const values = [...inputFieldsContent];
    values.splice(index, 1); // Remove the specific input field
    setInputFieldsContent(values);
  };

  const handleChange = (value: ApplicableSubsPlanDropDown[]) => {
    const formattedPlans = value.map(plan => ({
      PlanPkId: plan.id, // Make sure these properties exist in your dropdown data
      SubscriptionPlanName: plan.name
    }));
    formik.setFieldValue('SubscriptionPlan', formattedPlans);
  };

  console.log('isi inputFieldsContent =>', inputFieldsContent);
  // console.log('isi inputFieldsBAHASA =>', inputFieldsBahasa);
  // console.log('isi formik =>', formik.values.Code);
  // console.log('values => ', formik.initialValues);

  const disableButton = !formik.isValid
  console.log('disableButton', disableButton);

  return (
    <>
      <Dialog
        title='Create New Subscription Content'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={disableButton}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            {...DialogStyle.textField}
            id='ContentName'
            variant='outlined'
            label='Subscription Content Name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.ContentName} // Menggunakan value dari formik
            // value={''}
            error={formik.touched.ContentName && Boolean(errorData.ContentName)}
            helperText={formik.touched.ContentName && errorData.ContentName}
            fullWidth
          />
          <TextField
            {...DialogStyle.textField}
            id='Subtitle'
            variant='outlined'
            label='Subtitle'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Subtitle} // Menggunakan value dari formik
            error={formik.touched.Subtitle && Boolean(errorData.Subtitle)}
            helperText={formik.touched.Subtitle && errorData.Subtitle}
            fullWidth
          />
          <Autocomplete
            fullWidth
            sx={{ marginBottom: '10px' }}
            multiple
            id='fixed-tags-demo'
            value={applicableSubsPlan}
            onOpen={() => getApplicableSubsPlanDropdown()}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.name === value.name : false
            }
            onChange={(event, newValue) => {
              setApplicableSubsPlan(newValue)
              handleChange(newValue);
            }}
            options={(applicableSubsPlanDropdown && applicableSubsPlanDropdown.data) || []}
            getOptionLabel={(option) => option.name}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip label={option.name} {...getTagProps({ index })} key={index} />
              ))
            }
            renderInput={(params) => <TextField {...params} label='Applicable Subscription Plan' />}
          />
          <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: -10 }}>
            <Typography {...DialogStyle.Title} style={{ width: '90%' }}>Content</Typography>
            <IconButton onClick={handleAddFieldsEnglish} style={{ width: '10%' }} disabled={inputFieldsContent.length >= 10}>
              <AddOutlined style={{ width: 24, height: 24, color: inputFieldsContent.length >= 10 ? 'gray' : '#002C5F', marginRight: 2 }} />
              <Typography style={{ color: inputFieldsContent.length >= 10 ? 'gray' : '#002C5F', marginTop: 6 }}>Add</Typography>
            </IconButton>
          </Grid>
          {inputFieldsContent.slice(0, 10).map((input, index) => (
            <Grid key={index} item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography  {...DialogStyle.Title} style={{ marginRight: 6 }}>{index + 1}</Typography>
              <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <TextField
                  variant='outlined'
                  label={`Content English #${index + 1}`}
                  name={`ContentEnglish-${index}`}
                  value={input.ContentEnglish}
                  onChange={(e) => {
                    handleChangeInputEnglish(e, index),
                      console.log('input', input);
                  }}
                />
                <TextField
                  variant='outlined'
                  label={`Content Bahasa #${index + 1}`}
                  name={`ContentBahasa-${index}`}
                  value={input.ContentBahasa}
                  onChange={(e) => {
                    handleChangeInputBahasa(e, index),
                      console.log('input', input);
                  }}
                  style={{ marginTop: 10 }}
                />
              </Grid>
              <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                {inputFieldsContent.length > 1 && ( // Kondisi untuk menampilkan RemoveOutlined
                  <IconButton onClick={() => handleRemoveFieldsEnglish(index)}>
                    <DeleteOutlineOutlined style={{ width: 24, height: 24, color: '#002C5F' }} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </Container>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  );
};

export type PartialSubsContentCreateProps = {
  ContentName: string
  Subtitle: string
  SubscriptionPlan: SubscriptionApplicablePlanProps[]
  Content: ContentData[]
}

export type SubscriptionApplicablePlanProps = {
  PlanPkId: number,
  SubscriptionPlanName: string
}

export type ContentData = {
  ContentEN: string,
  ContentID: string
}


export default Create;