import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Chip,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import GLOBAL from 'modules/global'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetDetailSubsPlanQuery } from 'store/subsplan'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetDetailSubsPlanQuery(id)

  const loading = !data && (isLoading || isFetching)

  const subsplanID = (data && data.pkId) || '-'
  const subsplaname = (data && data.subscriptionPlanName) || '-'
  const subsplanameEN = data && data.subscriptionPlanNameEN
  const subsplanameID = data && data.subscriptionPlanNameID
  const sequenceNo = data && data.seqNo
  const schemeType = data && data.duration
  const validFrom = data && GLOBAL.formatDate(data.validFrom) || '-'
  const validUntil = data && GLOBAL.formatDate(data.validUntil) || '-'
  const price = data && data.price
  const iconPlan = data && data.iconUrl || null

  const gridDataLabel = [
    'Subscription Plan ID',
    'Subscription Plan Name',
    'Subscription Plan Name EN-US',
    'Subscription Plan Name ID',
    'Sequence No',
    'Scheme Type'
  ]

  const gridDataValue = [
    subsplanID,
    subsplaname,
    subsplanameEN,
    subsplanameID,
    sequenceNo,
    schemeType
  ]

  const gridDataLabel2 = [
    'Start Date',
    'End Date'
  ]

  const gridDataValue2 = [
    validFrom,
    validUntil
  ]

  const gridDataLabel3 = [
    'Price',
  ]

  const gridDataValue3 = [
    price
  ]

  const createdBy = (data && data.createdAt) || '-'
  const createdAt = (data && data.createdAt) || '-'
  const modifiedBy = (data && data.modifiedAt) || '-'
  const modifiedAt = (data && data.modifiedAt) || '-'

  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = [createdBy, createdAt, modifiedBy, modifiedAt]

  return (
    <>
      <Drawer open={open} title='Subscription Detail Plan' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <Grid container spacing={2}>
                {gridDataValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridDataLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2}>
                {gridDataValue2.map((item, index) => (
                  <Grid key={index} item xs={6}>
                    <TextField
                      variant='outlined'
                      label={gridDataLabel2[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2}>
                {gridDataValue3.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridDataLabel3[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <Autocomplete
                fullWidth
                sx={{ marginBottom: '10px' }}
                multiple
                id='fixed-tags-demo'
                defaultValue={(data && data.carModel) || []}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.carName == value.carName : false
                }
                disabled
                options={(data && data.carModel) || []}
                getOptionLabel={(option) => option.carName}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip label={option.carName} {...getTagProps({ index })} key={index} />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} label='Car model' />
                )}
              />
              <Box style={{
                display: 'flex',
                width: '100%',
                minHeight: '4rem',
                border: '1px solid rgba(0, 0, 0, 0.2)',
                borderRadius: 1,
                overflow: 'hidden',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                {iconPlan && (
                  <img
                    src={iconPlan}
                    alt={iconPlan}
                    style={{
                      height: 24, width: 24, objectFit: 'contain',
                      marginRight: '10px',
                      marginLeft: '-300px',
                      display: 'flex',
                    }}
                  />
                )}
                <Typography>Icon Plan</Typography>
              </Box>
              <Accordion elevation={0} disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls='basic-information'
                >
                  <Typography>Basic Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {gridBasicValue.map((item, index) => (
                      <Grid key={index} item xs={12}>
                        <TextField
                          variant='outlined'
                          label={gridBasicLabel[index]}
                          value={item}
                          inputProps={{ readOnly: true }}
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
