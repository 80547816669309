import { useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid'
import { useSelector } from 'react-redux'
import { ExpandMore } from '@mui/icons-material'
import Content from 'components/content/content'
import Loading from 'components/loading/loading'
import type { ErrorProps, RolesAssignmentMenusssProps, UserProps } from 'modules/types'
import { useGetAccountPermissionDetailsssQuery } from 'store/assignment'
import { useGetUsersAccountDetailsssQuery } from 'store/control'
import { useGetMenuTreesssQuery } from 'store/menu'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import AccessStyle from './style'

const Access = () => {
  const state = useSelector((state: { user: UserProps }) => state.user)
  console.log('state.user.userCode', state.user.userCode);

  const {
    data: dataAccount,
    error: dataAccountError,
    isFetching: isAccountFetching,
    isLoading: isAccountLoading,
    isUninitialized: isACcountUninitialized,
    isSuccess: isAccountSuccess,
    isError: isAccountError
  } = useGetUsersAccountDetailsssQuery(state.user.userCode)

  const {
    data: dataPermission,
    isFetching: isPermissionFetching,
    isLoading: isPermissionLoading
  } = useGetAccountPermissionDetailsssQuery(state.user.userCode)

  const {
    data: dataMenu,
    isFetching: isMenuFetching,
    isLoading: isMenuLoading
  } = useGetMenuTreesssQuery()

  const selectedColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96 },
    { field: 'menu', headerName: 'Main Menu', width: 192 },
    { field: 'name', headerName: 'Sub Menu', width: 192 },
    { field: 'convertedFunctions', headerName: 'Function Name', flex: 1 }
  ]
  console.log('dataAccount', dataAccount);
  const selectedMainMenu =
    dataMenu &&
    dataMenu.mainMenu
      .map((main) => {
        const subMenu = main.subMenu.map((subMenu) => {
          const functions = subMenu.subMenu.map((func) => ({
            mainMenuId: func.mainMenuId,
            name: func.name
          }))

          const list = {
            menu: main.name,
            name: subMenu.name,
            id: subMenu.mainMenuId,
            functions: functions,
            convertedFunctions: functions.map((func) => func.name).join(', ')
          }

          return list
        })
        return subMenu
      })
      .filter((menu) => menu.length)
      .flat(1)

  const email = (dataAccount && dataAccount.email) || '-'
  const fullName = (dataAccount && dataAccount.fullname) || '-'
  const mobilePhone = (dataAccount && dataAccount.mobileNumber) || '-'

  const gridDataLabel = ['Full Name', 'email', 'Mobile Phone']
  const gridDataValue = [fullName, email, mobilePhone]

  const [selectedRow, setSelectedRow] = useState<(RolesAssignmentMenusssProps | undefined)[]>(
    selectedMainMenu as (RolesAssignmentMenusssProps | undefined)[]
  )

  const isLoading =
    (isAccountLoading || isAccountFetching) &&
    !dataAccount &&
    (isPermissionLoading || isPermissionFetching) &&
    !dataPermission &&
    (isMenuLoading || isMenuFetching) &&
    !dataMenu

  if (!selectedRow && isEmpty(selectedRow) && selectedMainMenu) {
    setSelectedRow(selectedMainMenu)
  }

  return (
    <>
      <Content title='My Access' useTable={false} isLoading={isLoading}>
        {isLoading && <Loading />}
        <Container {...AccessStyle.Container}>
          {dataAccount && (
            <Accordion elevation={0} disableGutters expanded>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>Basic Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Container {...AccessStyle.Basic}>
                  {gridDataValue.map((item, index) => (
                    <TextField
                      key={index}
                      variant='outlined'
                      label={gridDataLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      multiline
                      fullWidth
                    />
                  ))}
                </Container>
              </AccordionDetails>
            </Accordion>
          )}

          {selectedRow && !isEmpty(selectedRow) && (
            <Accordion elevation={0} disableGutters expanded>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>Access</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <DataGrid rows={selectedRow} columns={selectedColumns} hideFooter autoHeight />
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </Container>
      </Content>

      <Notification
        open={!isAccountLoading && !isACcountUninitialized && !isAccountSuccess}
        onClose={() => (isAccountError ? {} : location.reload())}
        isError={isAccountError}
        message={GLOBAL.returnExceptionMessage(isAccountError, dataAccountError as ErrorProps)}
      />
    </>
  )
}

export default Access
