import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
  FormHelperText,
  Typography
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useDownloadDiscountSettingMutation, useGetListDiscountSettingMutation, useUpdateStatusDiscountSettingMutation } from 'store/discountSetting'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
// import type { PartialDiscountSettingProps, PartialFormDataCapsProps } from 'modules/partial'
import Notification from 'components/notification'
import type { ErrorProps } from 'modules/types'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import Create from './create/create'
// import Edit from './edit/page'
import Detail from './detail/detail'

const DiscountContentList = () => {
  const [getVoucherUsageList, listVoucherUsage] = useGetListDiscountSettingMutation()
  const [downloadVoucherUsageList, download] = useDownloadDiscountSettingMutation()
  const [updateStatusDiscount, update] = useUpdateStatusDiscountSettingMutation()

  const [messageUntil, setMessageUntil] = useState<string>('')

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  // const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [randomKey, setRandomKey] = useState<number>(0)

  const [filter, setFilter] = useState<PartialDiscountSettingProps>({
    Status: '',
    ValidFrom: '',
    ValidUntil: ''
  })

  const [payload, setPayload] = useState<PartialDiscountSettingProps & PartialFormDataCapsProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    Status: '',
    ValidFrom: '',
    ValidUntil: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search))
      formData.append('Search', payload.Search)

    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (!isEmpty(payload.ValidFrom)) formData.append('ValidFrom', payload.ValidFrom)
    if (!isEmpty(payload.ValidUntil)) formData.append('ValidUntil', payload.ValidUntil)

    getVoucherUsageList(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search))
      formData.append('Search', payload.Search)

    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (!isEmpty(payload.ValidFrom)) formData.append('ValidFrom', payload.ValidFrom)
    if (!isEmpty(payload.ValidUntil)) formData.append('ValidUntil', payload.ValidUntil)

    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadVoucherUsageList(formData)
  }

  const onCreate = () => setDialogCreate(true)

  const onFilter = (state: boolean) => {setDialogFilter(state), setMessageUntil('')}

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback: CallableFunction) => {
    setAnchor(null)
    callback(callback)
  }

  const tableHeadTitles = [
    'Discount Name',
    'Discount Type',
    'Discount Amount',
    'Start Date',
    'End Date',
    'Applicable Subs Plan',
    'Status'
  ]

  const openAnchor = Boolean(anchor)
  const totalPage =
    listVoucherUsage.data && GLOBAL.tableTotalPage(listVoucherUsage.data.recordsFiltered)
  const numbers = GLOBAL.tableNumber(payload.Start)

  // const carNames = listVoucherUsage.data?.data
  //   ?.map(record => record.subscriptionPlan.map(car => car.subscriptionPlanName)) // Ambil carName
  //   .flat() // Gabungkan menjadi satu array
  //   .filter((value, index, self) => self.indexOf(value) === index) // Hapus duplikat
  //   .join(', ');

  const formatCurrency = (value: number) =>
    value ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : '-'

  const setIspercent = (value: number, isPercent: string) => {
    if (isPercent === 'Percentage') {
      return `${value} %`;
    } else {
      return `Rp ${formatCurrency(value)}`;
    }
  };

  const isEndDateGreaterThanStartDate = () => {
    if (
      messageUntil && dayjs(filter.ValidFrom).isAfter(dayjs(filter.ValidUntil))
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    onResync()
    setMessageUntil('')
  }, [payload])

  return (
    <>
      <Content
        title='Discount List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={listVoucherUsage.data && listVoucherUsage.data.recordsFiltered}
        page={payload.Start + 1}
        isLoading={listVoucherUsage.isLoading}
        useDownloadDropdown
        onAdditional={onCreate}
        additionalTitle='New Discount'
        additionalPrefixIcon='Add'
      >
        {listVoucherUsage.isSuccess &&
          listVoucherUsage.data &&
          listVoucherUsage.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.pkId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.pkId}`}
                  aria-labelledby={`button-${index}-${table.pkId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  {/* <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem> */}
                  <MenuModalItem
                    title={table.status === 'DISABLED' ? 'Enable' : 'Disable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        updateStatusDiscount({
                          Id: table.pkId,
                          IsActive: table.status == 'DISABLED'
                        })
                      )
                    }
                  >
                    {table.status == 'DISABLED' ? 'Enabled' : 'Disabled'}
                  </MenuModalItem>
                </Menu>
                <Detail
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                  id={table.pkId}
                />
                {/* {dialogUpdate && menu == index && (
                  <Edit
                    id={table.pkId}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )} */}
              </TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>{table.discountType || '-'}</TableCell>
              <TableCell>{setIspercent(table.value, table.discountType)}</TableCell>
              <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.validFrom)}</TableCell>
              <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.validUntil)}</TableCell>
              <TableCell>{table.subscriptionPlan}</TableCell>
              <TableCell>
                <Chip
                  label={table.status === 'ENABLED' ? 'Enabled' : 'Disabled'}
                  color={table.status == 'ENABLED' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}
      {listVoucherUsage.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter) || isEndDateGreaterThanStartDate()}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomKey(randomKey + 1)
          setFilter({
            Status: '',
            ValidFrom: '',
            ValidUntil: ''
          })
          setPayload({
            ...payload,
            Start: 0,
            Status: '',
            ValidFrom: '',
            ValidUntil: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, Start: 0, ...filter })
          onFilter(false)
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.Status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, Status: event.target.value })}
          >
            <MenuItem value='ENABLED'>Enabled</MenuItem>
            <MenuItem value='DISABLED'>Disabled</MenuItem>
          </Select>
        </FormControl>

        <Typography>Period</Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.ValidFrom ? dayjs(filter.ValidFrom) : null}
              // minDate={dayjs(filter.ValidFrom)}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, ValidFrom: (event && event.format('YYYY-MM-DD')) || '' })
              }
              label='Start Date'
              format='MMM DD, YYYY'
              sx={{ width: "190px" }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.ValidUntil ? dayjs(filter.ValidUntil) : null}
              minDate={dayjs(filter.ValidFrom)}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, ValidUntil: (event && event.format('YYYY-MM-DD')) || '' })
              }
              onError={(error) => {
                if (error) {
                  setMessageUntil(filter.ValidUntil)
                }
              }}
              label='End Date'
              format='MMM DD, YYYY'
              sx={{ width: "190px" }}
            />
          </LocalizationProvider>
        </div>
        {messageUntil && dayjs(filter.ValidFrom).isAfter(dayjs(filter.ValidUntil)) && (
          <FormHelperText sx={{alignSelf: 'center', marginTop: -2}} error id='ValidUntil'>Start Date must be greater than End Date</FormHelperText>
        )}

      </Filter>

      <Notification
        open={
          !listVoucherUsage.isLoading &&
          !listVoucherUsage.isUninitialized &&
          !listVoucherUsage.isSuccess
        }
        onClose={() => (listVoucherUsage.isError ? listVoucherUsage.reset() : location.reload())}
        isError={Boolean(listVoucherUsage.error) && listVoucherUsage.isError}
        message={GLOBAL.returnExceptionMessage(
          listVoucherUsage.isError,
          listVoucherUsage.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export type PartialDiscountSettingProps = {
  Status: string
  ValidFrom: string
  ValidUntil: string
}

export type PartialFormDataCapsProps = {
  Start: number
  Length: number
  Search: string
}

export default DiscountContentList
