import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  TextField,
  Autocomplete,
  Typography,
  Chip,
  FormHelperText,
  // FormControl,
  // InputLabel,
  // Select
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useDownloadVoucherUsageListMutation } from 'store/voucherUsage'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import Notification from 'components/notification'
import type { ErrorProps } from 'modules/types'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import { useGetApplicableSubsPlanDropdownMutation, useGetMemberTypeDropdownsssMutation } from 'store/dropdown'
import { useGetPaidSubscriptionListMutation } from 'store/paidSubscription'
import Detail from './detail/detail'

const PaidSubscription = () => {

  const [getPaidSubscriptionList, listPaidSubscription] = useGetPaidSubscriptionListMutation()
  const [downloadVoucherUsageList, download] = useDownloadVoucherUsageListMutation()

  const [getApplicableSubsPlanDropdown, applicableSubsPlanDropdownResult] =
    useGetApplicableSubsPlanDropdownMutation()

  const [getCarModelList, carModelListResult] = useGetMemberTypeDropdownsssMutation()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [randomKey, setRandomKey] = useState<number>(0)

  const [filter, setFilter] = useState<PartialPaidSubscriptionProps>({
    SubscriptionName: '',
    MinSubscriptionStartDate: '',
    MaxSubscriptionStartDate: '',
    MinSubscriptionEndDate: '',
    MaxSubscriptionEndDate: '',
    CarModel: '',
    StatusRenewal: '',
    SubscriptionStatus: ''
  })

  const [payload, setPayload] = useState<PartialPaidSubscriptionProps & PartialFormDataCapsProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    SubscriptionName: '',
    MinSubscriptionStartDate: '',
    MaxSubscriptionStartDate: '',
    MinSubscriptionEndDate: '',
    MaxSubscriptionEndDate: '',
    CarModel: '',
    StatusRenewal: '',
    SubscriptionStatus: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })
  const formatDate = (value: string) => value ? moment(value).format('MMM DD, YYYY') : '-';
  const formatCurrency = (value: number) =>
    value ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : '-'
  const [messageUntil, setMessageUntil] = useState<string>('')
  const [messageUntilPeriodEnd, setMessageUntilPeriodEnd] = useState<string>('')

  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search))
      formData.append('Search', payload.Search)

    if (!isEmpty(payload.SubscriptionName)) formData.append('SubscriptionName', payload.SubscriptionName)
    if (!isEmpty(payload.MinSubscriptionStartDate)) formData.append('MinSubscriptionStartDate', payload.MinSubscriptionStartDate)
    if (!isEmpty(payload.MaxSubscriptionStartDate)) formData.append('MaxSubscriptionStartDate', payload.MaxSubscriptionStartDate)
    if (!isEmpty(payload.MinSubscriptionEndDate)) formData.append('MinSubscriptionEndDate', payload.MinSubscriptionEndDate)
    if (!isEmpty(payload.MaxSubscriptionEndDate)) formData.append('MaxSubscriptionEndDate', payload.MaxSubscriptionEndDate)
    if (!isEmpty(payload.CarModel)) formData.append('CarModel', payload.CarModel)
    if (!isEmpty(payload.StatusRenewal)) formData.append('StatusRenewal', payload.StatusRenewal)
    if (!isEmpty(payload.SubscriptionStatus)) formData.append('SubscriptionStatus', payload.SubscriptionStatus)
    getPaidSubscriptionList(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search))
      formData.append('Search', payload.Search)
    if (!isEmpty(payload.SubscriptionName)) formData.append('SubscriptionName', payload.SubscriptionName)
    if (!isEmpty(payload.MinSubscriptionStartDate)) formData.append('MinSubscriptionStartDate', payload.MinSubscriptionStartDate)
    if (!isEmpty(payload.MaxSubscriptionStartDate)) formData.append('MaxSubscriptionStartDate', payload.MaxSubscriptionStartDate)
    if (!isEmpty(payload.MinSubscriptionEndDate)) formData.append('MinSubscriptionEndDate', payload.MinSubscriptionEndDate)
    if (!isEmpty(payload.MaxSubscriptionEndDate)) formData.append('MaxSubscriptionEndDate', payload.MaxSubscriptionEndDate)
    if (!isEmpty(payload.CarModel)) formData.append('CarModel', payload.CarModel)
    if (!isEmpty(payload.StatusRenewal)) formData.append('StatusRenewal', payload.StatusRenewal)
    if (!isEmpty(payload.SubscriptionStatus)) formData.append('SubscriptionStatus', payload.SubscriptionStatus)

    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadVoucherUsageList(formData)
  }

  const onFilter = (state: boolean) => { setDialogFilter(state), setMessageUntil(''), setMessageUntilPeriodEnd('') }
  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback: CallableFunction) => {
    setAnchor(null)
    callback(callback)
  }

  const tableHeadTitles = [
    'Registered Owner',
    'VIN',
    'Plan',
    'Total Price',
    'Auto Renewal',
    'Period Start',
    'Period End',
    'Car Model',
    'Subscription Status'
  ]

  const openAnchor = Boolean(anchor)
  const totalPage =
    listPaidSubscription.data && GLOBAL.tableTotalPage(listPaidSubscription.data.recordsFiltered)
  const numbers = GLOBAL.tableNumber(payload.Start)

  const isEndDateGreaterThanStartDate = () => {
    if (
      messageUntil && dayjs(filter.MinSubscriptionStartDate).isAfter(dayjs(filter.MaxSubscriptionStartDate)) ||
      messageUntilPeriodEnd && dayjs(filter.MinSubscriptionEndDate).isAfter(dayjs(filter.MaxSubscriptionEndDate))
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    onResync()
    setMessageUntil('')
    setMessageUntilPeriodEnd('')
  }, [payload])

  return (
    <>
      <Content
        title='Paid Subscription'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={listPaidSubscription.data && listPaidSubscription.data.recordsFiltered}
        page={payload.Start + 1}
        isLoading={listPaidSubscription.isLoading}
        useDownloadDropdown
      >
        {listPaidSubscription.isSuccess &&
          listPaidSubscription.data &&
          listPaidSubscription.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.pkId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='VisibilityOutlined' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.pkId}`}
                  aria-labelledby={`button-${index}-${table.pkId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                </Menu>
                <Detail
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                  id={table.pkId}
                />
              </TableCell>
              <TableCell>{table.registeredOwner || '-'}</TableCell>
              <TableCell>{table.vin || '-'}</TableCell>
              <TableCell>
                {table.iconUrl ? (
                  <img
                    src={table.iconUrl}
                    alt={table.subscriptionPlanData}
                    style={{
                      height: 24,
                      width: 24,
                      borderRadius: 100,
                      objectFit: 'contain',
                      marginBottom: '-8px',
                      marginRight: '8px',
                    }}
                  />
                ) : (
                  <img
                    src={'https://dummyimage.com/50'}
                    alt={table.subscriptionPlanData}
                    style={{
                      height: 24,
                      width: 24,
                      borderRadius: 100,
                      objectFit: 'contain',
                      marginBottom: '-8px',
                      marginRight: '8px',
                    }}
                  />
                )}
                {table.subscriptionPlanData || '-'}</TableCell>
              <TableCell>{`Rp ${formatCurrency(table.totalPrice)}`}</TableCell>
              <TableCell>{table.autoRenewalStatus}</TableCell>
              <TableCell>{formatDate(table.subscriptionStartDate)}</TableCell>
              <TableCell>{formatDate(table.subscriptionEndDate)}</TableCell>
              <TableCell>{table.carModelName}</TableCell>
              <TableCell>
                <Chip
                  label={table.subscriptionStatus === 'Active' ? 'Active' : 'Inactive'}
                  color={table.subscriptionStatus == 'Active' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {listPaidSubscription.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter) || isEndDateGreaterThanStartDate()}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomKey(randomKey + 1)
          setFilter({
            SubscriptionName: '',
            MinSubscriptionStartDate: '',
            MaxSubscriptionStartDate: '',
            MinSubscriptionEndDate: '',
            MaxSubscriptionEndDate: '',
            CarModel: '',
            StatusRenewal: '',
            SubscriptionStatus: ''
          })
          setPayload({
            ...payload,
            Start: 0,
            SubscriptionName: '',
            MinSubscriptionStartDate: '',
            MaxSubscriptionStartDate: '',
            MinSubscriptionEndDate: '',
            MaxSubscriptionEndDate: '',
            CarModel: '',
            StatusRenewal: '',
            SubscriptionStatus: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, Start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Autocomplete
          key={'planName-' + randomKey}
          options={
            (applicableSubsPlanDropdownResult && applicableSubsPlanDropdownResult.data) || []
          }
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getApplicableSubsPlanDropdown()}
          onChange={(_, planName) => {
            setFilter({
              ...filter,
              SubscriptionName: String((planName && planName.name) || '')
            })
          }}
          value={
            (applicableSubsPlanDropdownResult &&
              applicableSubsPlanDropdownResult.data &&
              applicableSubsPlanDropdownResult.data.find(
                (e) => String(e.name) == filter.SubscriptionName
              )) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='planName'
              label='Subscription Plan Name'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {applicableSubsPlanDropdownResult.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Typography><strong>Subscription Period Start</strong></Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex', marginTop: -15 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MinSubscriptionStartDate ? dayjs(filter.MinSubscriptionStartDate) : null}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MinSubscriptionStartDate: (event && event.format('YYYY-MM-DD')) || '' })
              }
              label='Min Date'
              format='MMM DD, YYYY'
              sx={{ width: '180px' }}

            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MaxSubscriptionStartDate ? dayjs(filter.MaxSubscriptionStartDate) : null}
              minDate={dayjs(filter.MinSubscriptionStartDate)}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MaxSubscriptionStartDate: (event && event.format('YYYY-MM-DD')) || '' })
              }
              onError={(error) => {
                if (error) {
                  setMessageUntil(filter.MaxSubscriptionStartDate)
                }
              }}
              label='Max Date'
              format='MMM DD, YYYY'
              sx={{ width: '180px' }}
            />
          </LocalizationProvider>
        </div>
        {messageUntil && dayjs(filter.MinSubscriptionStartDate).isAfter(dayjs(filter.MaxSubscriptionStartDate)) && (
          <FormHelperText sx={{alignSelf: 'center', marginTop: -2}} error id='MaxSubscriptionStartDate'>Max Date must be greater than Min Date</FormHelperText>
        )}


        <Typography><strong>Subscription Period End</strong></Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex', marginTop: -15 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MinSubscriptionEndDate ? dayjs(filter.MinSubscriptionEndDate) : null}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MinSubscriptionEndDate: (event && event.format('YYYY-MM-DD')) || '' })
              }
              label='Min Date'
              format='MMM DD, YYYY'
              sx={{ width: '180px' }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MaxSubscriptionEndDate ? dayjs(filter.MaxSubscriptionEndDate) : null}
              minDate={dayjs(filter.MinSubscriptionEndDate)}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MaxSubscriptionEndDate: (event && event.format('YYYY-MM-DD')) || '' })
              }
              onError={(error) => {
                if (error) {
                  setMessageUntilPeriodEnd(filter.MaxSubscriptionEndDate)
                }
              }}
              label='Max Date'
              format='MMM DD, YYYY'
              sx={{ width: '180px' }}
            />
          </LocalizationProvider>
        </div>
        {messageUntilPeriodEnd && dayjs(filter.MinSubscriptionEndDate).isAfter(dayjs(filter.MaxSubscriptionEndDate)) && (
          <FormHelperText sx={{alignSelf: 'center', marginTop: -2}} error id='MaxSubscriptionEndDate'>Max Date must be greater than Min Date</FormHelperText>
        )}

        <Autocomplete
          options={(carModelListResult && carModelListResult.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getCarModelList()}
          onChange={(_, partner) =>
            setFilter({
              ...filter,
              CarModel: (partner && String(partner.name)) || ''
            })
          }
          value={
            (carModelListResult &&
              carModelListResult.data &&
              carModelListResult.data.find((e) => String(e.name) == filter.CarModel)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='CarModel'
              label='Car Model'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {carModelListResult.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />

        <Autocomplete
          options={yesNoValues}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onChange={(_, renewalStatus) =>
            setFilter({
              ...filter,
              StatusRenewal: (renewalStatus && String(renewalStatus.id)) || ''
            })
          }
          value={yesNoValues.find((e) => String(e.id) == filter.StatusRenewal) || undefined}
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='renewalStatus'
              label='Auto Renewal'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {carModelListResult.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />

        <Autocomplete
          options={activeinactiveValues}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onChange={(_, renewalStatusSubscription) =>
            setFilter({
              ...filter,
              SubscriptionStatus: (renewalStatusSubscription && String(renewalStatusSubscription.id)) || ''
            })
          }
          value={activeinactiveValues.find((e) => String(e.id) == filter.SubscriptionStatus) || undefined}
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='renewalStatusSubscription'
              label='Subscription Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {carModelListResult.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>

      <Notification
        open={
          !listPaidSubscription.isLoading &&
          !listPaidSubscription.isUninitialized &&
          !listPaidSubscription.isSuccess
        }
        onClose={() => (listPaidSubscription.isError ? listPaidSubscription.reset() : location.reload())}
        isError={Boolean(listPaidSubscription.error) && listPaidSubscription.isError}
        message={GLOBAL.returnExceptionMessage(
          listPaidSubscription.isError,
          listPaidSubscription.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}

export type PartialPaidSubscriptionProps = {
  SubscriptionName: string
  MinSubscriptionStartDate: string
  MaxSubscriptionStartDate: string
  MinSubscriptionEndDate: string
  MaxSubscriptionEndDate: string
  CarModel: string
  StatusRenewal: string
  SubscriptionStatus: string
}

export type PartialFormDataCapsProps = {
  Start: number
  Length: number
  Search: string
}

const yesNoValues = [
  { id: 'Yes', name: 'Yes' },
  { id: 'No', name: 'No' }
]

const activeinactiveValues = [
  { id: 'Active', name: 'Active' },
  { id: 'Inactive', name: 'Inactive' }
]
export default PaidSubscription
