import { initializeApp } from 'firebase/app'
// import { getToken, initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { getMessaging } from 'firebase/messaging'

// const firebaseConfig = {
//   apiKey: 'AIzaSyCF80yd3Mp_PF8uMRytyDufI_phtvSG2jo',
//   authDomain: 'bluelink-subs-stg.firebaseapp.com',
//   projectId: 'bluelink-subs-stg',
//   storageBucket: 'bluelink-subs-stg.firebasestorage.app',
//   messagingSenderId: '592011200908',
//   appId: '1:592011200908:web:27c0be85a6a7da1558aea9',
//   measurementId: 'G-MD6PNTY27W'
// }

const firebaseConfig = {
  apiKey: 'AIzaSyCl2Z4ny0YtKfXkqlz_xxdlMDw6TimVOF4',
  authDomain: 'myhyundai2-dev.firebaseapp.com',
  databaseURL: 'https://myhyundai2-dev-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'myhyundai2-dev',
  storageBucket: 'myhyundai2-dev.firebasestorage.app',
  messagingSenderId: '1000205877322',
  appId: '1:1000205877322:web:cd798e60ce450e2a2726b3',
  measurementId: 'G-TE1XYYHYB3'
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_APPCHECK_DEBUG_TOKEN

// const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ''),
//   isTokenAutoRefreshEnabled: true
// })

export const getAppCheckToken = async () => {
  try {
    // const { token } = await getToken(appCheck, false)

    return 'token'
  } catch (error) {
    console.error('Error Getting AppCheck token', error)
    return ''
  }
}

export const messaging = getMessaging(firebaseApp)
