import { Box, IconButton, Drawer as SideDrawer, Typography } from '@mui/material'
import Icon from 'components/icon'
import type { DetailStateProps } from 'modules/types'
import DetailStyle from './style'

const Drawer = ({ open, title, maxWidth, children, onClose }: DetailStateProps) => (
  <SideDrawer
    anchor='right'
    open={open}
    onClose={onClose}
    sx={{ zIndex: 1202 }}
    PaperProps={{
      sx: {
        width: maxWidth === 'md' ? 768 : 512,
        [`@media(max-width:${maxWidth === 'md' ? 768 : 512}px)`]: {
          width: '100%'
        }
      }
    }}
  >
    <Box {...DetailStyle.Container}>
      <IconButton onClick={onClose}>
        <Icon icon='Close' />
      </IconButton>
      <Typography variant='h6' sx={{ flex: 1, fontWeight: 500 }}>
        {title}
      </Typography>
    </Box>
    {children}
  </SideDrawer>
)

export default Drawer
