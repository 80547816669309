import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Icon from 'components/icon'
// import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetDetailPromoCodeBatchSubsDetailQuery } from 'store/promoCodeBatch'
import Loading from 'components/loading/loading'
import GLOBAL from 'modules/global'
import DialogStyle from './style'


const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <></>

  const { data, isLoading, isFetching } = useGetDetailPromoCodeBatchSubsDetailQuery(id)

  const promoCodeID = (data && data.code) || '-'
  const promoCode = (data && data.name) || '-'
  const prefix = (data && data.promoCode) || '-'
  const redeemed = (data && data.redeemed) || '-'
  const descrption = (data && data.description) || '-'
  const promoType = (data && data.isPercent) || '-'
  const promoAmount = (data && data.value) || '-'
  const maximumDeduction = (data && data.maximumDeduction) || '-'
  const minimumPayment = (data && data.minimumPayment) || '-'
  const validFrom = (data && GLOBAL.formatDate(data.validFrom)) || '-'
  const validUntil = (data && GLOBAL.formatDate(data.validUntil)) || '-'

  const gridDataLabelFirstLeft = ['Promo Code ID', 'Prefix']
  const gridDataValueFirstLeft = [promoCodeID, prefix]

  const gridDataLabelRight = [
    'Promo Code',
    'Redeemed'
  ]
  const gridDataValueRight = [promoCode, redeemed]


  const gridDataLabelSecondLeft = [
    'Promo Type',
    'Maximum Deduction',
    'Start Date'
  ]

  const gridDataValueSecondLeft = [
    promoType,
    maximumDeduction,
    validFrom
  ]
  const gridDataLabelSecondRight = [
    'Promo Amount',
    'Minimum Payment',
    'End Date'
  ]

  const gridDataValueSecondRight = [
    promoAmount,
    minimumPayment,
    validUntil
  ]

  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = ['', '', '', '']

  return (
    <Dialog open={open} fullWidth maxWidth='md' onClose={onClose}>
      <DialogContent>
        <DialogTitle
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography variant='h6'>Detail Promo Code Batch List</Typography>
          <Box>
            <IconButton onClick={onClose}>
              <Icon icon='Close' />
            </IconButton>
          </Box>
        </DialogTitle>
        {(isLoading || isFetching) && !data && <Loading />}

        {data && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{
              display: 'flex', flexDirection: 'row', alignItems: 'flex-start',
              justifyContent: 'space-between',
              position: 'relative'
            }}>
              <Box sx={{ padding: '10px 24px 24px 10px', flex: 1 }}>
                {gridDataValueFirstLeft.map((item, index) => (
                  <TextField
                    key={index}
                    {...DialogStyle.textField}
                    value={item}
                    variant='outlined'
                    inputProps={{ readOnly: true }}
                    label={gridDataLabelFirstLeft[index]}
                    fullWidth
                  />
                ))}
              </Box>
              <Box
                sx={{
                  flex: 1
                }}
              >
                <Box sx={{ padding: '10px 24px' }}>
                  {gridDataValueRight.map((item, index) => (
                    <TextField
                      key={index}
                      {...DialogStyle.textField}
                      value={item}
                      variant='outlined'
                      inputProps={{ readOnly: true }}
                      label={gridDataLabelRight[index]}
                      fullWidth
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Box sx={{ paddingLeft: '10px', paddingRight: '24px', marginTop: -2 }}>
              <TextField
                value={descrption}
                id='subDistrict'
                variant='outlined'
                label='Descrption'
                multiline
                rows={2}
                inputProps={{ readOnly: true }}
                {...DialogStyle.textField}
                fullWidth
              />
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'row', alignItems: 'flex-start',
              justifyContent: 'space-between',
              position: 'relative'
            }}>
              <Box sx={{ padding: '10px 24px 24px 10px', flex: 1 }}>
                {gridDataValueSecondLeft.map((item, index) => (
                  <TextField
                    key={index}
                    {...DialogStyle.textField}
                    value={item}
                    variant='outlined'
                    inputProps={{ readOnly: true }}
                    label={gridDataLabelSecondLeft[index]}
                    fullWidth
                  />
                ))}
              </Box>
              <Box
                sx={{
                  flex: 1
                }}
              >
                <Box sx={{ padding: '10px 24px' }}>
                  {gridDataValueSecondRight.map((item, index) => (
                    <TextField
                      key={index}
                      {...DialogStyle.textField}
                      value={item}
                      variant='outlined'
                      inputProps={{ readOnly: true }}
                      label={gridDataLabelSecondRight[index]}
                      fullWidth
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Accordion elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls='basic-information'
              >
                <Typography>Basic Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {gridBasicValue.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      <TextField
                        variant='outlined'
                        label={gridBasicLabel[index]}
                        value={item}
                        inputProps={{ readOnly: true }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export type subscriptionPlanList = {
  planPkId: number
  subscriptionPlanName: string
}

export type CarModelDataProps = {
  carModelId: number
  pkId: number
  carName: string
}

export default Detail
