import React, { useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import { BoltIcon, UserIcon, UserPlusIcon } from '@heroicons/react/20/solid'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import { DonutChart } from 'components/charts/donut'
import { BarChart } from 'components/charts/bar'
import { GaugeChart } from 'components/charts/gauge'
import { NumberChart } from 'components/charts/number'
import { AreaChart } from 'components/charts/area'
import { DashboardHeader } from './header'

const Dashboard = () => {
  useEffect(() => {
    const breadcrumbHeader = document.getElementById('breadcrumbHeader')

    if (breadcrumbHeader) breadcrumbHeader.style.display = 'none'

    return () => {
      if (breadcrumbHeader) breadcrumbHeader.style.removeProperty('display')
    }
  }, [])

  return (
    <>
      <Breadcrumb sideComponent={<DashboardHeader />} />

      <Box p={2}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} md={6}>
            <Box p={2} bgcolor={'white'}>
              <AreaChart />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={6}>
                <Box p={2} bgcolor={'white'}>
                  <GaugeChart />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box p={2} bgcolor={'white'}>
                  <GaugeChart />
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box p={2} bgcolor={'white'}>
                  <NumberChart
                    icon={<UserIcon style={{ height: 30, width: 30, color: '#036672' }} />}
                    data={{
                      name: '',
                      value: 100
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box p={2} bgcolor={'white'}>
                  <NumberChart
                    icon={<UserPlusIcon style={{ height: 30, width: 30, color: '#046C4E' }} />}
                    data={{
                      name: '',
                      value: 100
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box p={2} bgcolor={'white'}>
                  <NumberChart
                    icon={<BoltIcon style={{ height: 30, width: 30, color: '#1A56DB' }} />}
                    data={{
                      name: '',
                      value: 100
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} md={4}>
            <Box p={2} bgcolor={'white'}>
              <BarChart />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box p={2} bgcolor={'white'}>
              <BarChart />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box p={2} bgcolor={'white'}>
              <BarChart />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} md={6}>
            <Box p={2} bgcolor={'white'}>
              <DonutChart
                title='Total Subscription by Plan'
                data={[
                  {
                    name: 'Basic Charge',
                    value: 1404
                  },
                  {
                    name: 'Eco Drive',
                    value: 1224
                  },
                  {
                    name: 'Ultimate Power',
                    value: 1029
                  }
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box p={2} bgcolor={'white'}>
              <DonutChart
                title='Total Subscription by Plan'
                data={[
                  {
                    name: 'BCA VA',
                    value: 1404
                    // color: '#00809e'
                  },
                  {
                    name: 'QRIS',
                    value: 1224
                    // color: '#00aad2'
                  },
                  {
                    name: 'Gopay',
                    value: 1029
                    // color: '#33bbdb'
                  },
                  {
                    name: 'Mandiri VA',
                    value: 1029
                    // color: '#66cce4'
                  },
                  {
                    name: 'BRI Direct',
                    value: 1029
                    // color: '#99dded'
                  }
                ]}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box p={2} bgcolor={'white'}>
              <DonutChart
                title='Total Subscription by Plan'
                data={[
                  {
                    name: 'Basic Charge',
                    value: 1404
                  },
                  {
                    name: 'Eco Drive',
                    value: 1224
                  },
                  {
                    name: 'Ultimate Power',
                    value: 1029
                  }
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box p={2} bgcolor={'white'}>
              <DonutChart
                title='Total Subscription by Plan'
                data={[
                  {
                    name: 'BCA VA',
                    value: 1404
                    // color: '#00809e'
                  },
                  {
                    name: 'QRIS',
                    value: 1224
                    // color: '#00aad2'
                  },
                  {
                    name: 'Gopay',
                    value: 1029
                    // color: '#33bbdb'
                  }
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box p={2} bgcolor={'white'}>
              <DonutChart
                title='Total Subscription by Plan'
                data={[
                  {
                    name: 'BCA VA',
                    value: 1404
                    // color: '#00809e'
                  },
                  {
                    name: 'QRIS',
                    value: 1224
                    // color: '#00aad2'
                  },
                  {
                    name: 'Gopay',
                    value: 1029
                    // color: '#33bbdb'
                  }
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Dashboard
