// import { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  Container,
  TextField
} from '@mui/material'
// import { VisibilityOff, Visibility } from '@mui/icons-material'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useCreateUsersAccountMutation } from 'store/control'
import type { PartialUsersAccountCreateProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>

  const [createUsersAccount, create] = useCreateUsersAccountMutation()
  // const [visibility, setVisibility] = useState<boolean>(false)

  const onSubmit = (values: PartialUsersAccountCreateProps) => createUsersAccount(values)

  const scheme = yup.object<PartialUsersAccountCreateProps>({
    FullName: yup.string().required('Full Name is required'),
    MobileNumber: yup
      .string()
      .matches(GLOBAL.phoneNumberRegex(), 'Phone Number is not valid')
      .min(8, 'Minimum Length is 8')
      .max(15, 'Maximum Length is 15')
      .required('Phone Number is required'),
    Email: yup.string().email('Email is not valid').required('Email is required'),
    // Password: yup
    //   .string()
    //   .matches(
    //     GLOBAL.passwordRegex(),
    //     'Password must contain capital, small letters, number and special characters'
    //   )
    //   .min(8, 'Minimum Length is 8')
    //   .required('Password is required')
  })

  const formik = useFormik<PartialUsersAccountCreateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      FullName: '',
      MobileNumber: '',
      Email: ''
    },
    onSubmit: onSubmit
  })

  return (
    <>
      <Dialog
        title='Create New User Account'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            id='FullName'
            variant='outlined'
            label='Full Name'
            value={formik.values.FullName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.FullName && Boolean(formik.errors.FullName)}
            helperText={
              formik.touched && formik.touched.FullName && formik.errors && formik.errors.FullName
            }
            fullWidth
          />
          <TextField
            id='MobileNumber'
            variant='outlined'
            label='Phone Number'
            value={formik.values.MobileNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.MobileNumber && Boolean(formik.errors.MobileNumber)}
            helperText={
              formik.touched && formik.touched.MobileNumber && formik.errors && formik.errors.MobileNumber
            }
            fullWidth
          />
          <TextField
            id='Email'
            variant='outlined'
            label='Email'
            value={formik.values.Email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.Email && Boolean(formik.errors.Email)}
            helperText={
              formik.touched && formik.touched.Email && formik.errors && formik.errors.Email
            }
            fullWidth
          />
          {/* <FormControl variant='outlined'>
            <InputLabel htmlFor='Password'>Password</InputLabel>
            <OutlinedInput
              id='Password'
              label='Password'
              type={visibility ? 'text' : 'password'}
              value={formik.values.Password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete='new-password'
              error={formik.touched && formik.touched.Password && Boolean(formik.errors.Password)}
              endAdornment={
                <IconButton
                  aria-label='password visibility'
                  onClick={() => setVisibility(!visibility)}
                  edge='end'
                >
                  {visibility ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
              fullWidth
            />
            {formik.touched && formik.touched.Password && formik.errors && (
              <FormHelperText error id='Password'>
                {formik.errors.Password}
              </FormHelperText>
            )}
          </FormControl> */}
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
