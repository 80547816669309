import CONSTANT from 'modules/constant'
import type {
    PaginationProps,
    SubscriptionBluelinkDetailProps,
    SubscriptionBluelinkListProps,
} from 'modules/types'
import { API, download, headers } from './api'

const SubscriptionBluelinkAPI = API.injectEndpoints({
    endpoints: (builder) => ({
        geSubscriptionBluelinkList: builder.mutation<PaginationProps<SubscriptionBluelinkListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_SUBSCRIPTION_BLUELINK_LIST,
                method: 'POST',
                headers: headers({ useContentType: false }),
                body: body,
                formData: true
            })
        }),
        downloadSubscriptionBluelink: builder.mutation<PaginationProps<SubscriptionBluelinkListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_SUBSCRIPTION_BLUELINK_DOWNLOAD,
                method: 'POST',
                headers: headers({ useContentType: false }),
                responseHandler: download,
                body: body,
                formData: true
            })
        }),
        getSubscriptionBluelinkDetail: builder.query<SubscriptionBluelinkDetailProps, number>({
            query: (id) => ({
                url: CONSTANT.URL_SUBSCRIPTION_BLUELINK_DETAIL + id,
                method: 'GET',
                headers: headers()
            })
        })
    })
})

export const {
    useGeSubscriptionBluelinkListMutation,
    useDownloadSubscriptionBluelinkMutation,
    useGetSubscriptionBluelinkDetailQuery
} = SubscriptionBluelinkAPI