import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import * as yup from 'yup'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import GlobalStyle from 'modules/styles'
import type {
  ApplicableSubsPlanDropDown,
  CarTypeDataDropDown,
  DetailStateProps, ErrorProps
} from 'modules/types'
import { useGetApplicableSubsPlanDropdownMutation, useGetMemberTypeDropdownsssMutation } from 'store/dropdown'
import { useGetDetailPromoCodeGeneralQuery, useUpdatePromoCodeGeneralMutation } from 'store/promoCodeGeneral'
import { useGetDiscountTypeDropdownMutation } from 'store/discountSetting'
import DialogStyle from './style'

const Update = ({ open, onClose, id }: DetailStateProps & { id: number }) => {
  if (!open) return <></>
  const { data, isLoading, isFetching, isSuccess } = useGetDetailPromoCodeGeneralQuery(id)
  const [getDiscountTypeDropdown, DiscountTypeDropdown] = useGetDiscountTypeDropdownMutation()
  const [getApplicableSubsPlanDropdown, applicableSubsPlanDropdown] = useGetApplicableSubsPlanDropdownMutation();
  const [getCarModelDropdown, carModelList] = useGetMemberTypeDropdownsssMutation()

  const [discountType, setDiscountType] = useState<string>('');
  const [disableMaximumDeduction, setDisableMaximumDeduction] = useState<boolean>(false);
  const [applicableSubsPlan, setApplicableSubsPlan] = useState<ApplicableSubsPlanDropDown[]>([])
  const [userType, setUserType] = useState<CarTypeDataDropDown[]>([])
  const [updatePromoCodeGeneral, update] = useUpdatePromoCodeGeneralMutation()

  const scheme = yup.object<PartialPromoCodeGeneralEditTempProps>({
    Name: yup.string().required('Promo Name is required'),
    PromoNameEN: yup.string().required('Promo Name EN is required'),
    PromoNameID: yup.string().required('Promo Name ID is required'),
    PromoCode: yup.string().required('Promo Code ID is required'),
    Quantity: yup.number().required('Quantity ID is required').positive().integer(),
    Description: yup.string().required('Description is required'),
    IsPercent: yup.string().required('promo Type is required'),
    Value: yup.number().required('Value is required').positive().integer(),
    MinimumPayment: yup.number().required('Minimum Payment is required').positive().integer(),
    MaximumDeduction: yup.number().required('Maximum Deduction is required').positive().integer(),
    ValidFrom: yup.string().required('Valid From is required'),
    ValidUntil: yup.string().required('Valid Until is required'),
    SubscriptionPlan: yup.array().of(yup.object().shape({
      PlanPkId: yup.number().required(),
      SubscriptionPlanName: yup.string().required(),
    })).required('SubscriptionPlan is required'),
    CarModel: yup.array().of(yup.object().shape({
      PkId: yup.number().required(),
      CarName: yup.string().required(),
    })).required('CarModel is required')
  });

  // const applicableSubsPlanList = (subsPlan: subscriptionPlanList[]) => {
  //   if (!Array.isArray(subsPlan) || subsPlan.length === 0) {
  //     return '-';
  //   }
  //   return subsPlan.map(plan => plan.subscriptionPlanName).join(', ') || '-';
  // };
  const formik = useFormik<PartialPromoCodeGeneralEditTempProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      PkId: (data && data.pkId) || null,
      Name: (data && data.name) || '',
      PromoNameEN: (data && data.promoNameEN) || '',
      PromoNameID: (data && data.promoNameID) || '',
      PromoCode: (data && data.promoCode) || '',
      Quantity: (data && data.quantity) || null,
      Description: (data && data.description) || '',
      IsPercent: (data && data.isPercent) || '',
      Value: (data && data.value) || null,
      ValidFrom: (data && GLOBAL.formatDateddMMYYLongDateDetail(data.validFrom)) || '',
      ValidUntil: (data && GLOBAL.formatDateddMMYYLongDateDetail(data.validUntil)) || '',
      MinimumPayment: (data && data.minimumPayment) || null,
      MaximumDeduction: (data && data.maximumDeduction) || null,
      SubscriptionPlan: (data && data.subscriptionPlan && data.subscriptionPlan.length > 0) ? [{
        PlanPkId: data.subscriptionPlan[0].planPkId,
        SubscriptionPlanName: data.subscriptionPlan[0].subscriptionPlanName
      }] : [],
      CarModel: (data && data.carModelData && data.carModelData.length > 0) ? [{
        PkId: data.carModelData[0].pkId,
        CarName: data.carModelData[0].carName
      }] : [],
    },
    onSubmit: (values: PartialPromoCodeGeneralEditTempProps) => handleSubmit(values)
  })

  const handleSubmit = (values: PartialPromoCodeGeneralEditTempProps) => {
    const MaximumDeductionValue = disableMaximumDeduction ? 0 : values.MaximumDeduction;
    const formatPayload = {
      PkId: values.PkId,
      Name: values.Name,
      PromoNameEN: values.PromoNameEN,
      PromoNameID: values.PromoNameID,
      PromoCode: values.PromoCode,
      Quantity: values.Quantity,
      Description: values.Description,
      IsPercent: values.IsPercent,
      Value: values.Value,
      ValidFrom: dayjs(values.ValidFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      ValidUntil: dayjs(values.ValidUntil).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      MinimumPayment: values.MinimumPayment,
      MaximumDeduction: MaximumDeductionValue,
      IsAllCarModel: values.IsAllCarModel,
      SubscriptionPlan: values.SubscriptionPlan,
      CarModel: values.CarModel,
    };
    updatePromoCodeGeneral(formatPayload).then();
  };

  const errorData = formik.errors

  const [date, setDate] = useState<{
    validFrom: Dayjs | null
    validUntil: Dayjs | null
  }>({
    validFrom: (data && dayjs(data.validFrom)) || null,
    validUntil: (data && dayjs(data.validUntil)) || null
  })

  const handleChange = (value: ApplicableSubsPlanDropDown[]) => {
    const formattedPlans = value.map(plan => ({
      PlanPkId: plan.id,
      SubscriptionPlanName: plan.name
    }));
    formik.setFieldValue('SubscriptionPlan', formattedPlans);
  };

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const handleChangeCarModel = (value: CarTypeDataDropDown[]) => {
    const isian = carModelList.data || []; // All available car models
    // Check if "All" is selected
    if (value.some(item => item.name === "All")) {
      setUserType(isian); // Set userType to all available options
      setIsDisabled(true); // Disable the dropdown
      formik.setFieldValue('IsAllCarModel', true); // Set IsAllCarModel to true
      formik.setFieldValue('CarModel', isian.map(car => ({ PkId: car.id, CarName: car.name }))); // Set all car models
    } else {
      // If "All" is not selected, filter out the "All" option
      const filteredValue = value.filter(item => item.name !== "All");
      setUserType(filteredValue);
      setIsDisabled(false); // Enable the dropdown
      formik.setFieldValue('CarModel', filteredValue.map(car => ({ PkId: car.id, CarName: car.name })));

      // Set IsAllCarModel based on the selection
      const isAllSelected = filteredValue.length === isian.length; // Check if all available models are selected
      formik.setFieldValue('IsAllCarModel', isAllSelected);
    }
  };

  // const DiscountTypeDropdownValue = (!DiscountTypeDropdown.isLoading &&
  //   DiscountTypeDropdown?.data?.find((brand) => {
  //     const discountType = data?.isPercent;
  //     return discountType && brand.name.toLowerCase() === discountType.toLowerCase();
  //   })) || null;


  useEffect(() => {
    if (data && isSuccess) {

      setDate({
        validFrom: dayjs(data.validFrom),
        validUntil: dayjs(data.validUntil)
      });

      // setDiscountType(data.isPercent || '');

      getDiscountTypeDropdown()
      getApplicableSubsPlanDropdown()
      const defaultSubscriptionPlan =
        (data &&
          data.subscriptionPlan &&
          data.subscriptionPlan.map((item) => ({
            id: item.planPkId,
            name: item.subscriptionPlanName
          }))) ||
        []
      setApplicableSubsPlan(defaultSubscriptionPlan)


      getCarModelDropdown()
      const defaultCarModel =
        (data &&
          data.carModelData &&
          data.carModelData.map((item) => ({
            id: item.pkId,
            name: item.carName
          }))) ||
        []
      setUserType(defaultCarModel)
    }
  }, [isSuccess, data])

  const disabled = !formik.isValid ||  (formik.values.SubscriptionPlan.length === 0) ||
  (formik.values.CarModel.length === 0);
  console.log('disabled', disabled);
  console.log('formikError', formik.errors)
  console.log('formikTouched', formik.touched)
  console.log('discountType', discountType);

  return (
    <>
      <Dialog
        isDisabled={disabled}
        title='Edit Promo Code General'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        maxWidth='md'
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && <Loading />}
          {data && isSuccess && (
            <>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                  <TextField
                    id='PkId'
                    variant='outlined'
                    label='Promo Code ID'
                    disabled
                    value={formik.values.PkId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.PkId && Boolean(errorData.PkId)}
                    helperText={formik.touched.PkId && errorData.PkId}
                    fullWidth
                  />
                  <TextField
                    id='PromoNameEN'
                    variant='outlined'
                    label='Promo Name EN-US'
                    value={formik.values.PromoNameEN}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.PromoNameEN && Boolean(errorData.PromoNameEN)}
                    helperText={formik.touched.PromoNameEN && errorData.PromoNameEN}
                    fullWidth
                  />
                  <TextField
                    id='PromoCode'
                    variant='outlined'
                    label='Promo Code'
                    value={formik.values.PromoCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.PromoCode && Boolean(errorData.PromoCode)}
                    helperText={formik.touched.PromoCode && errorData.PromoCode}
                    fullWidth
                  />
                  <TextField
                    id='Quantity'
                    variant='outlined'
                    label='Quantity'
                    type='number'
                    inputProps={{ min: 1 }}
                    value={formik.values.Quantity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.Quantity && Boolean(errorData.Quantity)}
                    helperText={formik.touched.Quantity && errorData.Quantity}
                    fullWidth
                  />
                  <Autocomplete
                    options={(DiscountTypeDropdown && DiscountTypeDropdown.data) || []}
                    getOptionLabel={(list) => list.name}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.name == value.name : false
                    }
                    onOpen={() => getDiscountTypeDropdown()}
                    // value={DiscountTypeDropdownValue}
                    value={
                      (DiscountTypeDropdown &&
                        DiscountTypeDropdown.data &&
                        DiscountTypeDropdown.data.find(
                          (e) => e.name == formik.values.IsPercent
                        )) ||
                      null
                    }
                    onChange={(_, id) => {
                      if (id?.name) {
                        formik.setValues({ ...formik.values, IsPercent: id.name });
                        setDiscountType(id?.name)
                        if (id.name === 'Deduct Price') {
                          setDisableMaximumDeduction(true)
                        } else {
                          setDisableMaximumDeduction(false)
                        }
                      }
                    }}
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.id}>
                        {item.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='IsPercent'
                        label='Promo Type'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {DiscountTypeDropdown.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                  {discountType === 'Percentage' ? (
                    <>
                      <FormControl fullWidth>
                        <InputLabel htmlFor='electricityFee'>Promo Amount</InputLabel>
                        <OutlinedInput
                          id='Value'
                          label='Promo Amount'
                          type='number'
                          value={formik.values.Value && formik.values.Value.toString()}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched && formik.touched.Value && Boolean(formik.errors.Value)}
                          inputProps={{ autoComplete: 'off' }}
                          onKeyDown={(event) => {
                            if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                          }}
                          endAdornment={<InputAdornment position='end'>%</InputAdornment>
                          }
                        />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <FormControl fullWidth>
                        <InputLabel htmlFor='electricityFee'>Promo Amount</InputLabel>
                        <OutlinedInput
                          id='Value'
                          label='Promo Amount'
                          type='number'
                          value={formik.values.Value && formik.values.Value.toString()}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched && formik.touched.Value && Boolean(formik.errors.Value)}
                          inputProps={{ autoComplete: 'off' }}
                          onKeyDown={(event) => {
                            if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                          }}
                          startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
                        />
                      </FormControl>
                    </>
                  )}
                </Box>
                <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column', marginTop: '56px' }}>
                  <TextField
                    id='PromoNameID'
                    variant='outlined'
                    label='Promo Name ID'
                    value={formik.values.PromoNameID}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.PromoNameID && Boolean(errorData.PromoNameID)}
                    helperText={formik.touched.PromoNameID && errorData.PromoNameID}
                    fullWidth
                  />
                  <TextField
                    id='Name'
                    variant='outlined'
                    label='Promo Name'
                    value={formik.values.Name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.Name && Boolean(errorData.Name)}
                    helperText={formik.touched.Name && errorData.Name}
                    fullWidth
                  />
                  <TextField
                    {...DialogStyle.textField}
                    id='Description'
                    variant='outlined'
                    label='Description'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Description}
                    error={formik.touched && formik.touched.Description && Boolean(formik.errors.Description)}
                    helperText={
                      formik.touched && formik.touched.Description && formik.errors && formik.errors.Description
                    }
                    multiline
                    rows={3.4}
                    fullWidth
                  />
                  <TextField
                  sx={{marginTop: -1.2}}
                    id='MinimumPayment'
                    variant='outlined'
                    label='Minimum Payment'
                    type='number'
                    value={formik.values.MinimumPayment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.MinimumPayment && Boolean(errorData.MinimumPayment)}
                    helperText={formik.touched.MinimumPayment && errorData.MinimumPayment}
                    fullWidth
                  />
                </Box>
              </Box>
              <TextField
                {...DialogStyle.textField}
                id='MaximumDeduction'
                variant='outlined'
                label='Maximum Deduction'
                type='number'
                disabled={disableMaximumDeduction}
                // value={formik.values.MaximumDeduction}
                value={disableMaximumDeduction ? 0 : formik.values.MaximumDeduction}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.MaximumDeduction && Boolean(formik.errors.MaximumDeduction)}
                helperText={
                  formik.touched && formik.touched.MaximumDeduction && formik.errors && formik.errors.MaximumDeduction
                }
                fullWidth
              />
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      readOnly={true}
                      value={date.validFrom ? dayjs(date.validFrom) : null}
                      onChange={(e) => {
                        const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                        formik.setFieldValue('validFrom', newDate)
                        setDate((prevState) => ({ ...prevState, validFrom: e }))
                      }}
                      label='Start Date'
                      format='DD MMM YYYY'
                    />
                  </LocalizationProvider>
                  <Autocomplete
                    fullWidth
                    sx={{ marginBottom: '10px' }}
                    multiple
                    id='fixed-tags-demo'
                    value={applicableSubsPlan}
                    onOpen={() => getApplicableSubsPlanDropdown()}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.id === value.id : false // Compare by id instead of name
                    }
                    onChange={(event, newValue) => {
                      setApplicableSubsPlan(newValue);
                      handleChange(newValue);
                    }}
                    options={(applicableSubsPlanDropdown && applicableSubsPlanDropdown.data) || []}
                    getOptionLabel={(option) => option.name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.name} {...getTagProps({ index })}
                          key={index}
                        />
                      ))
                    }
                    renderInput={(params) => <TextField {...params} label='Applicable Subscription Plan' />}
                  />
                </Box>
                <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={date.validUntil ? dayjs(date.validUntil) : null}
                      readOnly={true}
                      minDate={dayjs(formik.values.ValidFrom)}
                      onChange={(e) => {
                        const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                        formik.setFieldValue('validUntil', newDate)
                        setDate((prevState) => ({ ...prevState, validUntil: e }))
                      }}
                      label='End Date'
                      format='DD MMM YYYY'
                    />
                  </LocalizationProvider>
                  <Autocomplete
                    fullWidth
                    sx={{ marginBottom: '10px' }}
                    multiple
                    id='fixed-tags-demo'
                    value={userType}
                    onOpen={() => getCarModelDropdown()}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.id === value.id : false // Compare by id instead of name
                    }
                    onChange={(event, newValue) => {
                      setUserType(newValue);
                      handleChangeCarModel(newValue);
                    }}
                    options={[
                      { id: 14, name: "All" }, // New option
                      ...(carModelList && carModelList.data || []), // Existing options
                    ]}
                    getOptionLabel={(option) => option.name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.name} {...getTagProps({ index })}
                          key={index}
                          disabled={isDisabled}
                        />
                      ))
                    }
                    renderInput={(params) => <TextField {...params} label='Car Model' />}
                  />

                </Box>
              </Box>
            </>
          )}
        </Container>
      </Dialog>
      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export type PartialPromoCodeGeneralEditTempProps = {
  PkId: number | null
  Name: string
  PromoNameEN: string
  PromoNameID: string
  PromoCode: string
  Quantity: number | null
  Description: string
  IsPercent: string
  Value: number | null
  ValidFrom: string
  ValidUntil: string
  MinimumPayment: number | null
  MaximumDeduction: number | null
  IsAllCarModel?: boolean
  SubscriptionPlan: SubscriptionApplicablePlanProps[]
  CarModel: CarModelCreateProps[]
}

export type SubscriptionApplicablePlanProps = {
  PlanPkId: number,
  SubscriptionPlanName: string
}

export type subscriptionPlanList = {
  planPkId: number
  subscriptionPlanName: string
}

export type CarModelCreateProps = {
  PkId: number,
  CarName: string
}
export default Update
