import {
  Chip,
  Container,
  Typography
} from '@mui/material'
import GLOBAL from 'modules/global'
import Drawer from 'components/drawer/detail'
import type { DetailStateProps } from 'modules/types'
import { useGetDetailPromoCodeTransactionQuery } from 'store/promoTransaction'
import Loading from 'components/loading/loading'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetDetailPromoCodeTransactionQuery(id)

  const loading = !data && (isLoading || isFetching)

  const userID = (data && data.user) || '-'
  const rdeptionStatus = (data && data.redeemedStatus) || '-'
  const promoName = (data && data.promoCodeName) || '-'
  const promoCode = (data && data.promoCode) || '-'
  const redeemedAt = (data && GLOBAL.formatDateWithTime(data.redeemedAt)) || '-'
  const createdAt = (data && GLOBAL.formatDateWithTime(data.createdAt)) || '-'

  return (
    <Drawer open={open} title='Detail Promo Code Transaction' onClose={onClose}>
      <Container {...DrawerStyle.Container}>
        {loading && <Loading />}
        {!loading && (
          <>
            <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>User ID</Typography>
            <Typography sx={{ marginTop: -2 }} style={{ color: '#000000', fontSize: 16, fontWeight: '500' }}><strong>{userID}</strong></Typography>

            <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>Redemption Status</Typography>
            <Typography sx={{ marginTop: -1.5 }} style={{ color: '#000000', fontSize: 16, fontWeight: '500' }}>
              <Chip
                  label={rdeptionStatus === 'Success' ? 'Success' : 'Failed'}
                  color={rdeptionStatus == 'Success' ? 'success' : 'error'}
                />
            </Typography>

            <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>Promo Name</Typography>
            <Typography sx={{ marginTop: -2 }} style={{ color: '##262626', fontSize: 18, fontWeight: '400', lineHeight: '17.72px' }}>{promoName}</Typography>

            <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>Promo Code</Typography>
            <Typography sx={{ marginTop: -2 }} style={{ color: '#000000', fontSize: 16, fontWeight: '500' }}><strong>{promoCode}</strong></Typography>

            <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>Redeemed At</Typography>
            <Typography sx={{ marginTop: -2 }} style={{ color: '#000000', fontSize: 16, fontWeight: '500' }}><strong>{redeemedAt}</strong></Typography>

            <Typography style={{ color: '#737373', fontSize: 14, fontWeight: '400' }}>Created At</Typography>
            <Typography sx={{ marginTop: -2 }} style={{ color: '#000000', fontSize: 16, fontWeight: '500' }}><strong>{createdAt}</strong></Typography>
          </>
        )}
      </Container>
    </Drawer>
  )
}

export default Detail
