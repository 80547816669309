import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { Autocomplete, Chip, CircularProgress, Container, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import * as yup from 'yup'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ApplicableSubsPlanDropDown, DetailStateProps, ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import { useCreateDiscountSettingMutation, useGetDiscountTypeDropdownMutation } from 'store/discountSetting'
import { useGetApplicableSubsPlanDropdownMutation } from 'store/dropdown'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createTokenRequest, create] = useCreateDiscountSettingMutation()
  const [getDiscountTypeDropdown, DiscountTypeDropdown] = useGetDiscountTypeDropdownMutation()
  const [getApplicableSubsPlanDropdown, applicableSubsPlanDropdown] = useGetApplicableSubsPlanDropdownMutation();
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [discountType, setDiscountType] = useState<number | null>(null)
  const [applicableSubsPlan, setApplicableSubsPlan] = useState<ApplicableSubsPlanDropDown[]>([])
  const [messageUntil, setMessageUntil] = useState<string>('')

  const scheme = yup.object<PartialCreateDiscountSettingProps>({
    Name: yup.string().required('Name is required'),
    Description: yup.string().required('Description is required'),
    DiscountType: yup.string().required('Discount Type is required'),
    IsPercent: yup.boolean().required('IsPercent is required'),
    Value: yup.number().required('Value is required'),
    ValidFrom: yup.string().required('ValidFrom is required'),
    ValidUntil: yup.string().required('ValidUntil is required'),
    SubscriptionPlan: yup.array().of(yup.object().shape({
      PlanPkId: yup.number().required(),
      SubscriptionPlanName: yup.string().required(),
    })).required('SubscriptionPlan is required')
  })

  const formik = useFormik<PartialCreateDiscountSettingProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      Name: '',
      Description: '',
      DiscountType: '',
      IsPercent: true,
      Value: 0,
      ValidFrom: '',
      ValidUntil: '',
      SubscriptionPlan: []
    },
    onSubmit: (values: PartialCreateDiscountSettingProps) => handleSubmit(values)
  })

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidFrom', newDate)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidUntil', newDate)
  }


  const handleChange = (value: ApplicableSubsPlanDropDown[]) => {
    const formattedPlans = value.map(plan => ({
      PlanPkId: plan.id, // Make sure these properties exist in your dropdown data
      SubscriptionPlanName: plan.name
    }));
    formik.setFieldValue('SubscriptionPlan', formattedPlans);
  };

  const handleSubmit = (e: PartialCreateDiscountSettingProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isPercentValue = discountType === 1 ? false : true;

    const formatPayload = {
      Name: e.Name,
      Description: e.Description,
      DiscountType: e.DiscountType,
      IsPercent: isPercentValue,
      Value: e.Value,
      ValidFrom: e.ValidFrom,
      ValidUntil: e.ValidUntil,
      SubscriptionPlan: e.SubscriptionPlan
    };
    createTokenRequest(formatPayload).then()
  }

  const isEndDateGreaterThanStartDate = () => {
    if (
      messageUntil && dayjs(formik.values.ValidFrom).isAfter(dayjs(formik.values.ValidUntil))
    ) {
      return true
    } else {
      return false
    }
  }

  const disabled = !formik.isValid ||
    (formik.values.SubscriptionPlan.length === 0) ||
    isEndDateGreaterThanStartDate();

  return (
    <>
      <Dialog
        open={open}
        title='Create New Discount'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={disabled}
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <TextField
              id='Name'
              variant='outlined'
              label='Discount Name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.Name && Boolean(formik.errors.Name)
              }
              helperText={
                formik.touched &&
                formik.touched.Name &&
                formik.errors &&
                formik.errors.Name
              }
              fullWidth
            />

            <TextField
              id='Description'
              variant='outlined'
              label='Description'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Description && Boolean(formik.errors.Description)}
              helperText={
                formik.touched && formik.touched.Description && formik.errors && formik.errors.Description
              }
              multiline
              rows={3}
              fullWidth
            />

            <Autocomplete
              options={DiscountTypeDropdown.data || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getDiscountTypeDropdown()}
              onChange={(_, id) => { formik.setFieldValue('DiscountType', id && id.id), setDiscountType(id && id?.id) }}
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='DiscountType'
                  label='Discount Type'
                  error={
                    formik.touched && formik.touched.DiscountType && Boolean(formik.errors.DiscountType)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.DiscountType &&
                    formik.errors &&
                    formik.errors.DiscountType
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {DiscountTypeDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
            {discountType === 1 && (
              <>
                <FormControl fullWidth>
                  <InputLabel htmlFor='electricityFee'>Discount Amount</InputLabel>
                  <OutlinedInput
                    id='Value'
                    label='Discount Amount'
                    type='number'
                    // value={formik.values.Value && formik.values.Value.toString()}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched && formik.touched.Value && Boolean(formik.errors.Value)}
                    inputProps={{ autoComplete: 'off' }}
                    onKeyDown={(event) => {
                      if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                    }}
                    endAdornment={<InputAdornment position='end'>%</InputAdornment>
                    }
                  />
                </FormControl>
                <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={startDate}
                      onChange={handleChangeStartDate}
                      label='Start Date'
                      format='DD MMM YYYY'
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={endDate}
                      minDate={dayjs(startDate)}
                      onChange={handleChangeEndDate}
                      onError={(error) => {
                        if (error) {
                          formik.setFieldError('ValidUntil', error);
                          setMessageUntil(formik.values.ValidUntil)
                        } else if (formik.values.ValidFrom && dayjs(formik.values.ValidFrom).isAfter(dayjs(formik.values.ValidUntil))) {
                          formik.setFieldError('ValidUntil', 'Valid Until must be greater than Valid From');
                        }
                      }}
                      label='End Date'
                      format='DD MMM YYYY'
                    />
                  </LocalizationProvider>
                </div>
                {messageUntil && dayjs(formik.values.ValidFrom).isAfter(dayjs(formik.values.ValidUntil)) && (
                  <FormHelperText sx={{ alignSelf: 'center', marginTop: -1 }} error id='ValidUntil'>End Date must be greater than Start Date</FormHelperText>
                )}

                <Autocomplete
                  fullWidth
                  sx={{ marginBottom: '10px' }}
                  multiple
                  id='fixed-tags-demo'
                  value={applicableSubsPlan}
                  onOpen={() => getApplicableSubsPlanDropdown()}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.name === value.name : false
                  }
                  onChange={(event, newValue) => {
                    setApplicableSubsPlan(newValue)
                    handleChange(newValue);
                  }}
                  options={(applicableSubsPlanDropdown && applicableSubsPlanDropdown.data) || []}
                  getOptionLabel={(option) => option.name}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} key={index} />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} label='Applicable Subscription Plan' />}
                />
              </>
            )}
            {discountType === 2 && (
              <>
                <FormControl fullWidth>
                  <InputLabel htmlFor='electricityFee'>Discount Amount</InputLabel>
                  <OutlinedInput
                    id='Value'
                    label='Discount Amount'
                    type='number'
                    // value={formik.values.Value && formik.values.Value.toString()}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched && formik.touched.Value && Boolean(formik.errors.Value)}
                    inputProps={{ autoComplete: 'off' }}
                    onKeyDown={(event) => {
                      if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                    }}
                    startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
                  />
                </FormControl>
                <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={startDate}
                      onChange={handleChangeStartDate}
                      label='Start Date'
                      format='DD MMM YYYY'
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={endDate}
                      minDate={dayjs(startDate)}
                      onChange={handleChangeEndDate}
                      onError={(error) => {
                        if (error) {
                          formik.setFieldError('ValidUntil', error);
                          setMessageUntil(formik.values.ValidUntil)
                        } else if (formik.values.ValidFrom && dayjs(formik.values.ValidFrom).isAfter(dayjs(formik.values.ValidUntil))) {
                          formik.setFieldError('ValidUntil', 'Valid Until must be greater than Valid From');
                        }
                      }}
                      label='End Date'
                      format='DD MMM YYYY'
                    />
                  </LocalizationProvider>
                </div>
                {messageUntil && dayjs(formik.values.ValidFrom).isAfter(dayjs(formik.values.ValidUntil)) && (
                  <FormHelperText sx={{ alignSelf: 'center', marginTop: -1 }} error id='ValidUntil'>End Date must be greater than Start Date</FormHelperText>
                )}

                <Autocomplete
                  fullWidth
                  sx={{ marginBottom: '10px' }}
                  multiple
                  id='fixed-tags-demo'
                  value={applicableSubsPlan}
                  onOpen={() => getApplicableSubsPlanDropdown()}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.name === value.name : false
                  }
                  onChange={(event, newValue) => {
                    setApplicableSubsPlan(newValue)
                    handleChange(newValue);
                  }}
                  options={(applicableSubsPlanDropdown && applicableSubsPlanDropdown.data) || []}
                  getOptionLabel={(option) => option.name}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} key={index} />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} label='Applicable Subscription Plan' />}
                />
              </>
            )}
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export type PartialCreateDiscountSettingProps = {
  Name: string
  Description: string
  DiscountType: string
  IsPercent: boolean,
  Value: number,
  ValidFrom: string,
  ValidUntil: string,
  SubscriptionPlan: SubscriptionApplicablePlanProps[]
}

export type SubscriptionApplicablePlanProps = {
  PlanPkId: number,
  SubscriptionPlanName: string
}

export default Create
