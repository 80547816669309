import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import MenuModalItem from 'components/menuItem/menuItem'
import Filter from 'components/filter/filter'
import Content from 'components/content/content'
import type { ErrorProps } from 'modules/types'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import CONSTANT from 'modules/constant'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useGetApplicableSubsPlanDropdownMutation, useGetMemberTypeDropdownsssMutation } from 'store/dropdown'
import { useGetDiscountTypeDropdownMutation } from 'store/discountSetting'
import { useDownloadPromoCodeGeneralMutation, useGetListPromoCodeGeneralMutation, useUpdateStatusPromoCodeGeneralMutation } from 'store/promoCodeGeneral'
import Create from './create/create'
import Edit from './edit/edit'
import Detail from './detail/detail'

const PromoCode = () => {
  const [getListPromoGeneral, promoCode] = useGetListPromoCodeGeneralMutation()
  const [downloadPromoGeneral, download] = useDownloadPromoCodeGeneralMutation()
  const [updateStatusPromoGeneral, update] = useUpdateStatusPromoCodeGeneralMutation()
  const [getApplicableSubsPlanDropdown, applicableSubsPlanDropdown] = useGetApplicableSubsPlanDropdownMutation();
  const [getCarModelDropdown, carModelList] = useGetMemberTypeDropdownsssMutation()
  const [getDiscountTypeDropdown, DiscountTypeDropdown] = useGetDiscountTypeDropdownMutation()

  const [messageUntil, setMessageUntil] = useState<string>('')

  // const [date, setDate] = useState<{
  //   ValidFrom: Dayjs | null
  //   ValidUntil: Dayjs | null
  // }>({
  //   ValidFrom: null,
  //   ValidUntil: null
  // })

  const [filter, setFilter] = useState({
    SubscriptionPlan: '',
    CarModel: '',
    PromoType: '',
    Status: '',
    ValidFrom: '',
    ValidUntil: ''
  })
  const [payload, setPayload] = useState<PartialPromoCodeProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    SubscriptionPlan: '',
    CarModel: '',
    PromoType: '',
    Status: '',
    ValidFrom: '',
    ValidUntil: ''
  })
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [randomNumber, setRandomNumber] = useState<number>(0)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })
  const onResync = () => {
    const formData = new FormData()
    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.SubscriptionPlan)) formData.append('SubscriptionPlan', payload.SubscriptionPlan)
    if (!isEmpty(payload.CarModel)) formData.append('CarModel', payload.CarModel)
      if (!isEmpty(payload.PromoType)) formData.append('PromoType', payload.PromoType)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (!isEmpty(payload.ValidFrom)) formData.append('ValidFrom', payload.ValidFrom)
    if (!isEmpty(payload.ValidUntil)) formData.append('ValidUntil', payload.ValidUntil)

    getListPromoGeneral(formData)
  }
  const onFilter = (state: boolean) => {setDialogFilter(state), setMessageUntil('')}
  const onDownload = (type?: string) => {
    const formData = new FormData()
    formData.append('Start', String(0))
    formData.append('Length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.SubscriptionPlan)) formData.append('SubscriptionPlan', payload.SubscriptionPlan)
    if (!isEmpty(payload.CarModel)) formData.append('CarModel', payload.CarModel)
    if (!isEmpty(payload.PromoType)) formData.append('PromoType', payload.PromoType)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (!isEmpty(payload.ValidFrom)) formData.append('ValidFrom', payload.ValidFrom)
    if (!isEmpty(payload.ValidUntil)) formData.append('ValidUntil', payload.ValidUntil)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadPromoGeneral(formData)
  }
  const onCreate = () => setDialogCreate(true)

  useEffect(() => {
    onResync()
    setMessageUntil('')
  }, [payload])

  const tableHeadTitles = [
    'Promo Name',
    'Promo Code',
    'Quantity',
    'Start date',
    'End Date',
    'Promo Type',
    'Amount',
    'Maximum Deduction',
    'Minimum Payment',
    'Applicable Subs Plan',
    'Car Model',
    'Status'
  ]
  const numbers = GLOBAL.tableNumber(payload.Start)
  const openAnchor = Boolean(anchor)
  const totalPage = promoCode.data && GLOBAL.tableTotalPage(promoCode.data.recordsTotal)
  const formatCurrency = (value: number) =>
    value ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : '-'

  const setIspercent = (value: number, isPercent: boolean) => {
    if (isPercent) {
      return `${value} %`;
    } else {
      return `Rp ${formatCurrency(value)}`;
    }
  };

  const isEndDateGreaterThanStartDate = () => {
    if (
      messageUntil && dayjs(filter.ValidFrom).isAfter(dayjs(filter.ValidUntil))
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Content
        title='Promo Code General List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        useDownloadDropdown
        onAdditional={onCreate}
        additionalTitle='Create New Promo Code General'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={promoCode.data && promoCode.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={promoCode.isLoading}
      >
        {promoCode.isSuccess &&
          promoCode.data &&
          promoCode.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.pkId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.pkId}`}
                  aria-labelledby={`button-${index}-${table.pkId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={table.status === 'DISABLED' ? 'Enable' : 'Disable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        updateStatusPromoGeneral({
                          Id: table.pkId,
                          IsActive: table.status == 'DISABLED'
                        })
                      )
                    }
                  >
                    {table.status == 'DISABLED' ? 'Enabled' : 'Disabled'}
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.pkId}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                {dialogUpdate && menu == index && (
                  <Edit
                    id={table.pkId}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.promoCode || '-'}</TableCell>
              <TableCell>{table.quantity || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.validFrom) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.validUntil) || '-'}</TableCell>
              <TableCell>{table.promoType || '-'}</TableCell>
              <TableCell>{setIspercent(table.value, table.isPercent) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatCurrency(table.minimumPayment) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatCurrency(table.maximumDeduction) || '-'}</TableCell>
              <TableCell>{table.subscriptionPlan || '-'}</TableCell>
              <TableCell>{table.carModel || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.status === 'ENABLED' ? 'Enabled' : 'Disabled'}
                  color={table.status == 'ENABLED' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}
      {promoCode.isLoading && <Loading />}
      <Notification
        open={!promoCode.isLoading && !promoCode.isUninitialized && !promoCode.isSuccess}
        onClose={() => (promoCode.isError ? promoCode.reset() : location.reload())}
        isError={Boolean(promoCode.error) && promoCode.isError}
        message={GLOBAL.returnExceptionMessage(promoCode.isError, promoCode.error as ErrorProps)}
      />
      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter) || isEndDateGreaterThanStartDate()}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomNumber(randomNumber + 1)
          setFilter({
            SubscriptionPlan: '',
            CarModel: '',
            PromoType: '',
            Status: '',
            ValidFrom: '',
            ValidUntil: ''
          })
          // setDate({
          //   ValidFrom: null,
          //   ValidUntil: null
          // })
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            SubscriptionPlan: '',
            CarModel: '',
            Status: '',
            PromoType: '',
            ValidFrom: '',
            ValidUntil: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(applicableSubsPlanDropdown && applicableSubsPlanDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getApplicableSubsPlanDropdown()}
          onChange={(_, reward) => {
            if (!isEmpty(reward)) setFilter({ ...filter, SubscriptionPlan: reward?.name })
          }}
          value={
            (applicableSubsPlanDropdown &&
              applicableSubsPlanDropdown.data &&
              applicableSubsPlanDropdown.data.find((e) => e.name == filter.SubscriptionPlan)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.name}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='SubscriptionPlan'
              label='Applicable Subscription Plan'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {applicableSubsPlanDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(carModelList && carModelList.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getCarModelDropdown()}
          onChange={(_, reward) => {
            if (!isEmpty(reward)) setFilter({ ...filter, CarModel: reward?.name })
          }}
          value={
            (carModelList &&
              carModelList.data &&
              carModelList.data.find((e) => e.name == filter.CarModel)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.name}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='carModellist'
              label='Car Model'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {carModelList.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(DiscountTypeDropdown && DiscountTypeDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getDiscountTypeDropdown()}
          onChange={(_, id) => {
            if (!isEmpty(id)) setFilter({ ...filter, PromoType: id?.name })
          }}
          value={
            (DiscountTypeDropdown &&
              DiscountTypeDropdown.data &&
              DiscountTypeDropdown.data.find((e) => e.name == filter.PromoType)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.name}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='PromoType'
              label='Promo Type'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {DiscountTypeDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.Status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, Status: event.target.value })}
          >
            <MenuItem value='ENABLED'>Enabled</MenuItem>
            <MenuItem value='DISABLED'>Disabled</MenuItem>
          </Select>
        </FormControl>
        <Typography>Period</Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              // value={date.ValidFrom}
              value={filter.ValidFrom ? dayjs(filter.ValidFrom) : null}
              onChange={(e) => {
                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                setFilter({ ...filter, ValidFrom: newDate })
                // setDate((prevState) => ({ ...prevState, minDateSignUp: e }))
              }}
              label='Start Date'
              format='MMM, DD YYYY'
              sx={{ width: "190px" }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              // value={date.ValidUntil}
              value={filter.ValidUntil ? dayjs(filter.ValidUntil) : null}
              minDate={dayjs(filter.ValidFrom)}
              onChange={(e) => {
                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                setFilter({ ...filter, ValidUntil: newDate })
                // setDate((prevState) => ({ ...prevState, maxDateSignUp: e }))
              }} 
              onError={(error) => {
                if (error) {
                  setMessageUntil(filter.ValidUntil)
                }
              }}
              label='End Date'
              format='MMM, DD YYYY'
              sx={{ width: "190px" }}
            />
          </LocalizationProvider>
        </div>
        {messageUntil && dayjs(filter.ValidFrom).isAfter(dayjs(filter.ValidUntil)) && (
          <FormHelperText sx={{alignSelf: 'center', marginTop: -2}} error id='ValidUntil'>Start Date must be greater than End Date</FormHelperText>
        )}
      </Filter>
    </>
  )
}

export type PartialPromoCodeProps = {
  Start: number
  Length: number
  Search: string
  SubscriptionPlan: string
  CarModel: string
  PromoType: string
  Status: string
  ValidFrom: string
  ValidUntil: string
}
export default PromoCode
