import CONSTANT from 'modules/constant'
import type { PaginationProps, ResultProps, SubsPlanDetailProps, SubsPlanListProps } from 'modules/types'
import type { PartialPromoCodeProps } from 'modules/partial'
import { API, download, headers } from './api'
// import { update } from 'lodash'

const PromoCodeAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getListSubsPlan: builder.mutation<PaginationProps<SubsPlanListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_SUBS_PLAN_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getDetailSubsPlan: builder.query<SubsPlanDetailProps, number | string>({
      query: (id) => ({
        url: CONSTANT.URL_SUBS_PLAN_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    createSubsPlan: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_SUBS_PLAN_CREATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        // headers: headers(),
        body: body,
        formData: true
      })
    }),
    updateStatusSubsPlan: builder.mutation<ResultProps, { Id: number; IsActive: boolean }>({
      query: (body) => ({
        url: CONSTANT.URL_SUBS_PLAN_UPDATE_STATUS,
        method: 'PUT',
        headers: headers(),
        body: body
      })
    }),
    downloadSubsPlan: builder.mutation<PaginationProps<SubsPlanListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_SUBS_PLAN_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    deletePromoCode: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_PROMO_CODE_DELETE + id,
        method: 'DELETE',
        headers: headers()
      })
    }),
    createPromoCode: builder.mutation<ResultProps, PartialPromoCodeProps>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),

    updateSubsPlan: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_SUBS_PLAN_UPDATE,
        method: 'PUT',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
  })
})

export const {
  useGetListSubsPlanMutation,
  useGetDetailSubsPlanQuery,
  useCreateSubsPlanMutation,
  useUpdateStatusSubsPlanMutation,
  useDownloadSubsPlanMutation,
  useDeletePromoCodeMutation,
  useCreatePromoCodeMutation,
  useUpdateSubsPlanMutation
} = PromoCodeAPI
