import CONSTANT from 'modules/constant'
import type {
    AuditTrailDetailsProps,
    AuditTrailListProps,
    DropdownAuditTrailActivityProps,
    DropdownAuditTrailModuleProps,
    DropdownAuditTrailRoleProps,
    PaginationProps
} from 'modules/types'
import { API, download, headers } from './api'

const AuditTrailAPI = API.injectEndpoints({
    endpoints: (builder) => ({
        getAuditTrailList: builder.mutation<PaginationProps<AuditTrailListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_AUDIT_TRAIL_LIST,
                method: 'POST',
                headers: headers({ useContentType: false }),
                body: body,
                formData: true
            })
        }),
        downloadAuditTrai: builder.mutation<PaginationProps<AuditTrailListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_AUDIT_TRAIL_DOWNLOAD,
                method: 'POST',
                headers: headers({ useContentType: false }),
                responseHandler: download,
                body: body,
                formData: true
            })
        }),
        getDetailAuditTrail: builder.query<AuditTrailDetailsProps, number>({
            query: (id) => ({
                url: CONSTANT.URL_AUDIT_TRAIL_DETAIL + id,
                method: 'GET',
                headers: headers()
            })
        }),
        dropDownAuditTrailActvity: builder.mutation<DropdownAuditTrailActivityProps[], void>({
            query: () => ({
                url: CONSTANT.URL_AUDIT_TRAIL_DROPDOWN_ACTIVITY,
                method: 'GET',
                headers: headers()
            })
        }),
        dropDownAuditTrailModule: builder.mutation<DropdownAuditTrailModuleProps[], void>({
            query: () => ({
                url: CONSTANT.URL_AUDIT_TRAIL_DROPDOWN_MODULE,
                method: 'GET',
                headers: headers()
            })
        }),
        dropDownAuditTrailRole: builder.mutation<DropdownAuditTrailRoleProps[], void>({
            query: () => ({
                url: CONSTANT.URL_AUDIT_TRAIL_DROPDOWN_ROLE,
                method: 'GET',
                headers: headers()
            })
        })
    })
})

export const {
    useGetAuditTrailListMutation,
    useDownloadAuditTraiMutation,
    useGetDetailAuditTrailQuery,
    useDropDownAuditTrailActvityMutation,
    useDropDownAuditTrailModuleMutation,
    useDropDownAuditTrailRoleMutation
} = AuditTrailAPI