import CONSTANT from 'modules/constant'
import type { CategoryDropdownProps, CopywritingDetailProps, CopywritingListProps, PaginationProps, ResultProps } from 'modules/types'
import type { PartialCreateCopywritingProps, PartialEditCopywritingProps, PartialPromoCodeProps } from 'modules/partial'
import { API, download, headers } from './api'

const PromoCodeAPI = API.injectEndpoints({
    endpoints: (builder) => ({
        getListCopywriting: builder.mutation<PaginationProps<CopywritingListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_COPYWRITING_LIST,
                method: 'POST',
                headers: headers({ useContentType: false }),
                body: body,
                formData: true
            })
        }),
        getDetailCopywriting: builder.query<CopywritingDetailProps, number | string>({
            query: (id) => ({
                url: CONSTANT.URL_COPYWRITING_DETAIL + id,
                method: 'GET',
                headers: headers()
            })
        }),
        getCategoryDropdown: builder.mutation<CategoryDropdownProps[], void>({
            query: () => ({
                url: CONSTANT.URL_DROPDOWN_CATEGORY,
                method: 'GET',
                headers: headers()
            })
        }),
        createCopywriting: builder.mutation<ResultProps, PartialCreateCopywritingProps>({
            query: (body) => ({
                url: CONSTANT.URL_COPYWRITING_CREATE,
                method: 'POST',
                headers: headers(),
                body: body
            })
        }),
        downloadCopyWriting: builder.mutation<PaginationProps<CopywritingListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_COPYWRITING_DOWNLOAD,
                method: 'POST',
                headers: headers({ useContentType: false }),
                responseHandler: download,
                body: body,
                formData: true
            })
        }),
        updateCopywriting: builder.mutation<ResultProps, { body: PartialEditCopywritingProps }>({
            query: (param) => ({
                url: CONSTANT.URL_COPYWRITING_EDIT,
                method: 'PUT',
                headers: headers(),
                body: param.body,
            })
        }),


        deletePromoCode: builder.mutation<ResultProps, number>({
            query: (id) => ({
                url: CONSTANT.URL_PROMO_CODE_DELETE + id,
                method: 'DELETE',
                headers: headers()
            })
        }),
        createPromoCode: builder.mutation<ResultProps, PartialPromoCodeProps>({
            query: (body) => ({
                url: CONSTANT.URL_PROMO_CODE_CREATE,
                method: 'POST',
                headers: headers(),
                body: body
            })
        })
    })
})

export const {
    useGetListCopywritingMutation,
    useGetDetailCopywritingQuery,
    useGetCategoryDropdownMutation,
    useCreateCopywritingMutation,
    useDownloadCopyWritingMutation,
    useUpdateCopywritingMutation,

    useDeletePromoCodeMutation,
    useCreatePromoCodeMutation
} = PromoCodeAPI
