import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge'

highchartsMore(Highcharts)
solidGauge(Highcharts)

export const GaugeChart = () => {
  const options: Highcharts.Options = {
    chart: {
      type: 'solidgauge',
      plotShadow: false,
      height: '50%'
    },
    title: {
      text: undefined
    },
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: undefined,
      // background: {
      //     backgroundColor: '#E5EAFC',
      //     borderWidth: 0,
      //     innerRadius: '60%',
      //     outerRadius: '100%',
      //     shape: 'arc'
      // },
      center: ['50%', '70%'],
      size: '140%'
    },

    exporting: {
      enabled: false
    },

    tooltip: {
      enabled: false
    },

    // the value axis
    yAxis: {
      min: 0,
      max: 100,
      // tickPixelInterval: 72,
      tickPosition: 'inside',
      tickColor: '#FFFFFF',
      tickLength: 20,
      tickWidth: 2,
      minorTickInterval: undefined,
      labels: {
        distance: 20,
        style: {
          fontSize: '14px'
        }
      },
      lineWidth: 0,
      plotBands: [
        {
          from: 0,
          to: 100,
          color: '#E5EAFC'
          // thickness: 20,
          // borderRadius: '50%'
        }
      ]
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true
        }
      }
    },
    series: [
      {
        type: 'solidgauge',
        name: 'Speed',
        color: 'red',
        // data: [72],
        data: [
          {
            color: '#00AAD2',
            radius: '100%',
            innerRadius: '88%',
            y: 65
          }
        ],
        tooltip: {
          valueSuffix: ' %'
        },
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            '<span style="font-size:25px">{y} %</span><br/>' +
            '<span style="font-size:10px;opacity:0.4">Subscription</span><br/>' +
            '<span style="font-size:10px;opacity:0.4">Cancelation Rate</span>' +
            '</div>',
          borderWidth: 0,
          color:
            (Highcharts.defaultOptions.title &&
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            '#333333',
          style: {
            fontSize: '16px'
          }
        },
        // dial: {
        //   radius: '80%',
        //   backgroundColor: 'gray',
        //   baseWidth: 12,
        //   baseLength: '0%',
        //   rearLength: '0%'
        // },
        // pivot: {
        //   backgroundColor: 'gray',
        //   radius: 6
        // }
      }
    ]
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}
