import type { BoxProps } from '@mui/material'

class DetailStyle {
  static Container: BoxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: 2,
  }
}

export default DetailStyle
