import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetDetailSubsContentQuery } from 'store/subsContent'
import DrawerStyle from './style'


const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetDetailSubsContentQuery(id)

  const loading = !data && (isLoading || isFetching)

  const subsContenID = (data && data.pkId) || '-'
  const subsContentName = (data && data.contentName) || '-'
  const subtitle = data && data.subtitle || '-'

  const subscriptionPlanNames = data?.subscriptionPlan.map(plan => plan.subscriptionPlanName).join(', ');
  const applicableSubsPlan = data && subscriptionPlanNames

  const gridDataLabel = [
    'Subscription Content ID',
    'Subscription Content Name',
    'Subtitle',
    'Applicable Subscription Plan'
  ]

  const gridDataValue = [
    subsContenID,
    subsContentName,
    subtitle,
    applicableSubsPlan
  ]

  // const createdBy = (data && data.createdBy) || '-'
  // const createdAt = (data && data.createdAt) || '-'
  // const modifiedBy = (data && data.modifiedBy) || '-'
  // const modifiedAt = (data && data.modifiedAt) || '-'

  // const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  // const gridBasicValue = [createdBy, createdAt, modifiedBy, modifiedAt]

  return (
    <>
      <Drawer open={open} title='Detail Subscription Content' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <Grid container spacing={2}>
                {gridDataValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridDataLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                <Typography>Content</Typography>
              </Grid>
              {data?.content?.map((input, index) => (
                <Grid key={index} item xs={12} style={{ display: 'flex', flexDirection: 'row'}}>
                  <Typography style={{marginRight: 4 }}>{index + 1}</Typography>
                  <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <TextField
                      variant='outlined'
                      // label={`Content English #${index + 1}`}
                      label={`Content English`}
                      name={`ContentEnglish-${index}`}
                      value={input.contentEN}
                    />
                    <TextField
                      variant='outlined'
                      // label={`Content Bahasa #${index + 1}`}
                      label={`Content Bahasa`}
                      name={`ContentBahasa-${index}`}
                      value={input.contentID}
                      style={{ marginTop: 10 }}
                    />
                  </Grid>
                </Grid>
              ))}
              {/* <Accordion elevation={0} disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls='basic-information'
                >
                  <Typography>Basic Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {gridBasicValue.map((item, index) => (
                      <Grid key={index} item xs={12}>
                        <TextField
                          variant='outlined'
                          label={gridBasicLabel[index]}
                          value={item}
                          inputProps={{ readOnly: true }}
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion> */}
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
