import { useState } from 'react'
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { DatePeriod } from 'modules/enums'

export const DashboardHeader = () => {
  const [period, setPeriod] = useState<DatePeriod>(DatePeriod.Weekly)

  const [valueStartDate, setValueStartDate] = useState<Dayjs | null>(dayjs(''))

  const [valueEnddate, setValueEndDate] = useState<Dayjs | null>(dayjs(''))

  return (
    <Grid container spacing={2}>
      <Grid item>
        <ToggleButtonGroup
          value={period}
          exclusive
          onChange={(_, value) => setPeriod(value)}
          aria-label='Date period'
        >
          {Object.keys(DatePeriod)
            .filter((k) => !isNaN(Number(k)))
            .map(Number)
            .map((k, id) => (
              <ToggleButton key={id + 1} value={k} aria-label={DatePeriod[k]}>
                {DatePeriod[k]}
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      </Grid>

      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={valueStartDate}
            //   onChange={(event: Dayjs | null) =>
            //     setFilter({ ...filter, MinValidFrom: (event && event.format('YYYY-MM-DD')) || '' })
            //   }
            onChange={(newValue) => setValueStartDate(newValue)}
            label='Start Date'
            format='DD-MM-YYYY'
            sx={{ width: '150px' }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={valueEnddate}
            minDate={dayjs(valueStartDate)}
            //   onChange={(event: Dayjs | null) =>
            //     setFilter({ ...filter, MaxValidFrom: (event && event.format('YYYY-MM-DD')) || '' })
            //   }
            onChange={(newValue) => setValueEndDate(newValue)}
            label='End Date'
            format='DD-MM-YYYY'
            sx={{ width: '150px' }}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  )
}
