import { ArrowDownLeftIcon, ArrowUpRightIcon } from "@heroicons/react/20/solid";
import { Typography } from "@mui/material";

export const NumberChart = ({ icon, data }: NumberChartProps) => (
  <div
    style={{
      width: '33%',
      paddingTop: 17,
      paddingBottom: 17,
      paddingLeft: 14,
      paddingRight: 14,
      marginRight: 20,
      backgroundColor: '#FFFFFF'
    }}
  >
    <div style={{ width: 48, height: 48, backgroundColor: '#AFECEF', padding: 8 }}>
      {icon}
    </div>
    <div
      style={{
        marginTop: 14,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <Typography style={{ fontSize: 40, fontWeight: 500 }}>
        <strong>{data.value}</strong>
      </Typography>
      <div style={{ marginTop: 16 }}>
        <span
          className='dot'
          style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#31C48D',
            borderRadius: '50%',
            display: 'inline-block'
          }}
        >
          <ArrowUpRightIcon style={{ height: 20, width: 20, color: '#FFFFFF' }} />
          <ArrowDownLeftIcon style={{ height: 20, width: 20, color: '#FFFFFF' }} />
        </span>
      </div>
      <Typography style={{ color: '#31C48D', marginTop: 16 }}>
        <strong>+6.8%</strong>
      </Typography>
    </div>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography style={{ color: '#999999' }}>Total Users</Typography>
    </div>
  </div>
)

interface NumberChartProps {
  icon: JSX.Element
  data: { name: string; value: number }
}
