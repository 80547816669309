import { useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import {
  useUpdateRolesListMutation,
  useGetRolesListDetailQuery,
  useGetRolesListDropdownsssMutation
} from 'store/roles'
import type { PartialRolesEditProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) <></>

  const [getRolesListTypesDropdown, rolesTypeDropdown] = useGetRolesListDropdownsssMutation()
  const [updateRolesList, update] = useUpdateRolesListMutation()

  const { data, isFetching, isLoading } = useGetRolesListDetailQuery(id)

  const onSubmit = (values: PartialRolesEditProps) => updateRolesList(values)

  const selectedRole =
    rolesTypeDropdown &&
    rolesTypeDropdown.data &&
    rolesTypeDropdown.data.find((el) => el.code == (data && data.code))

  const scheme = yup.object<PartialRolesEditProps>({
    Name: yup.string().required('Name is required'),
    Code: yup.string().required('Code is required'),
    Description: yup.string().required('Description is required')
  })


  console.log('Code', data?.code);
  
  const formik = useFormik<PartialRolesEditProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      PkId: (data && data.pkId) || 0,
      Code: (data && data.code),
      Name: (data && data.name) || '',
      Description: (data && data.description) || ''
    },
    onSubmit: onSubmit
  })

  useEffect(() => {
    getRolesListTypesDropdown()
  }, [])

  return (
    <>
      <Dialog
        title='Edit New Role'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <TextField
                id='Name'
                variant='outlined'
                label='Name'
                value={formik.values.Name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.Name && Boolean(formik.errors.Name)}
                helperText={
                  formik.touched && formik.touched.Name && formik.errors && formik.errors.Name
                }
                fullWidth
              />
              <TextField
                id='Description'
                variant='outlined'
                label='Description'
                value={formik.values.Description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched && formik.touched.Description && Boolean(formik.errors.Description)
                }
                helperText={
                  formik.touched &&
                  formik.touched.Description &&
                  formik.errors &&
                  formik.errors.Description
                }
                fullWidth
              />
              <Autocomplete
                options={rolesTypeDropdown.data || []}
                getOptionLabel={(list) => list.code}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.pkId == value.pkId : false
                }
                onOpen={() => getRolesListTypesDropdown()}
                value={selectedRole}
                onChange={(_, code) => formik.setFieldValue('Code', code && code.code)}
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.pkId}>
                    {item.code}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='roleId'
                    label='Select A Role'
                    value={formik.values.Code}
                    error={
                      formik.touched &&
                      formik.touched.Code &&
                      Boolean(formik.errors.Code)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.Code &&
                      formik.errors &&
                      formik.errors.Code
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {rolesTypeDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
