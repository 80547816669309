import * as Highcharts from 'highcharts'
import HighchartsReact from "highcharts-react-official"

export const BarChart = () => {
  const options: Highcharts.Options = {
    chart: {
      type: 'bar',
      height: 320,
      style: {
        fontFamily: 'Hyundai Sans Text Office'
      },
      scrollablePlotArea: {
        minHeight: 500
      }
    },
    title: {
      text: ['Total Transaction Paid', 'Total Users', 'Total Revenue'].join(','),
      align: 'left',
      style: {
        fontFamily: 'Hyundai Sans Head Office'
      }
    },
    xAxis: {
      categories: [
        '1 Africa',
        '2 America',
        '3 Asia',
        '4 Europe',
        '5 America',
        '6 Asia',
        '7 Europe',
        '8 America',
        '9 Asia',
        '10 Europe'
      ],
      title: {
        text: null
      },
      gridLineWidth: 1,
      // top: '-50%',
      lineWidth: 0,
      labels: {
        align: 'left',
        x: 0,
        y: -3,
        enabled: true
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Population (millions)',
        align: 'high'
      },
      labels: {
        overflow: 'justify'
      },
      gridLineWidth: 1,
      gridLineDashStyle: 'Dash'
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      bar: {
        // borderRadius: '50%',
        dataLabels: {
          enabled: true
        },
        groupPadding: 0.1
      }
    },
    legend: { enabled: false },
    credits: {
      enabled: false
    },
    series: [
      {
        type: 'bar',
        name: 'Year 1990',
        data: [632, 727, 3202, 721, 727, 3202, 721, 727, 3202, 721],
        pointPlacement: -0.2,
        dataLabels: {
          align: 'right',
          alignTo: 'plotEdges',
          y: -14,
          style: {
            fontWeight: 'normal'
          }
        },
        pointWidth: 10
      }
    ]
  }

  return (
    <HighchartsReact highcharts={Highcharts} options={options} />
  )
}
