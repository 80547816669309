import { createSlice } from '@reduxjs/toolkit'
import type { UserProps } from 'modules/types'

const initialState: Pick<UserProps, 'token'> = {
  token: '',
}

const FcmNotificationSlice = createSlice({
  name: 'FcmNotification',
  initialState,
  reducers: {
    setToken: (state, action) => (state = action.payload)
  }
})

export const { setToken } = FcmNotificationSlice.actions
export default FcmNotificationSlice.reducer
