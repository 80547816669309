import { useEffect, useState } from 'react'
import {
  TableRow,
  TableCell,
  IconButton,
  Autocomplete,
  TextField,
  CircularProgress,
  Typography,
  Box,
  Grid,
  Chip
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import type { PartialSubsTransactionProps } from 'modules/partial'
import Notification from 'components/notification'
import type { ErrorProps } from 'modules/types'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import {
  useDownloadSubsTransactionMutation,
  useLazyGetSubsTransactionListQuery
} from 'store/subscriptionTransaction'
import {
  useGetApplicableSubsPlanDropdownMutation,
  useGetMemberTypeDropdownsssMutation
} from 'store/dropdown'
import { useGetConfigPaymentMethodListQuery } from 'store/config'
import { useGetPromoCodeTransactionDropdownQuery } from 'store/promoTransaction'
import Detail from './detail/detail'
// import { StatusChip } from './statusChip'

const SubscriptionTransaction = () => {
  const [getSubsTransactionList, subsTransactionListResult] = useLazyGetSubsTransactionListQuery()

  const [downloadSubsTransaction, downloadSubsTransactionResult] =
    useDownloadSubsTransactionMutation()

  const [getApplicableSubsPlanDropdown, applicableSubsPlanDropdownResult] =
    useGetApplicableSubsPlanDropdownMutation()

  const promoCodeTransactionDropdownResult = useGetPromoCodeTransactionDropdownQuery()

  const [getCarModelList, carModelListResult] = useGetMemberTypeDropdownsssMutation()

  const configPaymentMethodListResult = useGetConfigPaymentMethodListQuery()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [randomKey, setRandomKey] = useState<number>(0)

  const [filter, setFilter] = useState<
    Omit<PartialSubsTransactionProps, 'Start' | 'Length' | 'Search' | 'Sort'>
  >({
    CarModel: '',
    PaymentType: '',
    PromoName: '',
    StatusRenewal: '',
    StopRenewalDate: '',
    SubscriptionName: '',
    MaxSubscriptionEndDate: '',
    MaxSubscriptionStartDate: '',
    MinSubscriptionEndDate: '',
    MinSubscriptionStartDate: ''
  })

  const [payload, setPayload] = useState<PartialSubsTransactionProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    Sort: '',
    CarModel: '',
    PaymentType: '',
    PromoName: '',
    StatusRenewal: '',
    StopRenewalDate: '',
    SubscriptionName: '',
    MaxSubscriptionEndDate: '',
    MaxSubscriptionStartDate: '',
    MinSubscriptionEndDate: '',
    MinSubscriptionStartDate: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onResync = () => {
    getSubsTransactionList(payload)
  }

  const onDownload = (type?: string) => {
    downloadSubsTransaction({ ...payload, type })
  }

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback: CallableFunction) => {
    setAnchor(null)
    callback(callback)
  }

  const tableHeadTitles = [
    'Transaction ID',
    'Invoice ID',
    'Registered Owner',
    'VIN',
    'Plan',
    'Total Price',
    'Start Period',
    'End Period',
    'Status',
    'Promo Name',
    'Car Model',
    'Payment Type',
    'Stop Auto Renewal Date'
  ]

  const openAnchor = Boolean(anchor)
  const totalPage =
    subsTransactionListResult.data &&
    GLOBAL.tableTotalPage(subsTransactionListResult.data.recordsTotal)
  const numbers = GLOBAL.tableNumber(payload.Start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Transactions'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={downloadSubsTransactionResult.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={subsTransactionListResult.data && subsTransactionListResult.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={subsTransactionListResult.isLoading}
        useDownloadDropdown
      >
        {subsTransactionListResult.isSuccess &&
          subsTransactionListResult.data &&
          subsTransactionListResult.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.pkId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.pkId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => {
                    onAction(event, index)
                    onRemoveAnchor(() => setDrawerDetail(true))
                  }}
                >
                  <Icon icon='VisibilityOutlined' />
                </IconButton>
                <Detail
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                  id={table.pkId}
                />
              </TableCell>
              <TableCell>{table.transactionId || '-'}</TableCell>
              <TableCell>{table.invoiceId || '-'}</TableCell>
              <TableCell>{table.registeredOwner}</TableCell>
              <TableCell>{table.vin}</TableCell>
              <TableCell>
                <img src={table.iconUrl || 'https://dummyimage.com/24'} width={24} />
                {table.subscriptionPlanData}
              </TableCell>
              <TableCell>{GLOBAL.formatCurrency(table.totalPrice)}</TableCell>
              <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.subscriptionStartDate)}</TableCell>
              <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.subscriptionEndDate)}</TableCell>
              <TableCell>
                <Chip
                  label={table.status === 'Success' ? 'Success' : 'Error'}
                  color={table.status == 'Success' ? 'success' : 'error'}
                />
                {/* <StatusChip status={table.status} /> */}
              </TableCell>
              <TableCell>{table.promoName}</TableCell>
              <TableCell>{table.carModelName}</TableCell>
              <TableCell>{table.paymentMethod}</TableCell>
              <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.stopAutoRenewalDate)}</TableCell>
            </TableRow>
          ))}
      </Content>

      {subsTransactionListResult.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomKey(randomKey + 1)
          setFilter({
            CarModel: '',
            PaymentType: '',
            PromoName: '',
            StatusRenewal: '',
            StopRenewalDate: '',
            SubscriptionName: '',
            MaxSubscriptionEndDate: '',
            MaxSubscriptionStartDate: '',
            MinSubscriptionEndDate: '',
            MinSubscriptionStartDate: ''
          })
          setPayload({
            ...payload,
            Start: 0,
            Search: '',
            Sort: '',
            CarModel: '',
            PaymentType: '',
            PromoName: '',
            StatusRenewal: '',
            StopRenewalDate: '',
            SubscriptionName: '',
            MaxSubscriptionEndDate: '',
            MaxSubscriptionStartDate: '',
            MinSubscriptionEndDate: '',
            MinSubscriptionStartDate: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, Start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Autocomplete
          key={'planName-' + randomKey}
          options={
            (applicableSubsPlanDropdownResult && applicableSubsPlanDropdownResult.data) || []
          }
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getApplicableSubsPlanDropdown()}
          onChange={(_, planName) => {
            setFilter({
              ...filter,
              SubscriptionName: String((planName && planName.id) || '')
            })
          }}
          value={
            (applicableSubsPlanDropdownResult &&
              applicableSubsPlanDropdownResult.data &&
              applicableSubsPlanDropdownResult.data.find(
                (e) => String(e.id) == filter.SubscriptionName
              )) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='planName'
              label='Subscription Plan Name'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {applicableSubsPlanDropdownResult.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />

        <Autocomplete
          key={'promoName-' + randomKey}
          options={
            (promoCodeTransactionDropdownResult && promoCodeTransactionDropdownResult.data) || []
          }
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onChange={(_, promoName) => {
            setFilter({
              ...filter,
              PromoName: String((promoName && promoName.id) || '')
            })
          }}
          value={
            (promoCodeTransactionDropdownResult &&
              promoCodeTransactionDropdownResult.data &&
              promoCodeTransactionDropdownResult.data.find(
                (e) => String(e.id) == filter.PromoName
              )) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='promoName'
              label='Promo Name'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {promoCodeTransactionDropdownResult.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />

        <Box>
          <Typography mb={1}>Subscription Period Start</Typography>
          <Grid container spacing={1.75}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={
                    filter.MinSubscriptionStartDate ? dayjs(filter.MinSubscriptionStartDate) : null
                  }
                  minDate={dayjs(filter.MinSubscriptionStartDate)}
                  onChange={(event: Dayjs | null) =>
                    setFilter({
                      ...filter,
                      MinSubscriptionStartDate: (event && event.format('YYYY-MM-DD')) || ''
                    })
                  }
                  label='Min Date'
                  format='DD-MM-YYYY'
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={
                    filter.MaxSubscriptionStartDate ? dayjs(filter.MaxSubscriptionStartDate) : null
                  }
                  minDate={dayjs(filter.MinSubscriptionStartDate)}
                  onChange={(event: Dayjs | null) =>
                    setFilter({
                      ...filter,
                      MaxSubscriptionStartDate: (event && event.format('YYYY-MM-DD')) || ''
                    })
                  }
                  label='Max Date'
                  format='DD-MM-YYYY'
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography mb={1}>Subscription Period End</Typography>
          <Grid container spacing={1.75}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={
                    filter.MinSubscriptionEndDate ? dayjs(filter.MinSubscriptionEndDate) : null
                  }
                  minDate={dayjs(filter.MinSubscriptionEndDate)}
                  onChange={(event: Dayjs | null) =>
                    setFilter({
                      ...filter,
                      MinSubscriptionEndDate: (event && event.format('YYYY-MM-DD')) || ''
                    })
                  }
                  label='Min Date'
                  format='DD-MM-YYYY'
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={
                    filter.MaxSubscriptionEndDate ? dayjs(filter.MaxSubscriptionEndDate) : null
                  }
                  minDate={dayjs(filter.MinSubscriptionEndDate)}
                  onChange={(event: Dayjs | null) =>
                    setFilter({
                      ...filter,
                      MaxSubscriptionEndDate: (event && event.format('YYYY-MM-DD')) || ''
                    })
                  }
                  label='Max Date'
                  format='DD-MM-YYYY'
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>

        <Autocomplete
          options={(carModelListResult && carModelListResult.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getCarModelList()}
          onChange={(_, carModel) =>
            setFilter({
              ...filter,
              CarModel: (carModel && String(carModel.id)) || ''
            })
          }
          value={
            (carModelListResult &&
              carModelListResult.data &&
              carModelListResult.data.find((e) => String(e.id) == filter.CarModel)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='carModel'
              label='Car Model'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {carModelListResult.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />

        <Autocomplete
          options={yesNoValues}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onChange={(_, renewalStatus) =>
            setFilter({
              ...filter,
              StatusRenewal: (renewalStatus && String(renewalStatus.id)) || ''
            })
          }
          value={yesNoValues.find((e) => String(e.id) == filter.StatusRenewal) || undefined}
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='renewalStatus'
              label='Auto Renewal'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {carModelListResult.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />

        <Autocomplete
          options={(configPaymentMethodListResult && configPaymentMethodListResult.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onChange={(_, paymentType) =>
            setFilter({
              ...filter,
              PaymentType: (paymentType && String(paymentType.id)) || ''
            })
          }
          value={
            (configPaymentMethodListResult &&
              configPaymentMethodListResult.data &&
              configPaymentMethodListResult.data.find((e) => String(e.id) == filter.PaymentType)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='paymentType'
              label='Payment Type'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {configPaymentMethodListResult.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.StopRenewalDate ? dayjs(filter.StopRenewalDate) : null}
            minDate={dayjs(filter.StopRenewalDate)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, StopRenewalDate: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Stop Renewal Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
      </Filter>

      {/* <Notification
        open={
          !subsTransactionListResult.isLoading &&
          !subsTransactionListResult.isUninitialized &&
          !subsTransactionListResult.isSuccess
        }
        onClose={() =>
          subsTransactionListResult.isError ? subsTransactionListResult.reset() : location.reload()
        }
        isError={Boolean(subsTransactionListResult.error) && subsTransactionListResult.isError}
        message={GLOBAL.returnExceptionMessage(
          subsTransactionListResult.isError,
          subsTransactionListResult.error as ErrorProps
        )}
      /> */}

      <Notification
        open={
          !downloadSubsTransactionResult.isLoading && !downloadSubsTransactionResult.isUninitialized
        }
        onClose={() => downloadSubsTransactionResult.reset()}
        isError={
          Boolean(downloadSubsTransactionResult.error) && downloadSubsTransactionResult.isError
        }
        message={GLOBAL.returnExceptionMessage(
          downloadSubsTransactionResult.isError,
          downloadSubsTransactionResult.error as ErrorProps
        )}
      />
    </>
  )
}

const yesNoValues = [
  { id: 'Yes', name: 'Yes' },
  { id: 'No', name: 'No' }
]

export default SubscriptionTransaction
